module Client.Page.PublicHistory

open System
open Client
open Client.Api
open Client.Components
open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fable.React
open Fable.React.Props
open Feliz
open Feliz.Recharts
open Browser
open Fulma
open Shared.Dto
open Shared.Dto.PublicHistory
open Fable.Core.JsInterop

type DataModel = {
    Sensor: string
    GraphData: PublicHistory.PublicGraphData list
}

type Model = Loadable<DataModel, unit>

let init (id: int) =
    Loadable.Loading(), Cmd.OfAsync.perform api.getPublicHistory id (PublicHistoryMsg.DataReceived >> PublicHistory)

let update (msg: PublicHistoryMsg) (model: Model) =
    match (msg, model) with
    | PublicHistoryMsg.DataReceived PublicHistory.NotFound, Loadable.Loading _ ->
        Loadable.Error "Der Sensor wurde nicht gefunden"
    | PublicHistoryMsg.DataReceived(PublicHistory.Data data), Loadable.Loading _ ->
        Loadable.Data {
            Sensor = data.Sensor
            GraphData = data.Data
        }
    | _, _ -> model

let formatTimeTick (unixTimeStamp: double) =
    let localTime =
        DateTimeOffset.FromUnixTimeSeconds(int64 unixTimeStamp).ToLocalTime()

    localTime.ToString("HH:mm")

let private dayToReferenceLine (day: DateTimeOffset) =
    let label = day.ToString("dd.MM.")
    let unixTimeStamp = day.ToUnixTimeSeconds()

    Recharts.referenceLine [
        referenceLine.label label
        referenceLine.x (double unixTimeStamp)
        referenceLine.stroke "black"
    ]

let private createReferenceLines () =
    let startDate = DateTime.Now.AddDays(-5.).Date

    List.init 5 ((+) 1 >> float >> startDate.AddDays)
    |> List.map DateTimeOffset
    |> List.map dayToReferenceLine

let airGraph (data: PublicHistory.PublicGraphData list) =
    let axisTicks =
        GraphCommon.createAxisTicksBy true 5. (fun (node: PublicHistory.PublicGraphData) -> node.AirTemperature) data

    let minTick = List.min axisTicks
    let maxTick = List.max axisTicks

    let yAxisTicks = axisTicks |> List.toArray |> Interop.mkYAxisAttr "ticks"

    let startTicks =
        DateTimeOffset(System.DateTime.Now.AddDays(-5.).Date).ToUnixTimeSeconds()
        |> double

    let endTicks =
        DateTimeOffset(System.DateTime.Now.AddDays(1.).Date).ToUnixTimeSeconds()
        |> double

    let referenceLines = createReferenceLines ()

    let ticksCount = endTicks - startTicks
    let interval = ticksCount / 12.

    let xDomain = [| startTicks; endTicks |] |> Interop.mkXAxisAttr "domain"

    let ticks =
        Seq.init 13 (fun i -> double i * interval + startTicks)
        |> Seq.toArray
        |> Interop.mkXAxisAttr "ticks"

    let tickFormatter = Interop.mkXAxisAttr "tickFormatter" formatTimeTick

    let dataKey =
        fun (p: PublicGraphData) -> DateTimeOffset(p.TimeStamp).ToUnixTimeSeconds() |> double
        |> Interop.mkXAxisAttr "dataKey"

    let scale = Interop.mkXAxisAttr "scale" "time"

    let tooltipContent properties =
        let label = properties?label
        let values: obj[] = properties?payload

        let header =
            DateTimeOffset.FromUnixTimeSeconds(int64 label)
            |> fun dto -> dto.ToLocalTime().ToString("dd.MM HH:mm")

        if Array.isEmpty values then
            div [] []
        else
            let lines =
                Array.map
                    (fun item ->
                        let text = sprintf "%s: %.2f" item?name item?value

                        p [ Style [ Color item?stroke ] ] [ str text ]
                    )
                    values

            div [ Class "custom-tooltip" ] [ h1 [] [ str header ]; yield! lines ]

    let xAxisTickInterval = Interop.mkXAxisAttr "interval" "preserveStartEnd"

    Recharts.lineChart [
        lineChart.syncId "syncId1"
        lineChart.data data
        lineChart.children [
            Recharts.xAxis [
                dataKey
                ticks
                xAxis.tickCount 14
                xDomain
                xAxis.number
                scale
                tickFormatter
                xAxisTickInterval
            ]
            Recharts.yAxis [
                yAxisTicks
                yAxis.number
                yAxis.unit " °C"
                yAxis.domain (domain.constant (int minTick), domain.constant (int maxTick))
            ]
            Recharts.legend []
            Recharts.tooltip [ tooltip.content tooltipContent ]
            Recharts.line [
                line.dot false
                line.dataKey (fun (p: PublicHistory.PublicGraphData) -> p.AirTemperature)
                line.name "Temperatur [°C]"
                line.stroke "#FFC000"
            ]

            yield! referenceLines

            Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
        ]
    ]
    |> GraphCommon.fullHeightGraphBox "Verlauf der Temperatur"

let private maybeAirGraph (data: PublicHistory.PublicGraphData list) =
    if List.isEmpty data then
        Heading.h1 [
            Heading.Props [ Style [ TextAlign TextAlignOptions.Center ] ]
        ] [ str "Aktuell sind keine Daten verfügbar" ]
    else
        airGraph data

let dataView (data: DataModel) =
    let content = [
        Heading.h3 [
            Heading.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
            ]
        ] [ str data.Sensor ]
        Columns.columns [ Columns.IsMultiline ] [
            Column.column [ Column.Width(Screen.All, Column.IsFull) ] [ Box.box' [] [ maybeAirGraph data.GraphData ] ]
        ]
    ]

    Container.container [] content

let view model = Loadable.view dataView model