module Client.Components.Graph.AverageWindSpeedSensor

open Client.Components.GraphCommon
open Client.DateTime
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData

let currentAverageWindSpeedDataBox (data: AverageWindSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (dateTimeToString data.Date)
        currentDataLevelItem "Akt. Windgeschwindigkeit Durchschnitt" (windSpeedToString data.AverageWindSpeed)
    ]
]

type AverageWindSpeedGraphNode = {
    Date: int64
    Datum: string
    AverageWindSpeed: float
}

type AverageWindSpeedGraphsData = {
    Ticks: float array
    Data: AverageWindSpeedGraphNode list
}

let toAverageWindSpeedSensorGraphData (data: GraphAverageWindSpeedDataDto) : AverageWindSpeedGraphNode = {
    Date = data.TimeStamp.Ticks
    Datum = data.TimeStamp.ToString("dd.MM HH:mm")
    AverageWindSpeed = data.AverageWindSpeed
}

let mapDataToAverageWindSpeed (data: GraphDataDto) =
    match data with
    | AverageWindSpeed data -> Some data
    | _ -> None

let createAverageWindSpeedGraphsData (data: AverageWindSpeedGraphNode list) =
    let ticks =
        if not (List.isEmpty data) then
            createAxisTicksBy true 2.0 (fun node -> node.AverageWindSpeed) data
            |> List.toArray
        else
            [||]

    { Ticks = ticks; Data = data }

let graphDataToAverageWindSpeedGraphData data =
    data
    |> List.map mapDataToAverageWindSpeed
    |> List.choose id
    |> List.map toAverageWindSpeedSensorGraphData
    |> createAverageWindSpeedGraphsData