module Client.Page.MySensorsData

open Client
open Client.Api
open Client.DateTime
open Client.Routing

open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Leaflet
open Microsoft.FSharp.Core
open Shared
open Shared.Dto.Dto
open Shared.Dto.MapSensorData
open Thoth.Elmish

type DataModel = {
    Sensors: MapSensor list
    Session: UserSession
}

type Model = Loadable<DataModel, UserSession>

let private refreshCmd sessionKey =
    let request = { SessionKey = sessionKey; Data = () }

    Cmd.OfAsync.perform api.getMyMapSensorsData request (MySensorDataMsg.DataReceived >> MySensorsData)

let init (session: UserSession) =
    Loadable.Loading session, refreshCmd session.SessionKey

let private getSession (model: Model) =
    match model with
    | Loadable.Loading session -> session
    | Loadable.Data dataModel -> dataModel.Session
    | _ -> failwith "That should never happend"

let private getSessionKey (model: Model) =
    getSession model |> fun session -> session.SessionKey

let update (msg: MySensorDataMsg) (model: Model) =
    match msg, model with
    | MySensorDataMsg.DataReceived result, Loadable.Loading session ->
        match result with
        | Ok data -> Loadable.Data { Sensors = data; Session = session }, Cmd.none
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Sensordaten" |> Toast.error

            model, toastCmd
    | MySensorDataMsg.DataReceived result, Loadable.Data dataModel ->
        match result with
        | Ok data -> Loadable.Data { dataModel with Sensors = data }, Cmd.none
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Sensordaten" |> Toast.error

            model, toastCmd
    | RefreshData, _ -> Loadable.Loading(getSession model), refreshCmd (getSessionKey model)
    | _ -> model, Cmd.none

let temperatureToString = sprintf "%.2f °C"

let percentageToString = sprintf "%.2f %%"

let rainFallToString = sprintf " %.1f mm"

let datedValueToString f (value: DatedValue<'a>) =
    sprintf "%s (%s)" (f value.Value) (timeToString value.Date)

let currentDataLevelItem label value =
    Level.item [ Level.Item.HasTextCentered ] [
        div [] [
            Level.heading [] [ str label ]
            Level.title [] [ str value ]
        ]
    ]

let currentAirDataBox (data: AirSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (dateTimeToString data.Date)
        currentDataLevelItem "Aktuelle Temperatur" (temperatureToString data.AirTemperature)
        currentDataLevelItem "Aktuelle Luftfeuchtigkeit" (percentageToString data.AirHumidity)
        currentDataLevelItem "Feuchttemperatur" (temperatureToString data.HumidTemperature)
    ]
]

let currentGroundDataBox (data: GroundSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (dateTimeToString data.Date)
        currentDataLevelItem "Aktuelle Bodenfeuchtigkeit" (percentageToString data.GroundHumidity)
        currentDataLevelItem "Aktuelle Temperatur" (temperatureToString data.GroundTemperature)
    ]
]

let currentRainfallDataBox (data: RainFallSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (dateTimeToString data.Date)
        currentDataLevelItem "Regen aktuell" (rainFallToString data.CurrentRainFall)
        currentDataLevelItem "Regen letzte Stunde" (rainFallToString data.RainFallLastHour)
        currentDataLevelItem "Regen Heute" (rainFallToString data.RainFallToday)
    ]
]

let currentDataBox dispatch (sensor: MapSensor) =
    let content =
        match sensor.Data with
        | AirData air -> currentAirDataBox air
        | GroundData ground -> currentGroundDataBox ground
        | RainFallData rainFall -> currentRainfallDataBox rainFall
        | _ -> []

    let sensorLinkStyle =
        Style [
            Color(Leaflet.getSensorNameLinkColor sensor.Data)
            TextDecoration "underline"
        ]
        :> IHTMLProp

    let sensorLink =
        Leaflet.createDetailsSensorNameLink [ sensorLinkStyle ] sensor dispatch

    let position = LatLngExpression.Case3(sensor.Latitude, sensor.Longitude)

    let headingContent =
        div [
            Style [
                TextAlign TextAlignOptions.Center
                Display DisplayOptions.Flex
                AlignItems AlignItemsOptions.Center
                JustifyContent "center"
            ]
        ] [
            (p [
                classList [
                    "is-size-3", true
                    "mb-5", true
                    "has-text-weight-bold", true
                ]
                Style [ Display DisplayOptions.Inline ]
            ] [ sensorLink ])

            a [
                OnClick(fun _ -> dispatch (Some position |> Route.SensorMap |> GoToRoute |> Global))
            ] [
                Icon.icon [
                    Icon.CustomClass "mb-5"
                    Icon.CustomClass "ml-2"
                ] [ Fa.i [ Fa.Size Fa.FaLarge; Fa.Solid.Globe ] [] ]
            ]
        ]

    Box.box' [] [
        Columns.columns [] [
            Column.column [ Column.Width(Screen.All, Column.IsFull) ] [ headingContent; (div [] content) ]
        ]
    ]

let dataView dispatch (model: DataModel) =
    let boxes =
        model.Sensors
        |> List.sortBy (fun sensor -> sensor.Name)
        |> List.map (currentDataBox dispatch)

    let button =
        Button.button [
            Button.Color Color.IsLink
            Button.OnClick(fun _ -> dispatch (MySensorsData MySensorDataMsg.RefreshData))
        ] [
            Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Sync ] [] ]
            span [] [ str "Aktualisieren" ]
        ]

    let buttonRow =
        Level.level [
            Level.Level.Modifiers [
                Modifier.Spacing(Spacing.MarginBottom, Spacing.Is3)
            ]
        ] [
            Level.left [] []
            Level.right [] [ Level.item [] [ button ] ]
        ]

    let content = List.append boxes [ buttonRow ]

    Container.container
        [
            Container.Modifiers [
                Modifier.Spacing(Spacing.MarginTop, Spacing.Is4)
            ]
        ]
        content

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model