module Client.Page.SiteNotice

open Fable.React
open Fable.React.Props
open Fulma

let view =
    Hero.hero [] [
        Hero.head [] [
            Heading.h1 [
                Heading.Modifiers [
                    Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
                ]
            ] [ str "Impressum" ]
        ]
        Hero.body [] [
            Container.container [] [
                div [ Class "block" ] [
                    p [ Style [ FontWeight "bold" ] ] [ str "KLAR! Modellregion plan b" ]
                    p [] [ str "Energieinstitut Vorarlberg" ]
                    p [] [ str "Stadtstraße 33" ]
                    p [] [ str "6850 Dornbirn" ]
                ]

                div [ Class "block" ] [
                    p [] [ str "Managerin der KLAR! Modellregion plan b:" ]
                    p [] [
                        str "Julie Buschbaum (julie.buschbaum@energieinstitut.at, 05572 / 31202-17)"
                    ]
                ]

                div [ Class "block" ] [
                    p [] [ str "Sprecher der KLAR! Modellregion plan b:" ]
                    p [] [
                        str "Bgm. Thomas Schierle (thomas.schierle@schwarzach.at, 05572 58115-221)"
                    ]
                ]
            ]
        ]
    ]