module Client.Page.UserList

open System
open Client.InfrastructureTypes
open Elmish
open Fulma
open Shared
open Shared.Dto.Dto
open Shared.Dto.User
open Client
open Fable.React
open Client.Api
open Client.Msg
open Client.Views
open Thoth.Elmish

type Data = {
    Users: IdValue<UserDto> list
    UserModal: Forms.User.Model option
    AddPackageModal: Forms.AddPackage.Model option
    Session: UserSession
}

type Model = Loadable<Data, UserSession>

let init (userSession: UserSession) =
    let request = {
        SessionKey = userSession.SessionKey
        Data = ()
    }

    let cmd =
        Cmd.OfAsync.perform api.getAllUsers request (UserListMsg.UsersLoaded >> UserList)

    Loadable.Loading userSession, cmd

let update (msg: UserListMsg) (model: Model) =
    match msg, model with
    | UsersLoaded maybeUserList, Loadable.Loading session ->
        match maybeUserList with
        | Result.Ok userList ->
            Loadable.Data {
                Users = userList
                Session = session
                UserModal = None
                AddPackageModal = None
            },
            Cmd.none
        | Result.Error message ->
            let errorMessage =
                match message with
                | AuthErr Unauthorized -> "Sie dürfen die notwendigen Daten nicht laden"
                | AuthErr Unauthenticated -> "Sie sind nicht eingeloggt. Bitte laden Sie die Seite neu"
                | CustomErr error -> error

            let cmd = Toast.create errorMessage |> Toast.error

            model, cmd
    | OpenModal user, Loadable.Data data ->
        Loadable.Data {
            data with
                UserModal = Some(Forms.User.init user)
        },
        Cmd.none
    | FormMsg formMsg, Loadable.Data data ->
        match data.UserModal with
        | Some modal ->
            let modal, cmd, formResult = Forms.User.update formMsg modal

            match formResult with
            | Forms.User.FormResult.Noop -> Loadable.Data { data with UserModal = Some modal }, cmd
            | Forms.User.FormResult.CloseModal -> Loadable.Data { data with UserModal = None }, cmd
            | Forms.User.FormResult.CloseAndRefresh -> init data.Session |> Cmds.batch cmd
        | None -> model, Cmd.none
    | OpenAddPackageModal userId, Loadable.Data data ->
        Loadable.Data {
            data with
                AddPackageModal =
                    Some(
                        Forms.AddPackage.init userId [
                            Package.BaseAccess
                            Package.AdditionalSensor
                            Package.History
                            Package.Alerts
                        ]
                        |> Loadable.Data
                    )
        },
        Cmd.none
    | AddPackage addPackageMsg, Loadable.Data data ->
        match data.AddPackageModal with
        | Some modal ->
            let modal, cmd, formResult = Forms.AddPackage.update addPackageMsg modal

            match formResult with
            | Forms.AddPackage.FormResult.Noop ->
                Loadable.Data {
                    data with
                        AddPackageModal = Some modal
                },
                cmd
            | Forms.AddPackage.FormResult.CloseModal -> Loadable.Data { data with AddPackageModal = None }, cmd
            | Forms.AddPackage.FormResult.CloseAndRefresh -> init data.Session |> Cmds.batch cmd
        | None -> model, Cmd.none
    | DeleteUser id, _ -> model, Cmd.OfAsync.perform api.deleteUser id (UserDeleted >> UserList)
    | UserDeleted success, Loadable.Data data ->
        if success then
            let toastCmd = Toast.create "Benutzer erfolgreich gelöscht" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        else
            let toastCmd = Toast.create "Fehler beim Löschen des Benutzers" |> Toast.error

            model, toastCmd
    | _, _ -> model, Cmd.none

let createNewUserButton dispatch =
    Button.button [
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (UserList <| UserListMsg.OpenModal None))
    ] [ str "Neuen Benutzer erstellen" ]

let userToRow dispatch (index: int) (user: IdValue<UserDto>) =
    tr [] [
        td [] [ Table.rowIndexString index ]
        td [] [ str (getLastName user.Value) ]
        td [] [ str (getFirstName user.Value) ]
        td [] [ str (getMail user.Value) ]
        td [] [ str (userTypeToString user.Value) ]
        td [] [
            str (
                getLastLogin user.Value
                |> Option.map DateTime.dateTimeToString
                |> Option.defaultValue "Noch nie"
            )
        ]
        td [] [
            getPackages user.Value
            |> List.map getPackageCharacter
            |> List.sort
            |> fun chars -> String.Join(", ", chars) |> str
        ]
        td [] [
            Button.button [
                Button.OnClick(fun _ -> dispatch (UserListMsg.OpenAddPackageModal user.Id |> UserList))
            ] [ str "Paket hinzufügen" ]
        ]
        td [] [
            Button.button [
                Button.OnClick(fun _ -> dispatch (UserListMsg.OpenModal(Some user) |> UserList))
            ] [ str "Bearbeiten" ]
        ]
        td [] [
            Button.button [
                Button.Color IsDanger
                Button.OnClick(fun _ -> dispatch (UserListMsg.DeleteUser user.Id |> UserList))
            ] [ str "Löschen" ]
        ]
    ]

let userListToTable dispatch (users: IdValue<UserDto> list) =
    Table.table [
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
    ] [
        thead [] [
            tr [] [
                th [] [ str "#" ]
                th [] [ str "Nachname" ]
                th [] [ str "Vorname" ]
                th [] [ str "Email Adresse" ]
                th [] [ str "Benutzer Typ" ]
                th [] [ str "Letzer Login" ]
                th [] [ str "Pakete" ]
                th [] []
                th [] []
                th [] []
            ]
        ]
        tbody [] (List.mapi (userToRow dispatch) users)
    ]

let tableHeader dispatch =
    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [
                Field.div [] [
                    Control.div [] [ createNewUserButton dispatch ]
                ]
            ]
        ]
    ]

let private dataView dispatch (data: Data) =
    let userModal =
        data.UserModal
        |> Option.map (Forms.User.view dispatch)
        |> Option.defaultValue (div [] [])

    let addPackageModal =
        data.AddPackageModal
        |> Option.map (Forms.AddPackage.view dispatch)
        |> Option.defaultValue (div [] [])

    [
        Heading.h1 [] [ str "Benutzer Liste" ]
        tableHeader dispatch
        userListToTable dispatch data.Users
        userModal
        addPackageModal
    ]
    |> Container.container [ Container.IsFluid ]
    |> (fun content -> div [] [ content; PageSkeleton.mySensFooter ])

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model