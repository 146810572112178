module Client.Forms.User

open Client.Api
open Client.Msg
open Elmish
open Fable.React
open Fable.React.Props
open Feliz.Bulma.ElementBuilders
open Fulma
open Fulma.Extensions.Wikiki
open Shared.Dto.User
open Shared
open Client
open Thoth.Elmish
open Fable.Core.JsInterop

type Role =
    private
    | Admin
    | User

let roleFromString role =
    match role with
    | "Administrator" -> Admin
    | "Benutzer" -> User
    | _ -> failwith "Unknown User Role"

let roleToString role =
    match role with
    | Admin -> "Administrator"
    | User -> "Benutzer"

let private roleFromDto (user: UserDto) =
    match user with
    | UserDto.Admin _ -> Admin
    | UserDto.User _ -> User

type UserData = {
    Id: int option
    FirstName: string option
    LastName: string option
    Mail: string option
    Password: string option
    Role: Role
    Packages: Package list
}

type Model = { Data: UserData; RequestRunning: bool }

type FormResult =
    | Noop
    | CloseModal
    | CloseAndRefresh

let private createNewModel = {
    Id = None
    FirstName = None
    LastName = None
    Mail = None
    Password = None
    Role = User
    Packages = []
}

let private modelFromDto (user: IdValue<UserDto>) = {
    Id = Some user.Id
    FirstName = Some(getFirstName user.Value)
    LastName = Some(getLastName user.Value)
    Mail = Some(getMail user.Value)
    Password = None
    Role = roleFromDto user.Value
    Packages = (getPackages user.Value)
}

let init (maybeUser: IdValue<UserDto> option) =
    let data =
        match maybeUser with
        | None -> createNewModel
        | Some user -> modelFromDto user

    { RequestRunning = false; Data = data }

let private createUserData firstName lastName mail password role packages : UserDto =
    let data: BaseUserDataDto = {
        FirstName = firstName
        LastName = lastName
        Mail = mail
        Password = password
        LastLogin = None
    }

    match role with
    | Admin -> UserDto.Admin data
    | User -> UserDto.User { Base = data; Packages = packages }

let createUpdateUserMsg maybeId maybeFirstName maybeLastName maybeMail maybePassword role packages =
    let userDto =
        Some createUserData
        |> Option.apply maybeFirstName
        |> Option.apply maybeLastName
        |> Option.apply maybeMail
        |> Option.apply (Some maybePassword)
        |> Option.apply (Some role)
        |> Option.apply (Some packages)

    match maybeId, userDto with
    | Some id, Some dto -> Some(UserFormMsg.UpdateUser { Id = id; Value = dto })
    | None, Some dto -> Some(UserFormMsg.CreateUser dto)
    | _, _ -> None

let ttnSensorToOption (role: Role) =
    let string = roleToString role

    option [ Value string ] [ str string ]

let rolesSelect (selected: Role) dispatch =
    let roles = [ Admin; User ]
    let options = List.map ttnSensorToOption roles

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue(roleToString selected)
                OnChange(fun event -> dispatch (event.target?value |> RoleChanged |> FormMsg |> UserList))
            ]
            options
    ]

let packageRow dispatch idx name =
    tr [] [
        td [] [ str name ]
        td [
            Class Tooltip.ClassName
            Tooltip.dataTooltip "Löschen"
            Style [ Width "38px" ]
        ] [
            Delete.delete [
                Delete.Size Size.IsMedium
                Delete.OnClick(fun _ -> dispatch (RemoveRole idx |> FormMsg |> UserList))
            ] []
        ]
    ]

let form dispatch (model: Model) =
    let packagesElements =
        model.Data.Packages
        |> List.map getPackageLabel
        |> List.mapi (packageRow dispatch)

    let packagesTable =
        Table.table [ Table.IsStriped; Table.IsFullWidth ] [
            thead [] [ tr [] [ th [] [ str "Paket" ]; th [] [] ] ]
            tbody [] packagesElements
        ]

    form [] [
        Field.div [] [
            Label.label [] [ str "Vorname" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder "Max"
                    model.Data.FirstName |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UserFormMsg.FirstNameChanged
                        |> FormMsg
                        |> UserList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Nachname" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder "Mustermann"
                    model.Data.LastName |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UserFormMsg.LastNameChanged
                        |> FormMsg
                        |> UserList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Mail Adresse" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder "max.mustermann@example.com"
                    model.Data.Mail |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UserFormMsg.MailChanged
                        |> FormMsg
                        |> UserList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Passwort" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder ""
                    model.Data.Password |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UserFormMsg.PasswordChanged
                        |> FormMsg
                        |> UserList
                        |> dispatch
                    )
                ]
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Benutzertyp" ]
            Control.div [ Control.IsExpanded ] [ rolesSelect model.Data.Role dispatch ]
        ]
        Field.div [] [
            Label.label [] [ str "Gebuchte Pakete" ]
            Control.div [] [ packagesTable ]
        ]
    ]

let saveButton dispatch (model: Model) =
    let data = model.Data

    let maybeOnClick =
        createUpdateUserMsg data.Id data.FirstName data.LastName data.Mail data.Password data.Role data.Packages
        |> Option.map (fun msg -> Button.OnClick(fun _ -> dispatch (FormMsg msg |> UserList)))

    let buttonOptions = [
        Button.IsLoading model.RequestRunning
        Button.Color IsSuccess
        Button.Disabled(Option.isNone maybeOnClick)
    ]

    Button.button (Lists.addToListIfSome buttonOptions maybeOnClick) [ str "Speichern" ]

let view dispatch (model: Model) =
    let closeModal = (fun _ -> dispatch (UserFormMsg.CloseModal |> FormMsg |> UserList))

    let headline =
        if Option.isSome model.Data.Id then
            sprintf "Benutzer bearbeiten"
        else
            sprintf "Neuen Benutzer erstellen"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [
            GenericOption.Props [ DOMAttr.OnClick closeModal ]
        ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
            Modal.Card.foot [] [ saveButton dispatch model ]
        ]
    ]

let private updateData (model: Model) f = { model with Data = f model.Data }

let update (msg: UserFormMsg) (model: Model) =
    match msg with
    | FirstNameChanged firstName ->
        updateData model (fun data -> { data with FirstName = firstName }), Cmd.none, FormResult.Noop
    | LastNameChanged lastName ->
        updateData model (fun data -> { data with LastName = lastName }), Cmd.none, FormResult.Noop
    | MailChanged mail -> updateData model (fun data -> { data with Mail = mail }), Cmd.none, FormResult.Noop
    | UserFormMsg.PasswordChanged password ->
        updateData model (fun data -> { data with Password = password }), Cmd.none, FormResult.Noop
    | RoleChanged role ->
        updateData model (fun data -> { data with Role = roleFromString role }), Cmd.none, FormResult.Noop
    | CreateUser user ->
        { model with RequestRunning = true },
        Cmd.OfAsync.perform api.createUser user (UserUpdated >> FormMsg >> UserList),
        FormResult.Noop
    | UpdateUser user ->
        { model with RequestRunning = true },
        Cmd.OfAsync.perform api.updateUser user (UserUpdated >> FormMsg >> UserList),
        FormResult.Noop
    | UserUpdated successful ->
        if successful then
            let toastCmd = Toast.create "Benutzer erfolgreich gespeichert" |> Toast.success

            { model with RequestRunning = false }, toastCmd, FormResult.CloseAndRefresh
        else
            let toastCmd = Toast.create "Fehler beim Speichern des Benutzers" |> Toast.error

            { model with RequestRunning = false }, toastCmd, FormResult.Noop
    | RemoveRole index ->
        let newPackages =
            model.Data.Packages
            |> List.mapi (fun idx package -> if idx <> index then Some package else None)
            |> List.choose id

        let newData = {
            model.Data with
                Packages = newPackages
        }

        { model with Data = newData }, Cmd.none, FormResult.Noop
    | UserFormMsg.CloseModal -> model, Cmd.none, FormResult.CloseModal