module Client.Components.Graph.SoilPhSensor

open Client.Components.GraphCommon
open Client.DateTime
open Feliz.Recharts
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData

type SoilPhGraphNode = {
    Date: int64
    Datum: string
    SoilPh: float
    SoilTemperature: float
}

let toSoilPhSensorGraphData (data: GraphSoilPhDataDto) : SoilPhGraphNode = {
    Date = data.TimeStamp.Ticks
    Datum = data.TimeStamp.ToString("dd.MM HH:mm")
    SoilPh = data.SoilPh
    SoilTemperature = data.SoilTemperature
}

let mapDataToSoilPh (data: GraphDataDto) =
    match data with
    | SoilPh data -> Some data
    | _ -> None

let currentSoilPhDataBox (data: SoilPhSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (dateTimeToString data.Date)
        currentDataLevelItem "PH-Wert" (sprintf "%f" data.SoilPh)
        currentDataLevelItem "Bodentemperatur" (temperatureToString data.SoilTemperature)
    ]
]

let soilPhDataGraphs (graphData: SimpleGraphData<SoilPhGraphNode>) =
    let phChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: SoilPhGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [ yAxis.number; yAxis.tickCount 6 ]
                Recharts.legend []
                Recharts.tooltip []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.SoilPh)
                    line.name "PH-Wert"
                    line.stroke "#cc9933"
                ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf des PH-Werts"

    let temperatureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: SoilPhGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [ yAxis.unit " °C"; yAxis.tickCount 6 ]
                Recharts.tooltip []
                Recharts.legend []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.SoilTemperature)
                    line.name "Bodentemperatur [in °C]"
                    line.stroke "#FFC000"
                ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Bodentemperatur"

    [ phChart; temperatureChart ]

let graphDataToSoilPhGraphData data =
    data
    |> List.map mapDataToSoilPh
    |> List.choose id
    |> List.map toSoilPhSensorGraphData
    |> (fun list -> { Data = list })