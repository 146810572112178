module Client.Components.Graph.LeafletMoistureSensor

open Client.Components.GraphCommon
open Client.DateTime
open Feliz.Recharts
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData

type LeafletMoistureGraphNode = {
    Date: int64
    Datum: string
    LeafletMoisture: float
    LeafletTemperature: float
}

let toLeafletMoistureSensorGraphData (data: GraphLeafletMoistureDataDto) : LeafletMoistureGraphNode = {
    Date = data.TimeStamp.Ticks
    Datum = data.TimeStamp.ToString("dd.MM HH:mm")
    LeafletMoisture = data.LeafletMoisture
    LeafletTemperature = data.LeafletTemperature
}

let mapDataToLeafletMoisture (data: GraphDataDto) =
    match data with
    | LeafletMoisture moistureData -> Some moistureData
    | _ -> None

let leafletMoistureDataGraphs (graphData: SimpleGraphData<LeafletMoistureGraphNode>) =
    let moistureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: LeafletMoistureGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [
                    yAxis.unit "%"
                    yAxis.number
                    yAxis.tickCount 6
                ]
                Recharts.legend []
                Recharts.tooltip []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.LeafletMoisture)
                    line.name "Blattnässe [%]"
                    line.stroke "#01B0F1"
                ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Blattnässe"

    let temperatureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: LeafletMoistureGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [ yAxis.unit " °C"; yAxis.tickCount 6 ]
                Recharts.tooltip []
                Recharts.legend []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.LeafletTemperature)
                    line.name "Blatttemperatur [in °C]"
                    line.stroke "#FFC000"
                ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Blatttemperatur"

    [ moistureChart; temperatureChart ]

let currentLeafletMoistureDataBox (data: LeafletMoistureSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (dateTimeToString data.Date)
        currentDataLevelItem "Blattnässe" (percentageToString data.LeafletMoisture)
        currentDataLevelItem "Blatttemperatur" (temperatureToString data.LeafletTemperature)
    ]
]

let graphDataToLeafletMoistureData data : SimpleGraphData<LeafletMoistureGraphNode> =
    data
    |> List.map mapDataToLeafletMoisture
    |> List.choose id
    |> List.map toLeafletMoistureSensorGraphData
    |> (fun list -> { Data = list })