module Client.Page.SensorPictures

open Client.Api
open Client.InfrastructureTypes
open Client.Msg
open Fable.FontAwesome
open Fable.React.Props
open Elmish
open Fulma
open Shared.Dto.SensorPictureDto
open Fable.React

type ListDataModel = {
    Pictures: SensorPictureDto list
    CurrentIndex: int
}

type DataModel =
    | NoPictures
    | List of ListDataModel

type Model = Loadable<DataModel, unit>

let init id =
    Loadable.Loading(), Cmd.OfAsync.perform api.getSensorPictures id (DataReceived >> SensorPictures)

let update (msg: SensorPicturesMsg) (model: Model) =
    match (msg, model) with
    | DataReceived pictures, Loadable.Loading _ ->

        let newModel =
            if List.isEmpty pictures then
                NoPictures
            else
                List {
                    Pictures = pictures
                    CurrentIndex = 0
                }

        Loadable.Data newModel, Cmd.none
    | PreviousPicture, Loadable.Data(List dataModel) ->
        let newIndex = dataModel.CurrentIndex - 1

        let newIndex =
            if newIndex < 0 then
                List.length dataModel.Pictures - 1
            else
                newIndex

        Loadable.Data(
            List {
                dataModel with
                    CurrentIndex = newIndex
            }
        ),
        Cmd.none
    | NextPicture, Loadable.Data(List dataModel) ->
        let newIndex = dataModel.CurrentIndex + 1

        let newIndex =
            if newIndex > List.length dataModel.Pictures - 1 then
                0
            else
                newIndex

        Loadable.Data(
            List {
                dataModel with
                    CurrentIndex = newIndex
            }
        ),
        Cmd.none
    | _, _ -> model, Cmd.none

let private listView dispatch (model: ListDataModel) =
    let currentPicture = model.Pictures.[model.CurrentIndex]

    let caption =
        sprintf "Bild %d von %d" (model.CurrentIndex + 1) (List.length model.Pictures)

    Hero.hero [
        Hero.IsFullHeight
        Hero.Props [ Style [ Height "100vh" ] ]
    ] [
        Hero.head [] [
            Container.container [ Container.CustomClass "has-text-centered" ] [
                Heading.h1 [] [ str currentPicture.Name ]
            ]
        ]
        Hero.body [
            GenericOption.Props [
                Style [ Custom("overflow", "auto"); FlexShrink 1 ]
            ]
        ] [
            Container.container [
                Container.CustomClass "has-text-centered"
                Container.Props [ Style [ Height "100%" ] ]
            ] [
                img [
                    Src currentPicture.Path
                    Style [ MaxHeight "100%" ]
                ]
                p [] [ str caption ]

                a [
                    Style [
                        Position PositionOptions.Fixed
                        Left "10%"
                        Top "50%"
                        Color "black"
                    ]
                    OnClick(fun event ->
                        event.preventDefault ()
                        PreviousPicture |> SensorPictures |> dispatch
                    )
                ] [
                    Fa.i [ Fa.Solid.ChevronCircleLeft; Fa.Size Fa.Fa2x ] []
                ]
                a [
                    Style [
                        Position PositionOptions.Fixed
                        Right "10%"
                        Top "50%"
                        Color "black"
                    ]
                    OnClick(fun event ->
                        event.preventDefault ()
                        NextPicture |> SensorPictures |> dispatch
                    )
                ] [
                    Fa.i [ Fa.Solid.ChevronCircleRight; Fa.Size Fa.Fa2x ] []
                ]
            ]
        ]
    ]

let private emptyListView =
    Hero.hero [ Hero.IsFullHeight ] [
        Hero.head [] []
        Hero.body [] [
            Container.container [] [
                Heading.h1 [] [
                    str "Es wurden keine Bilder für diesen Sensor gefunden"
                ]
            ]
        ]
        Hero.foot [] []
    ]

let private dataView dispatch (data: DataModel) =
    match data with
    | NoPictures -> emptyListView
    | List pictures -> listView dispatch pictures

let view dispatch (model: Model) = Loadable.view (dataView dispatch) model