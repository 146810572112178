module Client.Forms.TtnSensor

open Client.Msg
open Fulma
open Microsoft.FSharp.Core
open Shared
open Shared.Dto.Dto
open Fable.React
open Fable.React.Props

type Model = {
    Name: string option
    AppEui: string option
    DeviceEui: string option
    AppKey: string option
    SelectedType: SensorModel
    Description: string option
    RequestRunning: bool
}

type Msg =
    | NameUpdated
    | SelectedTypeUpdated

let init = {
    Name = None
    AppEui = None
    DeviceEui = None
    AppKey = None
    SelectedType = LHT65
    Description = None
    RequestRunning = false
}

let private tryCreateSensor (model: Model) =
    let maybeBaseData =
        Some createTtnSensorBaseData
        |> Option.apply (model.DeviceEui |> Option.map (fun eui -> sprintf "eui-%s" (eui.ToLower())))
        |> Option.apply model.Name
        |> Option.apply model.DeviceEui
        |> Option.apply model.AppEui
        |> Option.apply model.AppKey
        |> Option.apply (Some model.Description)

    Option.map
        (fun baseData -> {
            BaseData = baseData
            Configuration = model.SelectedType
        })
        maybeBaseData

let typeToOption sensorType isSelected =
    let sensorTypeString = sensorTypeToString sensorType
    option [ Value sensorTypeString; Selected isSelected ] [ str sensorTypeString ]

let typeSelect (selected: SensorModel) dispatch =
    let options =
        [
            LHT65
            LSN50v2_S31
            LSE01
            LSN50v2_Rain
            LLMS01
            LSPH01
            LSN50v2_Wind
        ]
        |> List.map (fun sensorType -> typeToOption sensorType (selected = sensorType))

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue(
                    List.tryFind (fun sensorType -> sensorType = selected) [ LHT65; LSN50v2_S31; LSE01; LSN50v2_Rain ]
                    |> Option.defaultValue LHT65
                    |> sensorTypeToString
                )
                OnChange(fun event ->
                    dispatch (
                        TtnSensorFormMsg.SelectedSensorTypeChanged(sensorTypeFromString event.Value |> Option.get)
                    )
                )
            ]
            options
    ]

let private createFormField label id control =
    Field.div [] [
        Label.label [ Label.For id ] [ str label ]
        control
    ]

let private createSensorTypeField sensorType dispatch =
    let control = Control.div [ Control.IsExpanded ] [ typeSelect sensorType dispatch ]

    createFormField "Sensortyp" "sensor_type" control

let private createNameField name dispatch =
    let id = "name"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Placeholder "MS-TLxxx"
                Input.Value(Option.defaultValue "" name)
                Input.OnChange(fun event -> event.Value |> String.toOption |> TtnSensorFormMsg.NameChanged |> dispatch)
            ]
        ]

    createFormField "Eindeutiger Name des Sensors" id control

let private createAppEuiField appEui dispatch =
    let id = "app_eui"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Value(Option.defaultValue "" appEui)
                Input.OnChange(fun event ->
                    event.Value |> String.toOption |> TtnSensorFormMsg.AppEuiChanged |> dispatch
                )
            ]
        ]

    createFormField "App EUI" id control

let private createDeviceEuiField deviceEui dispatch =
    let id = "device_eui"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Value(Option.defaultValue "" deviceEui)
                Input.OnChange(fun event ->
                    event.Value |> String.toOption |> TtnSensorFormMsg.DeviceEuiChanged |> dispatch
                )
            ]
        ]

    createFormField "Device EUI" id control

let private createAppKeyField appKey dispatch =
    let id = "app_key"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Value(Option.defaultValue "" appKey)
                Input.OnChange(fun event ->
                    event.Value |> String.toOption |> TtnSensorFormMsg.AppKeyChanged |> dispatch
                )
            ]
        ]

    createFormField "App Key" id control

let private createDescriptionField description dispatch =
    let id = "description"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Value(Option.defaultValue "" description)
                Input.OnChange(fun event ->
                    event.Value
                    |> String.toOption
                    |> TtnSensorFormMsg.DescriptionChanged
                    |> dispatch
                )
            ]
        ]

    createFormField "Beschreibung" id control

let form dispatch (model: Model) =
    form [] [
        Field.div [] [
            createNameField model.Name dispatch
            createAppEuiField model.AppEui dispatch
            createDeviceEuiField model.DeviceEui dispatch
            createAppKeyField model.AppKey dispatch
            createSensorTypeField model.SelectedType dispatch
            createDescriptionField model.Description dispatch
        ]
    ]

let buttonOptions (model: Model) dispatch =
    let maybeSensor = tryCreateSensor model

    let dynamicButtonOptions =
        match maybeSensor with
        | Some sensor -> [
            Button.Disabled false
            Button.OnClick(fun _ -> dispatch (TtnSensorListMsg.CreateSensor sensor))
          ]
        | None -> [ Button.Disabled true ]

    List.append
        [
            Button.IsLoading model.RequestRunning
            Button.Color IsSuccess
        ]
        dynamicButtonOptions

let view dispatch (model: Model) =
    let closeModal = (fun _ -> dispatch TtnSensorListMsg.CloseModal)

    let headline = "Neuer physikalischer Sensor erstellen"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [ Props [ OnClick closeModal ] ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [
                Content.content [] [ form (TtnSensorListMsg.Form >> dispatch) model ]
            ]
            Modal.Card.foot [] [
                Button.button (buttonOptions model dispatch) [ str "Speichern" ]
            ]
        ]
    ]