module Client.Components.SubmitButton

open Browser.Types
open Fulma

let onClick (f: MouseEvent -> Unit) =
    Button.OnClick(fun event ->
        event.preventDefault ()

        f event
    )