module Client.Components.Graph.RainFallSensor

open System
open Client.Components.GraphCommon
open Client.DateTime
open Feliz.Recharts
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData

type RainFallSensorGraphNode = {
    Date: int64
    Datum: string
    AmountOfRain: float
    RainSum: float
}

type RainFallSensorGraphData = {
    AmountOfRainAxisTicks: float list
    Data: RainFallSensorGraphNode list
}

let createRainFallSensorGraphData (data: RainFallSensorGraphNode list) =
    let amountAxisTicks =
        if not (List.isEmpty data) then
            createAxisTicksBy true 0.1 (fun node -> node.AmountOfRain) data
        else
            []

    {
        AmountOfRainAxisTicks = amountAxisTicks
        Data = data
    }

let toRainFallSensorGraphData (sum: float) (data: GraphRainFallDataDto) =
    let data = {
        Date = data.TimeStamp.Ticks
        Datum = data.TimeStamp.ToString("dd.MM HH:mm")
        AmountOfRain = Math.Round(data.CurrentRainFall, 2)
        RainSum = Math.Round(sum, 2)
    }

    data

let listToRainFallSensorGraphData (dataList: GraphRainFallDataDto list) =
    let mutable currentSum = 0.

    let mutable lastTotalRainFall =
        dataList
        |> List.tryHead
        |> Option.map (fun data -> data.TotalRainFall)
        |> Option.defaultValue 0.

    let f (data: GraphRainFallDataDto) =
        if lastTotalRainFall > data.TotalRainFall then
            lastTotalRainFall <- data.TotalRainFall
        else
            ()

        data

    dataList
    |> List.map (
        f
        >> fun data ->
            let sum = data.TotalRainFall - lastTotalRainFall
            currentSum <- currentSum + sum

            lastTotalRainFall <- data.TotalRainFall

            toRainFallSensorGraphData currentSum data
    )

let mapDataToRainFall (data: GraphDataDto) =
    match data with
    | RainFall rainFallData -> Some rainFallData
    | _ -> None

let graphDataDtoToRainFallGraphData data =
    data
    |> List.map mapDataToRainFall
    |> List.choose id
    |> listToRainFallSensorGraphData
    |> createRainFallSensorGraphData

let currentRainfallDataBox (data: RainFallSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (dateTimeToString data.Date)
        currentDataLevelItem "Regen aktuell" (rainFallToString data.CurrentRainFall)
        currentDataLevelItem "Regen letzte Stunde" (rainFallToString data.RainFallLastHour)
        currentDataLevelItem "Regen Heute" (rainFallToString data.RainFallToday)
    ]
]

let rainFallDataGraphs (graphData: RainFallSensorGraphData) =
    let amountAxisTicks =
        Interop.mkYAxisAttr "ticks" (List.toArray graphData.AmountOfRainAxisTicks)

    let rainFallChart =
        Recharts.barChart [
            barChart.data graphData.Data
            barChart.syncId "syncId"
            barChart.children [
                Recharts.xAxis [ xAxis.dataKey (fun p -> p.Datum) ]
                Recharts.yAxis [
                    amountAxisTicks
                    yAxis.dataKey (fun p -> p.AmountOfRain)
                    yAxis.unit "mm"
                    yAxis.number
                ]
                Recharts.tooltip []
                Recharts.legend []

                Recharts.bar [
                    bar.dataKey (fun p -> p.AmountOfRain)
                    bar.name "Regenmenge [mm/15 Minuten]"
                    bar.fill "#0033FF"
                ]
            ]
        ]
        |> fullHeightGraphBox "Regenmenge [mm/15 Minuten]"

    let sumChart =
        Recharts.areaChart [
            areaChart.data graphData.Data
            areaChart.syncId "syncId"
            areaChart.children [
                Recharts.xAxis [ xAxis.dataKey (fun p -> p.Datum) ]
                Recharts.yAxis [
                    yAxis.allowDecimals false
                    yAxis.dataKey (fun p -> p.RainSum)
                    yAxis.unit "mm"
                    yAxis.number
                ]
                Recharts.tooltip []
                Recharts.legend []

                Recharts.area [
                    area.dataKey (fun p -> p.RainSum)
                    area.name "Regenmenge [mm]"
                    area.fill "#0033FF"
                    area.fillOpacity 0.3
                ]
            ]
        ]
        |> fullHeightGraphBox "Summe der Regenmenge [mm]"

    [ rainFallChart; sumChart ]