module Client.Forms.Gateway

open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fulma
open Shared
open Fable.React
open Fable.React.Props
open Shared.Dto.MySensGateway
open Shared.Dto.User

type GatewayData = {
    ExistingGateway: IdValue<MySensGateway>
    Latitude: double option
    Longitude: double option
    UserSelected: IdValue<UserDto> option
}

type Model = {
    Gateway: GatewayData
    Users: IdValue<UserDto> list
    RequestRunning: bool
}

let init (users: IdValue<UserDto> list) (gateway: IdValue<MySensGateway>) =
    let gatewayData =
        let user =
            Option.map
                (fun userId -> List.tryFind (fun (user: IdValue<_>) -> user.Id = userId) users)
                gateway.Value.UserId
            |> Option.flatten

        {
            ExistingGateway = gateway
            Latitude = gateway.Value.Latitude
            Longitude = gateway.Value.Longitude
            UserSelected = user
        }

    {
        Gateway = gatewayData
        RequestRunning = false
        Users = users
    }

let userToOption (user: IdValue<UserDto>) =
    option [ Value user.Id ] [ str (getFullName user.Value) ]

let userSelect (selected: IdValue<UserDto> option) (list: IdValue<UserDto> list) dispatch =
    let options = option [ Value "" ] [ str "-" ] :: List.map userToOption list

    let findUser =
        fun (maybeId: string) ->
            Int.tryParseString maybeId
            |> Option.map (fun userId -> List.tryFind (fun (user: IdValue<_>) -> user.Id = userId) list)
            |> Option.flatten

    let selectedValue =
        Option.map (fun (user: IdValue<_>) -> user.Id.ToString()) selected
        |> Option.defaultValue ""

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue selectedValue
                OnChange(fun event ->
                    dispatch (findUser event.Value |> GatewayListMsg.SelectedUserUpdated |> GatewayList)
                )
            ]
            options
    ]

let inputToDoubleOption (str: string) =
    str |> String.toOption |> Option.map double

let form dispatch (model: Model) =
    let optionalDoubleToString =
        fun maybeDouble -> Option.map string maybeDouble |> Option.defaultValue ""

    form [] [
        Field.div [] [
            Label.label [] [ str "Breitengrad" ]
            Control.div [] [
                Input.number [
                    Input.Placeholder "47.155999194351715"
                    model.Gateway.Latitude |> optionalDoubleToString |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> inputToDoubleOption
                        |> GatewayListMsg.LatitudeUpdated
                        |> GatewayList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Längengrad" ]
            Control.div [] [
                Input.number [
                    Input.Placeholder "15.649259567260742"
                    model.Gateway.Longitude |> optionalDoubleToString |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> inputToDoubleOption
                        |> GatewayListMsg.LongitudeUpdated
                        |> GatewayList
                        |> dispatch
                    )
                ]
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Benutzer" ]
            Control.div [ Control.IsExpanded ] [
                userSelect model.Gateway.UserSelected model.Users dispatch
            ]
        ]
    ]

let createUpdateGatewayMsg (existingGateway: IdValue<MySensGateway>) latitude longitude selectedUser =
    let newGatewayData = {
        existingGateway.Value with
            Latitude = latitude
            Longitude = longitude
            UserId = Option.map (fun (selected: IdValue<_>) -> selected.Id) selectedUser
    }

    GatewayListMsg.UpdateGateway {
        existingGateway with
            Value = newGatewayData
    }

let saveButton dispatch (model: Model) =
    let msg =
        createUpdateGatewayMsg
            model.Gateway.ExistingGateway
            model.Gateway.Latitude
            model.Gateway.Longitude
            model.Gateway.UserSelected

    let buttonOptions = [
        Button.IsLoading model.RequestRunning
        Button.Color IsSuccess
        Button.OnClick(fun _ -> dispatch (GatewayList msg))
    ]

    Button.button buttonOptions [ str "Speichern" ]

let view dispatch (model: Model) =
    let closeModal = (fun _ -> dispatch (GatewayListMsg.CloseModal |> GatewayList))

    let headline =
        sprintf "Hitzekarte Klar - Gateway '%s' bearbeiten" model.Gateway.ExistingGateway.Value.Name

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [ Props [ OnClick closeModal ] ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
            Modal.Card.foot [] [ saveButton dispatch model ]
        ]
    ]

let update (msg: GatewayListMsg) (model: Model) =
    let gatewayData =
        match msg with
        | GatewayListMsg.SelectedUserUpdated selectedUser -> {
            model.Gateway with
                UserSelected = selectedUser
          }
        | GatewayListMsg.LatitudeUpdated latitude -> {
            model.Gateway with
                Latitude = latitude
          }
        | GatewayListMsg.LongitudeUpdated longitude -> {
            model.Gateway with
                Longitude = longitude
          }
        | _ -> model.Gateway

    { model with Gateway = gatewayData }, Cmd.none