module Client.Page.MyMapSensors

open Client
open Client.Api
open Client.Components
open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fulma
open Fable.React
open Shared.Dto.Dto
open Shared.Dto.MyMapSensors
open Thoth.Elmish

type DataModel = {
    Sensors: MyMapSensorDto list
    Session: UserSession
}

type Model = Loadable<DataModel, UserSession>

let private changeStatus (isDisabled: bool) (sensorId: int) (list: MyMapSensorDto list) =
    list
    |> List.map (fun sensor ->
        if sensor.Id = sensorId then
            { sensor with IsDisabled = isDisabled }
        else
            sensor
    )

let init (session: UserSession) =
    let requestData = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmd =
        Cmd.OfAsync.perform api.getMyMapSensors requestData (MySensorsLoaded >> MyMapSensors)

    Loadable.Loading session, cmd

let update (msg: MyMapSensorsMsg) (model: Model) =
    match (msg, model) with
    | MySensorsLoaded(Ok sensors), Loadable.Loading session ->
        Loadable.Data { Sensors = sensors; Session = session }, Cmd.none
    | MySensorsLoaded(Error _), Loadable.Loading _ -> Loadable.Error "Fehler beim Laden deiner Sensoren", Cmd.none
    | ActivateSensor id, Loadable.Data dataModel ->
        let requestData = {
            SessionKey = dataModel.Session.SessionKey
            Data = id
        }

        let cmd =
            Cmd.OfAsync.perform
                api.activateMapSensor
                requestData
                (fun successful -> SensorActivated(id, successful) |> MyMapSensors)

        model, cmd
    | SensorActivated(sensorId, response), Loadable.Data dataModel ->
        match response with
        | Ok true ->
            let toastCmd = Toast.create "Sensor wurde erfolgreich aktiviert" |> Toast.success

            Loadable.Data {
                dataModel with
                    Sensors = changeStatus false sensorId dataModel.Sensors
            },
            toastCmd
        | Ok false ->
            let toastCmd =
                Toast.create "Beim Aktivieren des Sensors ist ein Fehler aufgetreten"
                |> Toast.error

            model, toastCmd
        | Error _ ->
            let toastCmd =
                Toast.create "Beim Aktivieren des Sensors ist ein Fehler aufgetreten"
                |> Toast.error

            model, toastCmd
    | DisableSensor id, Loadable.Data dataModel ->
        let requestData = {
            SessionKey = dataModel.Session.SessionKey
            Data = id
        }

        let cmd =
            Cmd.OfAsync.perform
                api.disableMapSensor
                requestData
                (fun successful -> SensorDisabled(id, successful) |> MyMapSensors)

        model, cmd
    | SensorDisabled(sensorId, response), Loadable.Data dataModel ->
        match response with
        | Ok true ->
            let toastCmd = Toast.create "Sensor wurde erfolgreich deaktiviert" |> Toast.success

            Loadable.Data {
                dataModel with
                    Sensors = changeStatus true sensorId dataModel.Sensors
            },
            toastCmd
        | Ok false ->
            let toastCmd =
                Toast.create "Beim Deaktivieren des Sensors ist ein Fehler aufgetreten"
                |> Toast.error

            model, toastCmd
        | Error _ ->
            let toastCmd =
                Toast.create "Beim Deaktivieren des Sensors ist ein Fehler aufgetreten"
                |> Toast.error

            model, toastCmd
    | _, _ -> model, Cmd.none

let private createActivationButton (dispatch: Msg -> unit) (sensor: MyMapSensorDto) =
    let options, children =
        if sensor.IsDisabled then
            [
                Button.Color Color.IsSuccess
                Button.OnClick(fun _ -> ActivateSensor sensor.Id |> MyMapSensors |> dispatch)
            ],
            [ str "Aktiveren" ]
        else
            [
                Button.Color Color.IsDanger
                Button.OnClick(fun _ -> DisableSensor sensor.Id |> MyMapSensors |> dispatch)
            ],
            [ str "Deaktivieren" ]

    Button.button options children

let private createSettingsButton dispatch (sensor: MyMapSensorDto) =
    let onClick =
        SubmitButton.onClick (fun _ ->
            Routing.UserDefinedMapSensorProperties sensor.Id
            |> GoToRoute
            |> Global
            |> dispatch
        )

    Button.button [ Button.Color Color.IsLink; onClick ] [ str "Einstellungen" ]

let private sensorToBox dispatch (sensor: MyMapSensorDto) =
    Box.box' [] [
        Heading.h1 [] [ str sensor.Name ]
        Level.level [] [
            Level.left [] []
            Level.right [] [
                Level.item [] [ createActivationButton dispatch sensor ]
                Level.item [] [ createSettingsButton dispatch sensor ]
            ]
        ]
    ]

let dataView dispatch (model: DataModel) =
    Container.container [] (List.map (sensorToBox dispatch) model.Sensors)

let view dispatch (model: Model) = Loadable.view (dataView dispatch) model