namespace Shared

open System
open Microsoft.FSharp.Control
open Microsoft.FSharp.Core
open Shared.Dto
open Shared.Dto.Dto
open Shared.Dto.MapSensorData
open Shared.Dto.MapSensorSettings
open Shared.Dto.MyMapSensors
open Shared.Dto.MySensGateway
open Shared.Dto.PublicHistory
open Shared.Dto.SensorGraphData
open Shared.Dto.SensorPictureDto
open Shared.Dto.User
open Shared
open Shared.WithLastDate

module Route =
    let builder typeName methodName =
        sprintf "/api/%s/%s" typeName methodName

type MySenseApi = {
    getSession: SessionKey option -> Async<Session>

    getPublicSensors: Unit -> Async<MapSensor list>

    getSensors: SessionKey -> Async<AuthenticatedResponse<MapSensor list>>

    login: LoginRequestData -> Async<LoginResponse>
    logout: SessionKey -> Async<Unit>

    refreshTtiEndDevices: AuthenticatedRequest<unit> -> Async<bool>

    getAllTtnSensors: Unit -> Async<TtnSensorWithLastDate list>

    getConfiguredTtnSensors: Unit -> Async<ConfiguredTtnSensor list>

    createPhysicalSensor: AuthenticatedRequest<ConfiguredTtnSensor> -> Async<AuthenticatedResponse<unit>>

    removePhysicalSensor: AuthenticatedRequest<string> -> Async<AuthenticatedResponse<unit>>

    getAllMySensSensors: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<MySensSensor list>>

    getMySensSensor: int -> Async<MySensSensor option>

    getSensorType: int -> Async<SensorModel>

    getMapSensorData: int -> Async<MapSensorData option>

    getHistoricMapSensorData: SensorDataRequest -> Async<GraphDataDto list>

    getSensorDetailPageData: AuthenticatedRequest<int> -> Async<SensorDetailPage.Response>

    downloadMapSensorData: AuthenticatedRequest<SensorDataRequest> -> Async<AuthenticatedResponse<byte[]>>

    getAllMySensSensorsWithOptionalLastDate: Unit -> Async<WithOptionalLastDate<MySensSensor> list>

    createMySensSensor: NewMySensSensor -> Async<bool>

    updateMySensSensor: MySensSensor -> Async<bool>

    deleteMySensSensor: int -> Async<bool>

    updatePassword: AuthenticatedRequest<string * string> -> Async<bool>

    getAllUsers: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<IdValue<UserDto> list>>

    createUser: UserDto -> Async<bool>

    updateUser: IdValue<UserDto> -> Async<bool>

    deleteUser: int -> Async<bool>

    addPackage: int * Package -> Async<bool>

    sendPasswordResetRequest: string -> Async<bool>

    getPasswordResetRequest: string -> Async<PasswordResetRequest option>

    resetPassword: string * string -> Async<bool>

    getAllGateways: Unit -> Async<IdValue<MySensGateway> list>

    getAllGatewaysWithOptionalLastDate: Unit -> Async<WithOptionalLastDate<IdValue<MySensGateway>> list>

    refreshGatewaysFromTti: Unit -> Async<bool>

    updateGateway: IdValue<MySensGateway> -> Async<bool>

    getLeafletWetnessConfiguration: Unit -> Async<LeafletWetnessConfiguration>

    updateLeafletWetnessConfiguration: LeafletWetnessConfiguration -> Async<bool>

    getContactInfos: AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<AlertDto.ContactInfo list>>

    getMyContactInfos: AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<AlertDto.ContactInfo list>>

    createContactInfo: AuthenticatedRequest<AlertDto.NewContactInfo> -> Async<AuthenticatedResponse<bool>>

    updateContactInfo: AuthenticatedRequest<AlertDto.ContactInfo> -> Async<AuthenticatedResponse<bool>>

    deleteContactInfo: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<bool>>

    getMyAirTemperatureMapSensors: AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<MySensSensor list>>

    buyAlertPackages: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<bool>>

    getMyMapSensorsData: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<MapSensor list>>

    getPurchasedAlertCounts: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<AlertDto.PurchasedAlertCount>>

    getMapSensorSettings: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<MapSensorSettingsDto>>

    updateMapSensorSettings: AuthenticatedRequest<UpdatedMapSensorSettingsDto> -> Async<AuthenticatedResponse<bool>>

    getPublicHistory: int -> Async<PublicHistoryDto>

    removeSensorPicture: Guid -> Async<bool>

    addSensorPicture: NewSensorPictureDto -> Async<bool>

    getSensorPictures: int -> Async<SensorPictureDto list>

    getMyMapSensors: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<MyMapSensorDto list>>

    activateMapSensor: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<bool>>

    disableMapSensor: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<bool>>
}