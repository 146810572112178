namespace Shared

[<RequireQualifiedAccess>]
module Option =
    let apply (maybeData: 'a option) (maybeF: ('a -> 'b) option) =
        Option.map2 (fun f data -> f data) maybeF maybeData

    let ofBoolean (boolean: bool) (value: 'a) = if boolean then Some value else None

    let fromResult (result: Result<'a, 'b>) : 'a option =
        match result with
        | Ok a -> Some a
        | Error _ -> None

    let ofTryValue (maybeValue: bool * 'a) : 'a option =
        if fst maybeValue = true then
            Some <| snd maybeValue
        else
            None

    let ofTryTuple (found: bool, item: 'a) : 'a option = if found then Some item else None