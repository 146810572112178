module Client.Forms.ChangePassword

open Elmish
open Client.Api
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Client
open Client.Components
open Shared
open Client.Msg
open Shared.Dto.Dto
open Thoth.Elmish

type Model = {
    Session: UserSession
    OldPassword: string option
    NewPassword: string option
    NewPasswordRepeated: string option
    RequestRunning: bool
}

let init session = {
    Session = session
    OldPassword = None
    NewPassword = None
    NewPasswordRepeated = None
    RequestRunning = false
}

let update (msg: ChangePasswordMsg) (model: Model) =
    match msg with
    | OldPasswordUpdated oldPassword -> { model with OldPassword = oldPassword }, Cmd.none
    | NewPasswordUpdated newPassword -> { model with NewPassword = newPassword }, Cmd.none
    | NewPasswordRepeatedUpdated newPasswordRepeated ->
        {
            model with
                NewPasswordRepeated = newPasswordRepeated
        },
        Cmd.none
    | Request(oldPassword, newPassword) ->
        let cmd =
            Cmd.OfAsync.perform
                api.updatePassword
                {
                    SessionKey = model.Session.SessionKey
                    Data = (oldPassword, newPassword)
                }
                Response

        { model with RequestRunning = true }, cmd
    | Response successful ->
        if successful then
            let toastCmd = Toast.create "Passwort wurde geändert" |> Toast.success

            init model.Session, toastCmd
        else
            let toastCmd = Toast.create "Ein Fehler ist aufgetreten" |> Toast.error

            model, toastCmd

let buttonOptions (model: Model) dispatch =
    let maybeRequestData =
        Option.map3
            (fun oldPassword newPassword newPasswordRepeated ->
                if newPassword = newPasswordRepeated then
                    Some(oldPassword, newPassword)
                else
                    None
            )
            model.OldPassword
            model.NewPassword
            model.NewPasswordRepeated
        |> Option.flatten

    let buttonOptions = [
        Button.Color IsLink
        Button.IsLoading model.RequestRunning
        Button.Disabled(Option.isNone maybeRequestData)
        Button.Props [ HTMLAttr.Type "submit" ]
    ]

    maybeRequestData
    |> Option.map (fun requestData -> SubmitButton.onClick (fun _ -> dispatch (Request requestData)))
    |> Lists.addToListIfSome buttonOptions

let view (model: Model) dispatch =
    form [] [
        Field.div [] [
            Label.label [] [ str "Altes Passwort" ]
            Control.div [ Control.HasIconLeft ] [
                Input.password [
                    Input.Id "password"
                    Input.OnChange(fun event -> event.Value |> String.toOption |> OldPasswordUpdated |> dispatch)
                ]

                Icon.icon [ Icon.Size IsSmall; Icon.IsLeft ] [ Fa.i [ Fa.Solid.Key ] [] ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Neues Passwort" ]
            Control.div [ Control.HasIconLeft ] [
                Input.password [
                    Input.Id "newPassword"
                    Input.OnChange(fun event -> event.Value |> String.toOption |> NewPasswordUpdated |> dispatch)
                ]

                Icon.icon [ Icon.Size IsSmall; Icon.IsLeft ] [ Fa.i [ Fa.Solid.Key ] [] ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Neues Passwort wiederholen" ]
            Control.div [ Control.HasIconLeft ] [
                Input.password [
                    Input.Id "newPasswordRepeated"
                    Input.OnChange(fun event ->
                        event.Value |> String.toOption |> NewPasswordRepeatedUpdated |> dispatch
                    )
                ]

                Icon.icon [ Icon.Size IsSmall; Icon.IsLeft ] [ Fa.i [ Fa.Solid.Key ] [] ]
            ]
        ]
        Field.div [ Field.IsGroupedRight ] [
            Control.p [] [

                Button.button (buttonOptions model dispatch) [ str "Passwort ändern" ]
            ]
        ]
    ]