module Client.Page.Home

open Client.Msg
open Client.Routing
open Client.Views.PageSkeleton
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Shared.Dto.User
open Shared

let stringNoneIfEmpty str =
    if System.String.IsNullOrEmpty str then None else Some str

let view (session: IdValue<UserDto>) dispatch =
    Hero.hero [ Hero.IsFullheightWithNavbar ] [
        Hero.head [] []
        Hero.body [] [
            Container.container [] [
                p [
                    Style [ TextAlign TextAlignOptions.Center ]
                    Class "title"
                ] [ str "Willkommen bei Hitzekarte Klar! " ]

                p [
                    Style [ TextAlign TextAlignOptions.Center ]
                    Class "sub-title"
                ] [
                    str (
                        "Eingeloggt als "
                        + (getFirstName session.Value)
                        + " "
                        + (getLastName session.Value)
                    )
                ]

                p [
                    Style [
                        TextAlign TextAlignOptions.Center
                        MarginTop "3rem"
                    ]
                ] [
                    Button.button [
                        Button.Color Color.IsLink
                        Button.OnClick(fun _ -> dispatch (SensorMap None |> GoToRoute |> Global))
                        Button.IsOutlined
                    ] [
                        Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Globe ] [] ]
                        span [] [ str "Zur Sensorenkarte" ]
                    ]
                ]
                p [
                    Style [
                        TextAlign TextAlignOptions.Center
                        MarginTop "3rem"
                    ]
                ] [
                    Button.button [
                        Button.Color Color.IsLink
                        Button.OnClick(fun _ -> dispatch (GoToRoute MySensorsData |> Global))
                        Button.IsOutlined
                    ] [
                        Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Globe ] [] ]
                        span [] [ str "Zu den Sensordaten" ]
                    ]
                ]
            ]
        ]
        Hero.foot [] [ mySensFooter ]
    ]