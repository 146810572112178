module internal Client.LeafletWetness

open System
open Shared.Dto.Dto
open Shared.Dto.MapSensorData

let getLeafletWetness (data: MapSensorData) =
    match data with
    | AirData airData ->
        let minutesSinceLastData = (DateTime.Now - airData.Date).TotalMinutes

        if minutesSinceLastData <= 60.0 then
            Some airData.LeafletWetness
        else
            None
    | _ -> None

let getLeafletMoisture (data: MapSensorData) =
    match data with
    | LeafletMoistureData moistureData ->
        let minutesSinceLastData = (DateTime.Now - moistureData.Date).TotalMinutes

        if minutesSinceLastData <= 60.0 then
            Some moistureData.LeafletMoisture
        else
            None
    | _ -> None

let toImageSrc (wetness: LeafletWetness) =
    let file =
        match wetness with
        | Wet _ -> "BN100"
        | Dry -> "BN0"

    sprintf "/images/leaflet-wetness/%s.svg" file

let percentageToImageSrc (moisture: float) =
    let fileName =
        if moisture < 50. then "BN0"
        else if moisture < 98. then "BN50"
        else "BN100"

    sprintf "/images/leaflet-wetness/%s.svg" fileName