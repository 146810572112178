module Client.SensorMap

open System
open Client.InfrastructureTypes
open Elmish
open Fable.FontAwesome
open Fable.React.Props
open Fulma
open Fable.React
open Leaflet
open ReactLeaflet
open Api
open Client.Msg
open Shared.Dto.Dto
open Thoth.Elmish
open Shared.Dto.MapSensorData

let latLngToExpression (latLng: LatLng) : LatLngExpression =
    Fable.Core.Case3(latLng.lat, latLng.lng)

type MapViewData = {
    Center: LatLngExpression
    Zoom: float
}

type Model = {
    Session: SessionKey
    Sensors: MapSensor list
    LastUpdate: DateTime option
    MapViewData: MapViewData
    RefreshRunning: bool
}

let onDrag (dispatch: MapMsg -> Unit) (map: Leaflet.Map) =
    let newCenter = map.getCenter () |> latLngToExpression

    dispatch (MapMoved newCenter)

let onZoom (dispatch: MapMsg -> Unit) (map: Leaflet.Map) = dispatch (map.getZoom () |> MapZoomed)

let view (model: Model) (dispatch: Msg -> Unit) =
    let markers =
        model.Sensors
        |> List.map (fun sensor ->
            let position = makePosition sensor.Latitude sensor.Longitude

            let markerPopup = makeMarkerPopup sensor dispatch

            let airTemperature =
                sensor.Data |> onlyAirData |> Option.map (fun airData -> airData.AirTemperature)

            createCircleMarker position 50. markerPopup airTemperature
        )

    Hero.hero [] [
        map
            [
                MapProps.MaxZoom 18.
                MapProps.MinZoom 12.0
                MapProps.Zoom 13.
                MapProps.Center model.MapViewData.Center
                MapProps.Zoom model.MapViewData.Zoom
                MapProps.ZoomControl false
                MapProps.Style [ Height "calc(100vh - 3.25rem)"; ZIndex "0" ]
                MapProps.OnDragEnd(getMapFromEvent >> onDrag (Map >> dispatch))
                MapProps.OnZoomEnd(getMapFromEvent >> onZoom (Map >> dispatch))
            ]
            (tileLayer [
                TileLayerProps.Url "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
             ] []
             :: markers)

        Button.button [
            Button.IsLoading model.RefreshRunning
            Button.Color Color.IsLink
            Button.Props [
                Style [
                    Position PositionOptions.Absolute
                    Bottom "10px"
                    Right "10px"
                    ZIndex "1"
                ]
            ]
            Button.OnClick(fun _ -> dispatch (Map MapMsg.RefreshData))
        ] [
            Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Sync ] [] ]
            span [] [ str "Aktualisieren" ]
        ]
    ]

let storeMapDataInLocalStorage (data: MapViewData) = LocalStorage.saveJson "map-data" data

let retrieveMapDataFromLocalStorage () =
    LocalStorage.loadJson<MapViewData> "map-data"

let retrieveSensorsCmd sessionKey =
    Cmd.OfAsync.either api.getSensors sessionKey MapMsg.GotSensors MapMsg.SensorLoadingFailed

let private getCenterPosition (maybeExistingData: MapViewData option) (maybeNewCenter: LatLngExpression option) =
    match maybeNewCenter, maybeExistingData with
    | Some newCenter, _ -> newCenter
    | None, Some existingData -> existingData.Center
    | None, None -> LatLngExpression.Case3(47.476275, 9.725183)

let private getZoom (maybeExistingData: MapViewData option) (maybeNewCenter: LatLngExpression option) =
    match maybeNewCenter, maybeExistingData with
    | Some _, _ -> 14.
    | None, Some existingData -> existingData.Zoom
    | None, None -> 8.

let init (maybeCenter: LatLngExpression option) (session: UserSession) =
    let maybeExistingViewData = retrieveMapDataFromLocalStorage ()

    let center = getCenterPosition maybeExistingViewData maybeCenter
    let zoom = getZoom maybeExistingViewData maybeCenter

    let mapViewData = { Center = center; Zoom = zoom }

    storeMapDataInLocalStorage mapViewData

    let model = {
        Sensors = []
        Session = session.SessionKey
        LastUpdate = None
        RefreshRunning = true
        MapViewData = mapViewData
    }

    let cmd = retrieveSensorsCmd session.SessionKey

    model, cmd

let updateSensors sensors =
    let dates =
        sensors
        |> List.map (fun sensor -> sensor.Data)
        |> List.map getSensorDataDate
        |> List.choose id

    let latestDate =
        match dates with
        | x :: _ -> Some(List.max dates)
        | _ -> None

    let toastCmd = Toast.create "Daten erfolgreich geladen" |> Toast.success

    latestDate, toastCmd

let update (msg: MapMsg) (model: Model) =
    match msg with
    | MapMsg.GotSensors sensorsResult ->
        match sensorsResult with
        | Ok sensors ->
            let latestDate, toastCmd = updateSensors sensors

            {
                model with
                    Sensors = sensors
                    LastUpdate = latestDate
                    RefreshRunning = false
            },
            toastCmd
        | Error error ->
            let errorMessage =
                match error with
                | AuthErr Unauthenticated -> "Sie sind nicht eingeloggt, bitte laden Sie die Seite neu"
                | AuthErr Unauthorized ->
                    "Sie sind nicht dazu berechtigt, die Sensorkarte anzuzeigen, bitte wenden Sie sich an einen Administrator"
                | _ -> "Ein Fehler beim Laden der Sensoren ist aufgetreten. Bitte laden Sie die Seite neu"

            let cmd = Toast.create errorMessage |> Toast.error

            { model with RefreshRunning = false }, cmd
    | MapMsg.MapMoved center ->
        let newMapViewData = {
            model.MapViewData with
                Center = center
        }

        storeMapDataInLocalStorage newMapViewData

        {
            model with
                MapViewData = newMapViewData
        },
        Cmd.none
    | MapMsg.MapZoomed zoom ->
        let newMapViewData = { model.MapViewData with Zoom = zoom }

        storeMapDataInLocalStorage newMapViewData

        {
            model with
                MapViewData = newMapViewData
        },
        Cmd.none
    | MapMsg.RefreshData ->
        let cmd = retrieveSensorsCmd model.Session

        { model with RefreshRunning = true }, cmd
    | MapMsg.SensorLoadingFailed ex ->
        let statusCode = Exceptions.getStatusCode ex

        let message =
            match statusCode with
            | 500 -> "Interner Server Fehler ist aufgetreten. Bitte wende dich an den Administrator"
            | _ -> sprintf "Fehlermeldung: '%s'. Bitte wende dich an den Administrator" ex.Message

        let toastCmd =
            Toast.create message
            |> Toast.errorTitle statusCode
            |> Toast.timeout (TimeSpan.FromSeconds 10.0)
            |> Toast.error

        { model with RefreshRunning = false }, toastCmd