module Client.Page.UserDefinedMapSensorProperties

open Browser.Types
open Browser.Navigator
open Client.Api
open Client.Domain
open Client.Forms
open Client.Forms.AddSensorPicture
open Client.InfrastructureTypes
open Client.Msg
open Client
open Shared.Dto.SensorPictureDto
open Thoth.Elmish
open Fable.React.Props
open Fable.React.Standard
open Fable.React
open Fable.Remoting.Client
open Elmish
open Feliz
open Feliz.Bulma.ElementBuilders
open Fulma
open Shared
open Shared.Dto
open Shared.Dto.Dto
open Shared.Dto.MapSensorSettings

type DataModel = {
    Id: int
    SessionKey: SessionKey
    OldProperties: MapSensorSettingsDto
    Name: string option
    Latitude: float option
    Longitude: float option
    SensorPictures: SensorPictureDto list
    DeleteConfirmation: SensorPictureDto option
    AddModal: AddSensorPicture.Model option
    DeviceLocationUpdateRunning: bool
    UpdateRequestRunning: bool
}

type Model = Loadable<DataModel, SessionKey>

let initWithKey id (key: SessionKey) : Model * Cmd<Msg> =
    let requestData = { SessionKey = key; Data = id }

    let cmd =
        Cmd.OfAsync.perform
            api.getMapSensorSettings
            requestData
            (UserDefinedMapSensorPropertiesMsg.DataReceived >> UserDefinedMapSensorProperties)

    Loadable.Loading key, cmd

let init id (session: UserSession) : Model * Cmd<Msg> = initWithKey id session.SessionKey

let private createDataModel sessionKey (properties: MapSensorSettingsDto) : DataModel = {
    Id = properties.Id
    SessionKey = sessionKey
    OldProperties = properties
    Name = properties.Name
    Latitude = Option.map (fun (location: Location) -> location.Latitude) properties.Location
    Longitude = Option.map (fun (location: Location) -> location.Longitude) properties.Location
    SensorPictures = properties.Pictures
    AddModal = None
    DeviceLocationUpdateRunning = false
    DeleteConfirmation = None
    UpdateRequestRunning = false
}

let update (msg: UserDefinedMapSensorPropertiesMsg) (model: Model) =
    match (msg, model) with
    | DeviceLocationRequested, Loadable.Data data ->
        Loadable.Data {
            data with
                DeviceLocationUpdateRunning = true
        },
        Cmd.none
    | DeviceLocationReceived position, Loadable.Data data ->
        let toastCmd =
            Toast.create "GPS Koordinaten des Sensors erfolgreich übernommen"
            |> Toast.success

        Loadable.Data {
            data with
                Latitude = Some position.coords.latitude
                Longitude = Some position.coords.longitude
                DeviceLocationUpdateRunning = false
        },
        toastCmd
    | DeviceLocationFailed _, Loadable.Data data ->
        let toastCmd =
            Toast.create "Beim Auslesen der GPS Koordinaten ist ein Fehler aufgetreten"
            |> Toast.error

        Loadable.Data {
            data with
                DeviceLocationUpdateRunning = false
        },
        toastCmd
    | UserDefinedMapSensorPropertiesMsg.DataReceived response, Loadable.Loading sessionKey ->
        match response with
        | Result.Ok data -> Loadable.Data(createDataModel sessionKey data), Cmd.none
        | Result.Error(AuthErr err) ->
            let message =
                match err with
                | Unauthenticated -> "Du bist nicht eingeloggt, bitte lade Seite nochmal neu"
                | Unauthorized -> "Du darfst diesen Sensor nicht bearbeiten"

            Loadable.Error message, Cmd.none
        | Result.Error(CustomErr err) -> Loadable.Error err, Cmd.none
    | UpdateMapSensorProperties newProperties, Loadable.Data data ->
        let requestData = {
            SessionKey = data.SessionKey
            Data = newProperties
        }

        let cmd =
            Cmd.OfAsync.perform
                api.updateMapSensorSettings
                requestData
                (MapSensorPropertiesUpdated >> UserDefinedMapSensorProperties)

        Loadable.Data {
            data with
                UpdateRequestRunning = true
        },
        cmd
    | MapSensorPropertiesUpdated result, Loadable.Data dataModel ->
        match result with
        | Result.Ok result ->
            let toastCmd =
                Toast.create "Die Daten des Sensors wurden erfolgreich aktualisiert"
                |> Toast.success

            Loadable.Data {
                dataModel with
                    UpdateRequestRunning = false
            },
            toastCmd
        | Result.Error _ ->
            Loadable.Error "Beim Speichern ist ein Fehler aufgetreten. Versuche es bitte erneut", Cmd.none
    | UpdateName name, Loadable.Data dataModel -> Loadable.Data { dataModel with Name = name }, Cmd.none
    | UpdateLatitude latitude, Loadable.Data dataModel -> Loadable.Data { dataModel with Latitude = latitude }, Cmd.none
    | UpdateLongitude longitude, Loadable.Data dataModel ->
        Loadable.Data { dataModel with Longitude = longitude }, Cmd.none
    | DeleteSensorPicture picture, Loadable.Data dataModel ->
        Loadable.Data {
            dataModel with
                DeleteConfirmation = Some picture
        },
        Cmd.none
    | CloseDeleteConfirmationModal, Loadable.Data dataModel ->
        Loadable.Data {
            dataModel with
                DeleteConfirmation = None
        },
        Cmd.none
    | SensorPictureDeletionConfirmed id, _ ->
        model, Cmd.OfAsync.perform api.removeSensorPicture id (SensorPictureDeleted >> UserDefinedMapSensorProperties)
    | SensorPictureDeleted _, Loadable.Data dataModel ->
        let toastCmd = Toast.create "Das Bild wurde erfolgreich gelöscht" |> Toast.success

        let newModel, initCmd = initWithKey dataModel.Id dataModel.SessionKey

        newModel, Cmd.batch [ toastCmd; initCmd ]
    | OpenAddModal, Loadable.Data dataModel ->
        Loadable.Data {
            dataModel with
                AddModal = Some(AddSensorPicture.init dataModel.Id)
        },
        Cmd.none
    | CloseAddModal, Loadable.Data dataModel -> Loadable.Data { dataModel with AddModal = None }, Cmd.none
    | SensorPictureForm formMsg, Loadable.Data dataModel ->
        match dataModel.AddModal with
        | Some modalModel ->
            let newModel, formResult = AddSensorPicture.update formMsg modalModel

            match formResult with
            | Noop ->
                Loadable.Data {
                    dataModel with
                        AddModal = Some newModel
                },
                Cmd.none
            | Close -> Loadable.Data { dataModel with AddModal = None }, Cmd.none
            | FormResult.SavePicture dto ->
                Loadable.Data {
                    dataModel with
                        AddModal = Some newModel
                },
                Cmd.OfAsync.perform api.addSensorPicture dto (SensorPictureAdded >> UserDefinedMapSensorProperties)
            | FormResult.LoadFile file ->
                let fileNameParts = file.name.Split('.')
                let fileExtension = fileNameParts.[fileNameParts.Length - 1]

                let readFile (file: File) =
                    async {
                        let! content = file.ReadAsByteArray()

                        return content
                    }

                model,
                Cmd.OfAsync.perform
                    readFile
                    file
                    (fun content ->
                        FileLoaded(content, fileExtension)
                        |> SensorPictureForm
                        |> UserDefinedMapSensorProperties
                    )
        | None -> model, Cmd.none
    | SensorPictureAdded result, Loadable.Data dataModel ->
        let toastCmd = Toast.create "Bild wurde erfolgreich hinzugefügt" |> Toast.success

        let newModel, cmd = initWithKey dataModel.Id dataModel.SessionKey

        newModel, Cmd.batch [ toastCmd; cmd ]
    | _, _ -> model, Cmd.none

let private locationSuccessCallback dispatch (location: Position) =
    UserDefinedMapSensorPropertiesMsg.DeviceLocationReceived location
    |> UserDefinedMapSensorProperties
    |> dispatch

let private locationErrorCallback dispatch (error: PositionError) =
    UserDefinedMapSensorPropertiesMsg.DeviceLocationFailed error
    |> UserDefinedMapSensorProperties
    |> dispatch

let private createSaveRequestData (id: int) (name: string option) (location: Location) : UpdatedMapSensorSettingsDto = {
    Id = id
    Name = name
    Location = location
}

let private createSaveButton dispatch (model: DataModel) : ReactElement =
    let maybeRequestData =
        Option.map2 Location.create model.Longitude model.Latitude
        |> Option.flatten
        |> Option.map (createSaveRequestData model.OldProperties.Id model.Name)

    let buttonOptions = [
        Button.Color Color.IsSuccess
        Button.IsLoading model.UpdateRequestRunning

        match maybeRequestData with
        | Some requestData ->
            Button.OnClick(fun event ->
                event.preventDefault ()

                UpdateMapSensorProperties requestData
                |> UserDefinedMapSensorProperties
                |> dispatch
            )
        | None -> Button.Disabled true
    ]

    Button.button buttonOptions [ str "Einstellungen speichern" ]

let private pictureToBox dispatch (picture: SensorPictureDto) =
    Media.media [] [
        Media.left [] [
            Image.image [ Image.Is128x128 ] [ img [ Src picture.Path ] ]
        ]
        Media.content [] [
            Field.div [] [ Control.div [] [ h1 [] [ str picture.Name ] ] ]
        ]

        Media.right [] [
            Delete.delete [
                Delete.OnClick(fun _ -> DeleteSensorPicture picture |> UserDefinedMapSensorProperties |> dispatch)
            ] []
        ]
    ]

let private confirmationDialog dispatch (maybeDeletion: SensorPictureDto option) =
    match maybeDeletion with
    | Some deletion ->
        let config: ConfirmationModal.Configuration = {
            Headline = "Sensor Bild löschen"
            Text = sprintf "Wollen Sie das Bild '%s' wirklich löschen?" deletion.Name
            OnClose = (fun _ -> dispatch (UserDefinedMapSensorProperties CloseDeleteConfirmationModal))
            OnNo = (fun _ -> dispatch (UserDefinedMapSensorProperties CloseDeleteConfirmationModal))
            OnYes = (fun _ -> dispatch (UserDefinedMapSensorProperties(SensorPictureDeletionConfirmed deletion.Id)))
        }

        ConfirmationModal.view config
    | None -> div [] []

let private picturesField dispatch (pictures: SensorPictureDto list) =
    let existingPictures = List.map (pictureToBox dispatch) pictures

    let addButton =
        Button.button [
            Button.Color Color.IsLink
            Button.OnClick(fun event ->
                event.preventDefault ()
                OpenAddModal |> UserDefinedMapSensorProperties |> dispatch
            )
        ] [ str "Neues Bild hinzufügen" ]

    Field.div [
        Field.Props [
            Style [ BorderBottom "1px solid black"; Padding "5px" ]
        ]
    ] [
        Label.label [] [ str "Fotos des Sensors" ]
        Control.div [] [ yield! existingPictures; addButton ]
    ]

let formView dispatch (model: DataModel) =
    form [] [
        Field.div [] [
            Label.label [] [ str "Name" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder "MS-TL0815"
                    model.Name |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UpdateName
                        |> UserDefinedMapSensorProperties
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [ Field.IsHorizontal ] [
            Field.body [] [
                Field.div [] [
                    Label.label [] [ str "Breitengrad" ]
                    Control.div [] [
                        Input.number [
                            Input.Placeholder "47.155999194351715"
                            model.Latitude |> Inputs.optionalDoubleToString |> Input.Value
                            Input.OnChange(fun event ->
                                event.Value
                                |> Inputs.toDoubleOption
                                |> UpdateLatitude
                                |> UserDefinedMapSensorProperties
                                |> dispatch
                            )
                        ]
                    ]
                ]
                Field.div [] [
                    Label.label [] [ str "Längengrad" ]
                    Control.div [] [
                        Input.number [
                            Input.Placeholder "15.649259567260742"
                            model.Longitude |> Inputs.optionalDoubleToString |> Input.Value
                            Input.OnChange(fun event ->
                                event.Value
                                |> Inputs.toDoubleOption
                                |> UpdateLongitude
                                |> UserDefinedMapSensorProperties
                                |> dispatch
                            )
                        ]
                    ]
                ]
            ]
        ]
        Field.div [
            Field.Props [
                Style [ BorderBottom "1px solid black"; Padding "5px" ]
            ]
        ] [
            Control.div [] [
                Button.button [
                    Button.IsLoading model.DeviceLocationUpdateRunning
                    Button.Color Color.IsLink
                    match navigator.geolocation with
                    | Some geolocation ->
                        Button.OnClick(fun event ->
                            event.preventDefault ()
                            dispatch (UserDefinedMapSensorProperties DeviceLocationRequested)

                            DeviceLocation.request
                                (locationSuccessCallback dispatch)
                                (locationErrorCallback dispatch)
                                geolocation
                        )
                    | None -> Button.Disabled true
                ] [ str "Aktuellen Standort übernehmen" ]
            ]
        ]

        picturesField dispatch model.SensorPictures

        Field.div [] [
            Control.div [] [ createSaveButton dispatch model ]
        ]
    ]

let dataView dispatch (data: DataModel) =
    let addModal =
        match data.AddModal with
        | Some modal ->
            AddSensorPicture.view dispatch (fun _ -> CloseAddModal |> UserDefinedMapSensorProperties |> dispatch) modal
        | None -> div [] []

    let content =
        Html.div [
            prop.children [
                formView dispatch data
                confirmationDialog dispatch data.DeleteConfirmation
                addModal
            ]
        ]

    Container.container [
        Container.Modifiers [
            Modifier.Spacing(Spacing.MarginTop, Spacing.Is4)
        ]
    ] [ content ]

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model