module Client.Views.PageSkeleton

open Client
open Fable.FontAwesome
open Fable.React.Props
open Fulma

open Fable.React
open Client.Msg
open Shared
open Shared.Dto.User

let mySensFooter =
    Columns.columns [
        Columns.IsCentered
        Columns.Modifiers [ Modifier.BackgroundColor Color.IsLight ]
        Columns.IsGapless
    ] [
        Column.column [ Column.Width(Screen.All, Column.Is6) ] [
            Level.level [
                Level.Level.Modifiers [
                    Modifier.Spacing(Spacing.PaddingTopAndBottom, Spacing.Is1)
                ]
            ] [
                Level.item [ Level.Item.HasTextCentered ] [
                    a [
                        Href "https://hitzekarte-klar.at/impressum/"
                        Target "_blank"
                    ] [ str "Impressum" ]
                ]
            ]
        ]
    ]

let adminMenu (user: UserDto) dispatch =
    match user with
    | Admin _ ->
        Some
        <| Navbar.Item.div [
            Navbar.Item.HasDropdown
            Navbar.Item.IsHoverable
        ] [
            Navbar.Link.a [] [
                Icon.icon [] [ Fa.i [ Fa.Solid.Tools ] [] ]
                span [] [ str "Administration" ]
            ]
            Navbar.Dropdown.div [] [
                Navbar.Item.a [
                    Navbar.Item.Props [
                        OnClick(fun event ->
                            event.preventDefault ()
                            dispatch (GoToRoute Routing.TtnSensorList |> Global)
                        )
                    ]
                ] [ str "Physikalische Sensoren" ]
                Navbar.Item.a [
                    Navbar.Item.Props [
                        OnClick(fun event ->
                            event.preventDefault ()
                            dispatch (GoToRoute Routing.MySensSensorList |> Global)
                        )
                    ]
                ] [ str "Karten Sensoren" ]
                Navbar.Item.a [
                    Navbar.Item.Props [
                        OnClick(fun event ->
                            event.preventDefault ()
                            dispatch (GoToRoute Routing.GatewayList |> Global)
                        )
                    ]
                ] [ str "Gateways" ]
                Navbar.Item.a [
                    Navbar.Item.Props [
                        OnClick(fun event ->
                            event.preventDefault ()
                            dispatch (GoToRoute Routing.UserList |> Global)
                        )
                    ]
                ] [ str "Benutzer" ]
            ]
        ]
    | User _ -> None

let navbarStart (user: UserDto) dispatch =
    let items = [
        Navbar.Item.a [
            Navbar.Item.Props [
                OnClick(fun event ->
                    event.preventDefault ()
                    dispatch (Routing.SensorMap None |> GoToRoute |> Global)
                )
            ]
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.Globe ] [] ]
            span [] [ str "Sensorenkarte" ]
        ]
        Navbar.Item.a [
            Navbar.Item.Props [
                OnClick(fun event ->
                    event.preventDefault ()
                    dispatch (GoToRoute Routing.MyMapSensors |> Global)
                )
            ]
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.List ] [] ]
            span [] [ str "Meine Sensoren" ]
        ]
        Navbar.Item.a [
            Navbar.Item.Props [
                OnClick(fun event ->
                    event.preventDefault ()
                    dispatch (GoToRoute Routing.MyProfile |> Global)
                )
            ]
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.UserCircle ] [] ]
            span [] [ str "Mein Profil" ]
        ]
    ]

    adminMenu user dispatch |> Lists.addToListIfSome items

let navbar (user: UserDto) isBurgerOpen dispatch =
    Navbar.navbar [
        Navbar.Color Color.IsLight
        Navbar.Props [ Style [ Height "3.25rem" ] ]
    ] [
        Navbar.Brand.div [] [
            Navbar.Item.a [
                Navbar.Item.Props [
                    OnClick(fun _ -> GoToRoute Routing.Home |> Global |> dispatch)
                ]
            ] [ img [ Src "/images/icon/menu-icon.png" ] ]
            Navbar.burger [
                Navbar.Burger.OnClick(fun _ -> dispatch (Global ToggleBurger))
                Navbar.Burger.IsActive isBurgerOpen
            ] [ span [] []; span [] []; span [] [] ]
        ]
        Navbar.menu [ Navbar.Menu.IsActive isBurgerOpen ] [
            Navbar.Start.div [] (navbarStart user dispatch)
            Navbar.End.div [] [
                Navbar.Item.div [] [
                    Button.button [
                        Button.Color IsGrey
                        Button.OnClick(fun _ -> LogoutPressed |> Global |> dispatch)
                    ] [
                        Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.SignOutAlt ] [] ]
                        span [] [ str "Ausloggen" ]
                    ]
                ]
            ]
        ]
    ]

let skeleton (user: UserDto) isBurgerOpen dispatch content =
    div [] [ navbar user isBurgerOpen dispatch; content ]