module Client.Page.PendingSession

open Fable.React
open Client.Routing

type ErrorData = { StatusCode: int; Message: string }

type LoadingData = Route

type Model =
    | Loading of Route
    | Error of ErrorData

let init route = Loading route

let errorOccured code message =
    Error { StatusCode = code; Message = message }

let view (model: Model) =
    match model with
    | Loading _ -> h1 [] [ str "Waiting for session" ]
    | Error error -> h1 [] [ str "Sadly an error occured" ]