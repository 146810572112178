module Client.Page.TtnSensorList

open Client.InfrastructureTypes
open Client
open Client.Api
open Client.Msg

open Client.Views
open Elmish
open Fulma
open Shared.Dto.Dto
open Fable.React
open Shared.Infrastructure
open Thoth.Elmish

type DataModel = {
    Sensors: TtnSensorWithLastDate list
    TTNRefreshRunning: bool
    TTIRefreshRunning: bool
    Session: UserSession
    Modal: Forms.TtnSensor.Model option
}

type Model = Loadable<DataModel, UserSession>

let init session =
    (Loadable.Loading session,
     Cmd.OfAsync.perform api.getAllTtnSensors () (TtnSensorListMsg.ListReceived >> TtnSensorList))

let updateRequestRunning (model: DataModel) (requestRunning: bool) =
    let modalModel = Option.get model.Modal

    {
        model with
            Modal =
                Some {
                    modalModel with
                        RequestRunning = requestRunning
                }
    }

let update (msg: TtnSensorListMsg) (model: Model) =
    match msg, model with
    | TtnSensorListMsg.RefreshTTISensors, Loadable.Data data ->
        let request = {
            SessionKey = data.Session.SessionKey
            Data = ()
        }

        let cmd =
            Cmd.OfAsync.perform api.refreshTtiEndDevices request (TtnSensorListMsg.ListRefreshed >> TtnSensorList)

        Loadable.Data { data with TTIRefreshRunning = true }, cmd
    | TtnSensorListMsg.ListRefreshed result, Loadable.Data dataModel ->
        if result then
            let toastCmd =
                Toast.create "Sensoren wurden erfolgreich aus TTN heruntergeladen"
                |> Toast.success

            init dataModel.Session |> Cmds.batch toastCmd
        else
            let toastCmd =
                Toast.create "Fehler beim Aktualisieren der Sensoren aus TTN" |> Toast.error

            Loadable.Data {
                dataModel with
                    TTNRefreshRunning = false
                    TTIRefreshRunning = false
            },
            toastCmd

    | TtnSensorListMsg.ListReceived sensors, Loadable.Loading session ->
        Loadable.Data {
            Sensors = sensors
            Session = session
            Modal = None
            TTNRefreshRunning = false
            TTIRefreshRunning = false
        },
        Cmd.none
    | TtnSensorListMsg.CloseModal, Loadable.Data data -> Loadable.Data { data with Modal = None }, Cmd.none
    | TtnSensorListMsg.OpenNewSensorModal, Loadable.Data data ->
        Loadable.Data {
            data with
                Modal = Some Forms.TtnSensor.init
        },
        Cmd.none
    | TtnSensorListMsg.Form formMsg, Loadable.Data data ->
        match (formMsg, data.Modal) with
        | TtnSensorFormMsg.SelectedSensorTypeChanged selectedType, Some modal ->
            let modalModel = {
                modal with
                    SelectedType = selectedType
            }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | TtnSensorFormMsg.NameChanged name, Some modal ->
            let modalModel = { modal with Name = name }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | TtnSensorFormMsg.AppEuiChanged appEui, Some modal ->
            let modalModel = { modal with AppEui = appEui }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | TtnSensorFormMsg.DeviceEuiChanged deviceEui, Some modal ->
            let modalModel = { modal with DeviceEui = deviceEui }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | TtnSensorFormMsg.AppKeyChanged appKey, Some modal ->
            let modalModel = { modal with AppKey = appKey }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | TtnSensorFormMsg.DescriptionChanged description, Some modal ->
            let modalModel = { modal with Description = description }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | _ -> Loadable.Data data, Cmd.none

    | TtnSensorListMsg.CreateSensor sensor, Loadable.Data data ->
        let request = {
            SessionKey = data.Session.SessionKey
            Data = sensor
        }

        Loadable.Data(updateRequestRunning data true),
        Cmd.map TtnSensorList (Cmd.OfAsync.perform api.createPhysicalSensor request TtnSensorListMsg.SensorUpdated)
    | TtnSensorListMsg.SensorUpdated response, Loadable.Data data ->
        match response with
        | Ok _ ->
            let toastCmd =
                Toast.create "Der Sensor wurde erfolgreich gespeichert" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        | _ ->
            let toastCmd =
                Toast.create "Ein Fehler ist aufgetreten beim Speichern des Sensors"
                |> Toast.error

            Loadable.Data(updateRequestRunning data false), toastCmd
    | TtnSensorListMsg.DeleteSensor sensor, Loadable.Data data ->
        let request = {
            SessionKey = data.Session.SessionKey
            Data = sensor
        }

        Loadable.Data { data with Modal = None },
        Cmd.map TtnSensorList (Cmd.OfAsync.perform api.removePhysicalSensor request TtnSensorListMsg.SensorDeleted)
    | TtnSensorListMsg.SensorDeleted response, Loadable.Data data ->
        match response with
        | Ok _ ->
            let toastCmd = Toast.create "Der Sensor wurde erfolgreich gelöscht" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        | _ ->
            let toastCmd =
                Toast.create "Ein Fehler ist aufgetreten beim Lößchen des Sensors"
                |> Toast.error

            Loadable.Data(updateRequestRunning data false), toastCmd
    | _, _ -> model, Cmd.none

let sensorToRow dispatch (index: int) (sensor: TtnSensorWithLastDate) =
    let lastDataTime =
        sensor.LastDate
        |> Option.map (TimeSpan.createElapsedTime >> TimeSpan.toHumanReadable)
        |> Option.map (sprintf "vor %s")
        |> Option.defaultValue ""

    tr [] [
        td [] [ Table.rowIndexString index ]
        td [] [ str (getSensorName sensor.Sensor) ]
        td [] [ str (getSensorAppEui sensor.Sensor) ]
        td [] [ str (getSensorDeviceEui sensor.Sensor) ]
        td [] [ str (getSensorAppKey sensor.Sensor) ]
        td [] [
            (getBaseData sensor.Sensor).Description |> Option.defaultValue "" |> str
        ]
        td [] [
            sensorIsConfigured sensor.Sensor |> Boolean.toUserString |> str
        ]
        td [] [
            sensorTypeAsString sensor.Sensor |> Option.defaultValue "" |> str
        ]
        td [] [ str lastDataTime ]
        td [] [
            Button.button [
                Button.OnClick(fun _ ->
                    dispatch (TtnSensorListMsg.DeleteSensor(getSensorName sensor.Sensor) |> TtnSensorList)
                )
            ] [ str "Löschen" ]
        ]
    ]

let sensorListToTable dispatch (sensors: TtnSensorWithLastDate list) =
    Table.table [
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
    ] [
        thead [] [
            tr [] [
                td [] [ str "#" ]
                td [] [ str "Name" ]
                td [] [ str "App EUI" ]
                td [] [ str "Device EUI" ]
                td [] [ str "App key" ]
                td [] [ str "Description" ]
                td [] [ str "Sensor ist konfiguriert" ]
                td [] [ str "Sensortyp" ]
                td [] [ str "Letzte Daten" ]
                td [] [ str "" ]
            ]
        ]
        tbody [] (List.mapi (sensorToRow dispatch) sensors)
    ]

let createNewDevice dispatch =
    Button.button [
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (TtnSensorList TtnSensorListMsg.OpenNewSensorModal))
    ] [ str "Neuer Sensor anlegen" ]

let refreshTTIDevices isRunning dispatch =
    Button.button [
        Button.Color IsLink
        Button.IsLoading isRunning
        Button.OnClick(fun _ -> dispatch (TtnSensorList TtnSensorListMsg.RefreshTTISensors))
    ] [ str "Sensor aus TTI herunterladen" ]

let tableHeader refreshTtnRunning refreshTtiRunning dispatch =
    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [
                Field.div [] [ Control.div [] [ createNewDevice dispatch ] ]
            ]
            Level.item [] [
                Field.div [] [
                    Control.div [] [ refreshTTIDevices refreshTtiRunning dispatch ]
                ]
            ]
        ]
    ]

let private dataView dispatch (data: DataModel) =
    let modal =
        data.Modal
        |> Option.map (Forms.TtnSensor.view (TtnSensorList >> dispatch))
        |> Option.defaultValue (div [] [])

    [
        Heading.h1 [] [ str "Physikalische Sensoren Liste" ]
        tableHeader data.TTNRefreshRunning data.TTIRefreshRunning dispatch
        sensorListToTable dispatch data.Sensors
        modal
    ]
    |> Container.container [ Container.IsFluid ]
    |> (fun content -> div [] [ content; PageSkeleton.mySensFooter ])

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model