module Shared.Dto.MapSensorData

open System
open Shared
open Shared.Dto.Dto

type AirSensorData = {
    Date: DateTime
    AirTemperature: float
    AirHumidity: float
    HumidTemperature: float
    DewPoint: float
    AbsoluteHumidity: float
    ApparentTemperature: float option
    MinAirTemperature: DatedValue<float>
    MaxAirTemperature: DatedValue<float>
    Rssi: int option
    BatteryLevel: BatteryLevel
    LeafletWetness: LeafletWetness
}

type GroundSensorData = {
    Date: DateTime
    GroundTemperature: float
    GroundHumidity: float
    SoilConductivity: float
    MinGroundHumidity: DatedValue<float>
    MaxGroundHumidity: DatedValue<float>
    Rssi: int option
    BatteryLevel: BatteryLevel
}

type RainFallSensorData = {
    Date: DateTime
    CurrentRainFall: float
    RainFallLastHour: float
    RainFallToday: float
    Rssi: int option
    BatteryLevel: BatteryLevel
    Count: int
}

type LeafletMoistureSensorData = {
    Date: DateTime
    Rssi: int option
    BatteryLevel: BatteryLevel

    LeafletMoisture: float
    LeafletTemperature: float
}

type SoilPhSensorData = {
    Date: DateTime
    Rssi: int option
    BatteryLevel: BatteryLevel

    SoilPh: float
    SoilTemperature: float
}

type AverageWindSensorData = {
    Date: DateTime
    Rssi: int option
    BatteryLevel: BatteryLevel

    AverageWindSpeed: float
}

type MapSensorDataList =
    | NoDataAvailable
    | AirData of AirSensorData list
    | GroundData of GroundSensorData list
    | RainFallData of RainFallSensorData list
    | LeafletMoistureData of LeafletMoistureSensorData list
    | SoilPhData of SoilPhSensorData list
    | AverageWindSpeed of AverageWindSensorData list

type MapSensorData =
    | NoDataAvailable
    | NoPublicData
    | AirData of AirSensorData
    | GroundData of GroundSensorData
    | RainFallData of RainFallSensorData
    | LeafletMoistureData of LeafletMoistureSensorData
    | SoilPhData of SoilPhSensorData
    | AverageWindSpeedData of AverageWindSensorData

let onlyAirData (data: MapSensorData) : AirSensorData option =
    match data with
    | AirData airSensorData -> Some airSensorData
    | _ -> None

type MapSensor = {
    Id: int
    Name: string
    Latitude: double
    Longitude: double
    Data: MapSensorData
    DetailsAllowed: bool
}

let getRssiOfSensorData (data: MapSensorData) =
    match data with
    | AirData airData -> airData.Rssi
    | GroundData groundData -> groundData.Rssi
    | RainFallData data -> data.Rssi
    | LeafletMoistureData data -> data.Rssi
    | SoilPhData data -> data.Rssi
    | AverageWindSpeedData data -> data.Rssi
    | _ -> None

let getBatteryLevel (data: MapSensorData) =
    match data with
    | AirData airData -> Some airData.BatteryLevel
    | GroundData groundData -> Some groundData.BatteryLevel
    | RainFallData data -> Some data.BatteryLevel
    | LeafletMoistureData data -> Some data.BatteryLevel
    | SoilPhData data -> Some data.BatteryLevel
    | AverageWindSpeedData data -> Some data.BatteryLevel
    | _ -> None

let getSensorDataDate (data: MapSensorData) =
    match data with
    | NoDataAvailable
    | NoPublicData -> None
    | AirData data -> Some data.Date
    | GroundData data -> Some data.Date
    | RainFallData data -> Some data.Date
    | LeafletMoistureData data -> Some data.Date
    | SoilPhData data -> Some data.Date
    | AverageWindSpeedData data -> Some data.Date