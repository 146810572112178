module Index

open Client
open Client.InfrastructureTypes
open Client.Model
open Client.Msg
open Client.Session
open Client.Page
open Client.Views
open Fable.Core.JsInterop
open Elmish
open Fable.FontAwesome
open Fable.React
open Fulma
open Leaflet
open Client.Navbar
open Shared.Dto.Dto
open Shared.Dto.User
open Shared

importAll "flatpickr/dist/themes/material_green.css"
importAll "../../node_modules/leaflet/dist/leaflet.css"
icon?Default?imagePath <- "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/"

importAll "./Styles/main.css"
importAll "./Styles/map.css"
importAll "./Styles/graph.css"

let private mapModel (modelMap: 'model -> PageModel) (subModel: 'model, cmd: Cmd<Msg>) = modelMap subModel, cmd

let onlyLoggedIn route =
    onlyForLoggedInSession (Login.init route >> mapModel PageModel.Login)

let urlUpdate (maybeRoute: Routing.Route option) (model: Model) : Model * Cmd<Msg> =
    let route = Option.defaultValue Routing.NotFound maybeRoute

    let map (modelMap: 'model -> PageModel) (cmdMap: 'msg -> Msg) (subModel: 'model, subCmd: Cmd<'msg>) =
        modelMap subModel, Cmd.map cmdMap subCmd

    let onlyWithSession =
        initWithSession (PendingSession.init route |> PageModel.PendingSession, Cmd.none) model.Session

    let url = Routing.routeToUrl route

    let model, pageCmd =
        match route with
        | Routing.Home ->
            onlyWithSession (onlyLoggedIn route (fun _ -> PageModel.Home, Cmd.none))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.TtnSensorList ->
            onlyWithSession (onlyLoggedIn route (TtnSensorList.init >> mapModel PageModel.TtnSensorList))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.MySensSensorList ->
            onlyWithSession (onlyLoggedIn route (MySensSensorList.init >> mapModel PageModel.MySensSensorList))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.GatewayList ->
            onlyWithSession (onlyLoggedIn route (GatewayList.init >> mapModel PageModel.GatewayList))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.PublicMap ->
            onlyWithSession (fun _ -> PublicMap.init |> map PublicPage PublicMap)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.SiteNotice ->
            onlyWithSession (fun _ -> SiteNotice, Cmd.none)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.SensorMap maybeCenter ->
            onlyWithSession (onlyLoggedIn route (SensorMap.init maybeCenter >> map PageModel.SensorPage Msg.Map))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.Login ->
            onlyWithSession (Login.init Routing.Home >> mapModel Model.Login)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.NotFound ->
            onlyWithSession (fun _ -> NotFound, Cmd.none)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.PasswordLost ->
            onlyWithSession (fun _ -> PageModel.PasswordLost PasswordLost.init, Cmd.none)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.ResetPassword token ->
            onlyWithSession (ResetPassword.init token >> mapModel Model.ResetPassword)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.MyProfile ->
            onlyWithSession (onlyLoggedIn route (MyProfile.init >> mapModel PageModel.MyProfile))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.UserList ->
            onlyWithSession (onlyLoggedIn route (UserList.init >> mapModel PageModel.UserList))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.CalculationConfiguration ->
            onlyWithSession (
                onlyLoggedIn route (CalculationConfiguration.init >> mapModel PageModel.CalculationConfiguration)
            )
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.MySensSensor id ->
            onlyWithSession (onlyLoggedIn route (MySensSensor.init id >> mapModel PageModel.MySensSensor))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.MySensorsData ->
            onlyWithSession (onlyLoggedIn route (Page.MySensorsData.init >> mapModel PageModel.MySensorsData))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.MyMapSensors ->
            onlyWithSession (onlyLoggedIn route (Page.MyMapSensors.init >> mapModel PageModel.MyMapSensors))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.UserDefinedMapSensorProperties id ->
            onlyWithSession (
                onlyLoggedIn
                    route
                    (Page.UserDefinedMapSensorProperties.init id
                     >> mapModel PageModel.UserDefinedMapSensorSettings)
            )
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.PublicHistory id ->
            onlyWithSession (fun _ -> PublicHistory.init id |> mapModel PageModel.PublicHistory)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Routing.SensorPictures id ->
            onlyWithSession (fun _ -> SensorPictures.init id |> mapModel PageModel.SensorPictures)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)

    model, Cmd.batch [ pageCmd ]

let heroView (user: IdValue<UserDto> option) (dispatch: Msg -> Unit) (content: ReactElement) =
    div [] [
        createMainNavbar user (Global >> dispatch)
        Hero.hero [ Hero.IsFullheightWithNavbar ] [
            content
            Hero.foot [] [ PageSkeleton.mySensFooter ]
        ]
    ]

let mapCmd (model: Model) (modelMap: 'model -> PageModel) (cmdMap: 'msg -> Msg) (subModel: 'model, subCmd: Cmd<'msg>) =
    let newModel = { model with Page = modelMap subModel }

    newModel, Cmd.map cmdMap subCmd

let toggleBurger (model: Model) = {
    model with
        IsBurgerOpen = not model.IsBurgerOpen
}

let closeBurger (model: Model) = { model with IsBurgerOpen = false }

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    let mapModel (modelMap: 'model -> PageModel) (subModel: 'model, cmd: Cmd<Msg>) = modelMap subModel, cmd

    match (msg, model.Page) with
    | Global LogoutPressed, _ ->
        match model.Session with
        | Pending -> model, Cmd.none
        | Session userSession -> model, runLogout userSession |> Cmd.map Global
    | Global LoggedOut, _ ->
        let session = Anonymous

        Page.Login.init Routing.Route.Home session |> mapCmd model Model.Login id
    | Global(SessionReceived session), PendingSession(PendingSession.Loading route) ->
        urlUpdate (Some route) { model with Session = Session session }
    | Global(GoToRoute route), _ ->
        model
        |> closeBurger
        |> (fun model ->
            let navCmd = route |> Routing.routeToUrl |> Navigation.Navigation.newUrl

            model, navCmd
        )
    | Global ToggleBurger, _ -> toggleBurger model, Cmd.none
    | PublicMap pageMsg, PublicPage pageModel ->
        PublicMap.update pageMsg pageModel
        |> mapCmd model Model.PublicPage Msg.PublicMap
    | Map pageMsg, SensorPage pageModel -> SensorMap.update pageMsg pageModel |> mapCmd model Model.SensorPage Map
    | Login loginMsg, PageModel.Login loginModel ->
        let clientSession =
            match loginMsg with
            | LoginResult(Successful userSession) ->
                storeClientSessionId (sessionKeyToString userSession.SessionKey)

                Session(UserSession userSession)
            | _ -> model.Session

        Login.update loginMsg loginModel
        |> mapModel Model.Login
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = pageModel
                    Session = clientSession
            },
            cmd
    | TtnSensorList ttnMsg, PageModel.TtnSensorList listModel ->
        TtnSensorList.update ttnMsg listModel
        |> mapModel Model.TtnSensorList
        |> fun (pageModel, cmd) -> { model with Page = pageModel }, cmd
    | MySensSensorList mySensMsg, PageModel.MySensSensorList listModel ->
        MySensSensorList.update mySensMsg listModel
        |> mapModel Model.MySensSensorList
        |> fun (pageModel, cmd) -> { model with Page = pageModel }, cmd
    | GatewayList gatewayMsg, PageModel.GatewayList listModel ->
        GatewayList.update gatewayMsg listModel
        |> mapModel Model.GatewayList
        |> fun (pageModel, cmd) -> { model with Page = pageModel }, cmd
    | MyProfile myProfileMsg, PageModel.MyProfile profileModel ->
        MyProfile.update myProfileMsg profileModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.MyProfile pageModel
            },
            cmd
    | UserList userListMsg, PageModel.UserList userListModel ->
        UserList.update userListMsg userListModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.UserList pageModel
            },
            cmd
    | PasswordLost lostMsg, PageModel.PasswordLost lostModel ->
        PasswordLost.update lostMsg lostModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.PasswordLost pageModel
            },
            cmd
    | ResetPassword resetMsg, PageModel.ResetPassword resetModel ->
        ResetPassword.update resetMsg resetModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.ResetPassword pageModel
            },
            cmd
    | CalculationConfiguration calcConfigurationMsg, PageModel.CalculationConfiguration calcConfigurationModel ->
        CalculationConfiguration.update calcConfigurationMsg calcConfigurationModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.CalculationConfiguration pageModel
            },
            cmd
    | MySensSensor sensorMsg, PageModel.MySensSensor sensorModel ->
        MySensSensor.update sensorMsg sensorModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.MySensSensor pageModel
            },
            cmd
    | MySensorsData dataMsg, PageModel.MySensorsData dataModel ->
        MySensorsData.update dataMsg dataModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.MySensorsData pageModel
            },
            cmd
    | UserDefinedMapSensorProperties dataMsg, PageModel.UserDefinedMapSensorSettings dataModel ->
        UserDefinedMapSensorProperties.update dataMsg dataModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.UserDefinedMapSensorSettings pageModel
            },
            cmd
    | PublicHistory dataMsg, PageModel.PublicHistory dataModel ->
        PublicHistory.update dataMsg dataModel
        |> fun pageModel ->
            {
                model with
                    Page = PageModel.PublicHistory pageModel
            },
            Cmd.none
    | Msg.SensorPictures dataMsg, PageModel.SensorPictures dataModel ->
        Page.SensorPictures.update dataMsg dataModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.SensorPictures pageModel
            },
            cmd
    | Msg.MyMapSensors dataMsg, PageModel.MyMapSensors dataModel ->
        Page.MyMapSensors.update dataMsg dataModel
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = PageModel.MyMapSensors pageModel
            },
            cmd
    | _, _ -> model, Cmd.none

let view (model: Model) (dispatch: Msg -> unit) =
    match model.Page with
    | PublicPage pageModel -> PublicMap.view pageModel dispatch
    | SensorPage pageModel ->
        SensorMap.view pageModel dispatch
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.Login pageModel ->
        Page.Login.view pageModel dispatch
        |> heroView (getUserFromClientSession model.Session) dispatch
    | NotFound -> Page.NotFound.view |> heroView (getUserFromClientSession model.Session) dispatch
    | PendingSession _ -> Loadable.loadingView (Some "Warten auf Daten vom Server") Fa.Fa8x
    | Model.TtnSensorList listModel ->
        Page.TtnSensorList.view listModel dispatch
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.MySensSensorList listModel ->
        Page.MySensSensorList.view listModel dispatch
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.Home ->
        Page.Home.view (getUserFromClientSession model.Session |> Option.get) dispatch
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.MyProfile profileModel ->
        MyProfile.view profileModel (MyProfile >> dispatch)
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.UserList userListModel ->
        UserList.view userListModel dispatch
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.PasswordLost lostModel -> Page.PasswordLost.view lostModel dispatch |> heroView None dispatch
    | Model.ResetPassword resetModel -> Page.ResetPassword.view resetModel dispatch |> heroView None dispatch
    | Model.GatewayList listModel ->
        Page.GatewayList.view listModel dispatch
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.CalculationConfiguration calcConfigurationModel ->
        Page.CalculationConfiguration.view (CalculationConfiguration >> dispatch) calcConfigurationModel
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.MySensSensor sensorModel ->
        Page.MySensSensor.view sensorModel dispatch
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.MySensorsData dataModel ->
        Page.MySensorsData.view dataModel dispatch
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.UserDefinedMapSensorSettings dataModel ->
        Page.UserDefinedMapSensorProperties.view dataModel dispatch
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.PublicHistory dataModel -> Page.PublicHistory.view dataModel
    | Model.MyMapSensors dataModel ->
        Page.MyMapSensors.view dispatch dataModel
        |> PageSkeleton.skeleton
            (getUserFromClientSession model.Session
             |> Option.get
             |> (fun idValue -> idValue.Value))
            model.IsBurgerOpen
            dispatch
    | Model.SensorPictures dataModel -> Page.SensorPictures.view dispatch dataModel
    | Model.SiteNotice -> Page.SiteNotice.view