namespace Shared.Dto

open System
open Shared.Dto.Dto
open Shared.Dto.MapSensorData

[<RequireQualifiedAccess>]
module SensorDetailPage =
    type GraphVisualisation<'a> =
        | NotAllowed
        | Allowed of 'a

    let mapGraphVisualisation f data =
        match data with
        | NotAllowed -> NotAllowed
        | Allowed data -> Allowed(f data)

    type Data = {
        Id: int
        Name: string
        SensorType: SensorModel
        CurrentData: MapSensorData
        DownloadTimespan: TimeSpan
        Visualisations: GraphVisualisation<TimeSpan>
    }

    type Response =
        | NotFound
        | Unauthenticated
        | Unauthorized
        | NotActive
        | Data of Data