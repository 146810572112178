module Shared.Dto.Dto

open System
open Shared.Dto.User
open Shared

type SessionKey = SessionKey of string

let sessionKeyToString (SessionKey key) = key

type UserSession = {
    User: IdValue<UserDto>
    SessionKey: SessionKey
}

type Session =
    | Anonymous
    | UserSession of UserSession

type LoginRequestData = { Mail: string; Password: String }

type LoginResponse =
    | Successful of UserSession
    | Unsuccessful

type AuthenticatedRequest<'a> = { SessionKey: SessionKey; Data: 'a }

type AuthenticationError =
    | Unauthorized
    | Unauthenticated

type AuthenticatedResponseError =
    | AuthErr of AuthenticationError
    | CustomErr of string

type AuthenticatedResponse<'a> = Result<'a, AuthenticatedResponseError>

type SensorModel =
    | LHT65
    | LSN50v2_S31
    | LSE01
    | LSN50v2_Rain
    | LSN50v2_Wind
    | LLMS01
    | LSPH01

type SensorType =
    | Air
    | Soil
    | RainFall
    | LeafletMoisture
    | PH
    | WindAverage

let sensorTypeToString sensorType =
    match sensorType with
    | LHT65 -> "Luftsensor (LHT65)"
    | LSN50v2_S31 -> "Luftsensor (LSN50v2_S31)"
    | LSE01 -> "Bodensensor (LSE01)"
    | LSN50v2_Rain -> "Regensensor (LSN50v2_Rain)"
    | LSN50v2_Wind -> "Wind Durchschnitt (LSN50v2_Wind)"
    | LLMS01 -> "Blattnässe (LLMS01)"
    | LSPH01 -> "PH-Wert (LSPH01)"

let sensorTypeFromString typeString =
    match typeString with
    | "Luftsensor (LHT65)" -> Some LHT65
    | "Luftsensor (LSN50v2_S31)" -> Some LSN50v2_S31
    | "Bodensensor (LSE01)" -> Some LSE01
    | "Regensensor (LSN50v2_Rain)" -> Some LSN50v2_Rain
    | "Blattnässe (LLMS01)" -> Some LLMS01
    | "PH-Wert (LSPH01)" -> Some LSPH01
    | "Wind Durchschnitt (LSN50v2_Wind)" -> Some LSN50v2_Wind
    | _ -> None

type TtnSensorBaseData = {
    TtnId: string
    Name: string
    DeviceEui: string
    AppEui: string
    AppKey: string
    Description: string option
}

let createTtnSensorBaseData ttnId name deviceEui appEui appKey description = {
    TtnId = ttnId
    Name = name
    DeviceEui = deviceEui
    AppEui = appEui
    AppKey = appKey
    Description = description
}

type ConfiguredTtnSensor = {
    BaseData: TtnSensorBaseData
    Configuration: SensorModel
}

type TtnSensor =
    | Configured of ConfiguredTtnSensor
    | UnConfigured of TtnSensorBaseData

type TtnSensorWithLastDate = {
    Sensor: TtnSensor
    LastDate: DateTime option
}

let getBaseData sensor =
    match sensor with
    | Configured configured -> configured.BaseData
    | UnConfigured baseData -> baseData

let getDeviceEui sensor = (getBaseData sensor).DeviceEui

let getSensorName sensor = (getBaseData sensor).Name

let getSensorDeviceEui sensor = (getBaseData sensor).DeviceEui

let getSensorAppEui sensor = (getBaseData sensor).AppEui

let getSensorAppKey sensor = (getBaseData sensor).AppKey

let getSensorType sensor =
    match sensor with
    | Configured configured -> Some configured.Configuration
    | UnConfigured _ -> None

let sensorIsConfigured sensor =
    match sensor with
    | Configured _ -> true
    | UnConfigured _ -> false

let sensorTypeAsString sensor =
    match sensor with
    | Configured configured -> Some(sensorTypeToString configured.Configuration)
    | UnConfigured _ -> None

type MySensSensor = {
    Id: int
    Name: string
    Longitude: double
    Latitude: double
    IsPublic: bool
    IsDisabled: bool
    SensorType: SensorType
    TtnSensorId: string option
    UserId: int option
}

type NewMySensSensor = {
    Name: string
    Longitude: double
    Latitude: double
    IsPublic: bool
    IsDisabled: bool
    SensorType: SensorType
    TtnSensorId: string option
    UserId: int option
}

type PasswordResetRequest = {
    RequestedAt: DateTime
    UserId: int
    Token: string
}

type LeafletWetness =
    | Wet of DateTime
    | Dry

let leafletWetnessToInt wetness =
    match wetness with
    | Wet _ -> 1
    | Dry -> 0

type BatteryLevel =
    | Full
    | TwoQuarters
    | OneQuarter
    | Low
    | Danger

type LeafletWetnessConfiguration = {
    WetHumidityLimit: float
    DryHumidityLimit: float

    DewPointLimit: float

    RelativeHumidityDeltaLimit: float
    AbsoluteHumidityFactorLimit: float
}

type DateRange = { Start: DateTime; End: DateTime }

type SensorDataRequest = { Range: DateRange; SensorId: int }