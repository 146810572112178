module Client.Forms.AddSensorPicture

open Browser.Types
open Client.Msg
open Elmish
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fable.Core.JsInterop
open Fable.Remoting.Client
open Feliz.Bulma.ElementBuilders
open Fulma
open Shared.Dto.SensorPictureDto

type FileData = { Extension: string; Content: byte[] }

type Model = {
    File: FileData option
    Name: string
    SensorId: int
    AddRequestRunning: bool
}

let init sensorId = {
    File = None
    Name = ""
    SensorId = sensorId
    AddRequestRunning = false
}

type FormResult =
    | Noop
    | Close
    | LoadFile of File
    | SavePicture of NewSensorPictureDto

let update (msg: SensorPictureFormMsg) (model: Model) =
    match msg with
    | SensorPictureFormMsg.NameChanged name -> { model with Name = name }, FormResult.Noop
    | SensorPictureFormMsg.FileSelected file -> model, FormResult.LoadFile file
    | SensorPictureFormMsg.FileLoaded(bytes, extension) ->
        {
            model with
                File =
                    Some {
                        Extension = extension
                        Content = bytes
                    }
        },
        FormResult.Noop
    | SensorPictureFormMsg.SavePicture dto -> { model with AddRequestRunning = true }, FormResult.SavePicture dto

let handleFileEvent dispatch (fileEvent: Browser.Types.Event) =
    let files: Browser.Types.FileList = fileEvent.target?files

    if files.length > 0 then
        let file = files.[0]

        FileSelected file
        |> SensorPictureForm
        |> UserDefinedMapSensorProperties
        |> dispatch
    else
        ()

let private createNewPictureDto (model: Model) =
    Option.map
        (fun file -> {
            Name = model.Name
            Content = file.Content
            SensorId = model.SensorId
            FileExtension = file.Extension
        })
        model.File

let private form dispatch (model: Model) =
    let maybeImagePreview =
        match model.File with
        | Some file ->
            Some(
                img [
                    Src(file.Content.AsDataUrl())
                    Style [ Width "100%"; Height "auto" ]
                    Class "ml-2"
                ]
            )
        | None -> None

    form [] [
        Field.div [] [
            Control.div [] [
                Label.label [] [ str "Name" ]
                Input.input [
                    Input.Placeholder "Beschreibung des Bildes"
                    Input.OnChange(fun event ->
                        event.Value
                        |> NameChanged
                        |> SensorPictureForm
                        |> UserDefinedMapSensorProperties
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            File.file [ File.HasName ] [
                File.label [] [
                    File.input [
                        GenericOption.Props [
                            DOMAttr.OnChange(handleFileEvent dispatch)
                            Accept ".jpg, .png, .jpeg"
                        ]
                    ]
                    File.cta [] [
                        File.icon [] [ Icon.icon [] [ Fa.i [ Fa.Solid.Upload ] [] ] ]
                        str "Datei auswählen"
                    ]
                ]
            ]
        ]

        match maybeImagePreview with
        | Some preview ->
            Field.div [] [
                Control.div [] [ Label.label [] [ str "Bildvorschau" ]; preview ]
            ]
        | None -> ()
    ]

let private saveButton dispatch requestRunning maybeDto =
    Button.button [
        Button.Color Color.IsSuccess
        Button.Disabled(Option.isNone maybeDto)
        Button.IsLoading requestRunning
        match maybeDto with
        | Some dto ->
            Button.OnClick(fun event ->
                event.preventDefault ()

                SensorPictureFormMsg.SavePicture dto
                |> SensorPictureForm
                |> UserDefinedMapSensorProperties
                |> dispatch
            )
        | None -> ()
    ] [ str "Speichern" ]

let view dispatch closeModal (model: Model) =
    let headline = "Neues Foto hinzufügen"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [
            GenericOption.Props [ DOMAttr.OnClick closeModal ]
        ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
            Modal.Card.foot [] [
                saveButton dispatch model.AddRequestRunning (createNewPictureDto model)
            ]
        ]
    ]