module Client.Page.MySensSensor

open Client.Components.Graph.SoilPhSensor
open Client.Components.GraphCommon
open Client.InfrastructureTypes
open Fable.Remoting.Client
open Client
open Client.Components
open Fable.React
open Feliz.Bulma
open Microsoft.FSharp.Collections
open Shared
open System
open Client.Api
open Elmish
open Client.Msg
open Client.Components.Graph.AirSensor
open Client.Components.Graph.GroundSensor
open Client.Components.Graph.RainFallSensor
open Client.Components.Graph.LeafletMoistureSensor
open Client.Components.Graph.AverageWindSpeedSensor
open Fulma
open Fulma.Elmish
open Shared.Dto
open Shared.Dto.Dto
open Shared.Dto.MapSensorData

open Client.DateTime
open Feliz
open Feliz.Recharts
open Shared.Dto.SensorGraphData
open Thoth.Elmish

module R = Standard
module P = Fable.React.Props

type SensorGraphDataList =
    | AirList of AirSensorGraphData
    | GroundList of GroundSensorGraphData
    | RainFallList of RainFallSensorGraphData
    | LeafletMoistureList of SimpleGraphData<LeafletMoistureGraphNode>
    | SoilPhList of SimpleGraphData<SoilPhGraphNode>
    | AverageWindSpeedList of AverageWindSpeedGraphsData

type DatePickerModel = {
    State: DatePicker.Types.State
    CurrentDate: DateTime option
}

type DataModel = {
    Session: UserSession
    Name: string
    SensorId: int
    SensorType: SensorModel
    CurrentData: MapSensorData
    GraphRequestRunning: bool
    DownloadRequestRunning: bool
    DownloadRange: DateRangePicker.Data
    GraphDateRange: SensorDetailPage.GraphVisualisation<DateRangePicker.Data>
    HistoryGraphData: SensorGraphDataList option
}

type PageModel =
    | NotFound
    | NotActive
    | NotAllowed
    | NotAuthenticated
    | Data of DataModel

type Model = Loadable<PageModel, UserSession>

let init id (session: UserSession) =
    let requestData = {
        SessionKey = session.SessionKey
        Data = id
    }

    let cmd =
        Cmd.OfAsync.perform api.getSensorDetailPageData requestData (MySensSensorMsg.PageDataReceived >> MySensSensor)

    Loadable.Loading session, cmd

let pageDataToDataModel (session: UserSession) (data: SensorDetailPage.Data) : DataModel =
    let downloadRange = DateRangePicker.init data.DownloadTimespan

    let graphDateRange =
        SensorDetailPage.mapGraphVisualisation DateRangePicker.initWithTwoDays data.Visualisations

    {
        Session = session
        Name = data.Name
        SensorId = data.Id
        SensorType = data.SensorType
        CurrentData = data.CurrentData
        GraphRequestRunning = false
        DownloadRequestRunning = false
        DownloadRange = downloadRange
        GraphDateRange = graphDateRange
        HistoryGraphData = None
    }

let onDetailPageDataReceived session (response: SensorDetailPage.Response) : PageModel =
    match response with
    | SensorDetailPage.Response.NotFound -> PageModel.NotFound
    | SensorDetailPage.Response.Unauthenticated -> PageModel.NotAuthenticated
    | SensorDetailPage.Response.Unauthorized -> PageModel.NotAllowed
    | SensorDetailPage.Response.NotActive -> PageModel.NotActive
    | SensorDetailPage.Response.Data data -> PageModel.Data(pageDataToDataModel session data)

let createHistoricRequestData (sensor: int) (startDate: DateTime) (endDate: DateTime) = {
    Range = {
        Start = startDate
        End = endDate.AddDays(1.)
    }

    SensorId = sensor
}

let createRequestHistoricDataCmd data =
    Cmd.OfAsync.perform api.getHistoricMapSensorData data (MySensSensorMsg.HistoricDataReceived >> MySensSensor)

let toDatePickerModel (newState: DatePicker.Types.State) (date: DateTime option) = {
    State = newState
    CurrentDate = date
}

let toGraphDataList (sensorType: SensorModel) (data: GraphDataDto list) =
    match sensorType with
    | LSN50v2_S31
    | LHT65 -> data |> graphDataDtoToAirGraphData |> AirList
    | LSE01 -> data |> graphDataDtoToGroundGraphData |> GroundList
    | LSN50v2_Rain -> data |> graphDataDtoToRainFallGraphData |> RainFallList
    | LLMS01 -> data |> graphDataToLeafletMoistureData |> LeafletMoistureList
    | LSPH01 -> data |> graphDataToSoilPhGraphData |> SoilPhList
    | LSN50v2_Wind -> data |> graphDataToAverageWindSpeedGraphData |> AverageWindSpeedList

let update (msg: MySensSensorMsg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | PageDataReceived response, Loadable.Loading loadingModel ->
        let dataModel = onDetailPageDataReceived loadingModel response

        match dataModel with
        | Data data ->
            match data.GraphDateRange with
            | SensorDetailPage.Allowed _ ->
                let startDate = DateTime.Now.Date.AddDays -2.
                let endDate = DateTime.Now.Date

                let requestData = createHistoricRequestData data.SensorId startDate endDate

                Loadable.Data(Data { data with GraphRequestRunning = true }),
                Cmd.OfAsync.perform
                    api.getHistoricMapSensorData
                    requestData
                    (MySensSensorMsg.HistoricDataReceived >> MySensSensor)
            | SensorDetailPage.NotAllowed -> Loadable.Data dataModel, Cmd.none
        | _ -> Loadable.Data dataModel, Cmd.none
    | HistoricDataReceived historicData, Loadable.Data(Data data) ->
        let newGraphData = Some(toGraphDataList data.SensorType historicData)

        let newData = {
            data with
                HistoryGraphData = newGraphData
                GraphRequestRunning = false
        }

        Loadable.Data(Data newData), Cmd.none
    | DownloadDateRangeChanged dates, Loadable.Data(Data data) ->
        let newDownloadRange = DateRangePicker.updateData dates data.DownloadRange

        Loadable.Data(
            Data {
                data with
                    DownloadRange = newDownloadRange
            }
        ),
        Cmd.none
    | GraphDateRangeChanged dates, Loadable.Data(Data data) ->
        let newGraphDateRange =
            SensorDetailPage.mapGraphVisualisation (DateRangePicker.updateData dates) data.GraphDateRange

        Loadable.Data(
            Data {
                data with
                    GraphDateRange = newGraphDateRange
            }
        ),
        Cmd.none
    | RequestData requestData, Loadable.Data(Data data) ->
        Loadable.Data(Data { data with GraphRequestRunning = true }), createRequestHistoricDataCmd requestData
    | StartDownload requestData, Loadable.Data(Data data) ->
        let authenticatedRequest = {
            SessionKey = data.Session.SessionKey
            Data = requestData
        }

        Loadable.Data(
            Data {
                data with
                    DownloadRequestRunning = true
            }
        ),
        Cmd.OfAsync.perform
            api.downloadMapSensorData
            authenticatedRequest
            (fun response -> MySensSensorMsg.Download(requestData, response) |> MySensSensor)
    | MySensSensorMsg.Download(requestData, response: AuthenticatedResponse<byte[]>), Loadable.Data(Data data) ->
        let cmd =
            match response with
            | Result.Ok responseData ->
                let fileName =
                    sprintf
                        "%s_%s-%s.csv"
                        data.Name
                        (dateToFileNameString requestData.Range.Start)
                        (dateToFileNameString requestData.Range.End)

                responseData.SaveFileAs(fileName, "text/csv")

                Cmd.none
            | Result.Error _ -> Toast.create "Ein Fehler ist aufgetreten beim Herunterladen" |> Toast.error

        Loadable.Data(
            Data {
                data with
                    DownloadRequestRunning = false
            }
        ),
        cmd
    | _ -> model, Cmd.none

let currentDataBox (data: MapSensorData) =
    Column.column [ Column.Width(Screen.All, Column.IsFull) ] [
        (Heading.h3 [
            Heading.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
            ]
        ] [ str "Aktuelle Daten" ])
        yield!
            match data with
            | NoDataAvailable
            | NoPublicData -> []
            | AirData air -> currentAirDataBox air
            | GroundData ground -> currentGroundDataBox ground
            | RainFallData rainFall -> currentRainfallDataBox rainFall
            | LeafletMoistureData leafletMoistureSensorData -> currentLeafletMoistureDataBox leafletMoistureSensorData
            | SoilPhData data -> currentSoilPhDataBox data
            | AverageWindSpeedData data -> currentAverageWindSpeedDataBox data
    ]

let downloadBox dispatch (sensorId: int) (model: DataModel) =
    let maybeRequestData =
        Option.map2 (createHistoricRequestData sensorId) model.DownloadRange.Start model.DownloadRange.End

    let buttonOptions = [
        color.isLink
        prop.disabled (Option.isNone maybeRequestData)
        if model.DownloadRequestRunning then
            button.isLoading
        else
            ()
        match maybeRequestData with
        | Some requestData ->
            prop.onClick (fun _ -> dispatch (MySensSensorMsg.StartDownload requestData |> MySensSensor))
        | None -> ()
    ]

    let content =
        Bulma.columns [
            columns.isVCentered
            prop.children [
                Bulma.column [
                    column.isNarrow
                    prop.children [ Html.p [ prop.text "Datumsbereich:" ] ]
                ]
                Bulma.column [
                    DateRangePicker.view
                        "Von/Bis Datumsbereich auswählen"
                        ((MySensSensorMsg.DownloadDateRangeChanged >> MySensSensor) >> dispatch)
                        model.DownloadRange
                ]
                Bulma.column [
                    column.isNarrow
                    prop.children [
                        Bulma.button.button [ yield! buttonOptions; prop.text "Download" ]
                    ]
                ]
            ]
        ]

    Bulma.box [
        (Heading.h3 [
            Heading.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
            ]
        ] [ str "Download der Daten" ])
        content
    ]

let averageWindSpeedDataGraphs (graphData: AverageWindSpeedGraphsData) =
    let ticks = Interop.mkYAxisAttr "ticks" graphData.Ticks

    let averageWindSpeedChart =
        Recharts.barChart [
            barChart.syncId "syncId1"
            barChart.data graphData.Data
            barChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: AverageWindSpeedGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [ yAxis.unit "km/h"; yAxis.number; ticks ]
                Recharts.legend []
                Recharts.tooltip []
                Recharts.bar [
                    bar.dataKey (fun p -> p.AverageWindSpeed)
                    bar.name "Windgeschwindigkeit [km/h]"
                    bar.fill "#663399"
                ]
            ]
        ]
        |> fullHeightGraphBox "Durchschnittliche Windgeschwindigkeit"

    [ averageWindSpeedChart ]

let getGraphs (data: SensorGraphDataList) =
    match data with
    | AirList air -> airDataGraphs air
    | GroundList ground -> groundDataGraphs ground
    | RainFallList rainFall -> rainFallDataGraphs rainFall
    | LeafletMoistureList moisture -> leafletMoistureDataGraphs moisture
    | SoilPhList soilPh -> soilPhDataGraphs soilPh
    | AverageWindSpeedList averageWind -> averageWindSpeedDataGraphs averageWind

let createEnabledButtonOptions dispatch (sensorId: int) (startDate: DateTime) (endDate: DateTime) =
    let requestData = createHistoricRequestData sensorId startDate endDate

    [
        Button.Disabled false
        Button.OnClick(fun _ -> dispatch (MySensSensorMsg.RequestData requestData |> MySensSensor))
    ]

let createDatePicker (rangePicker: DateRangePicker.Data) (model: DataModel) dispatch =
    let buttonOptions =
        Option.map2 (createEnabledButtonOptions dispatch model.SensorId) rangePicker.Start rangePicker.End
        |> Option.defaultValue [ Button.Disabled true ]

    let buttonOptions =
        List.append buttonOptions [
            Button.IsLink
            Button.IsLoading model.GraphRequestRunning
        ]

    Column.column [ Column.Width(Screen.All, Column.IsFull) ] [
        Columns.columns [ Columns.IsVCentered ] [
            Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ p [] [ str "Datumsbereich:" ] ]
            Column.column [] [
                DateRangePicker.view
                    "Von/Bis Datumsbereich auswählen"
                    ((MySensSensorMsg.GraphDateRangeChanged >> MySensSensor) >> dispatch)
                    rangePicker
            ]
            Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ Button.button buttonOptions [ str "Laden" ] ]
        ]
    ]

let graphView dispatch (model: DataModel) =
    let heading =
        Heading.h3 [
            Heading.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
            ]
        ] [ str "Visualisierung der Daten" ]

    let graphsContent =
        match model.GraphDateRange with
        | SensorDetailPage.Allowed dateRange ->
            let graphs =
                match model.HistoryGraphData with
                | Some graphData -> getGraphs graphData
                | None -> []

            Columns.columns [ Columns.IsMultiline ] ((createDatePicker dateRange model dispatch) :: graphs)
        | SensorDetailPage.NotAllowed ->
            Heading.h5 [
                Heading.Modifiers [
                    Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
                ]
                Heading.IsSubtitle
            ] [
                str
                    "Für die Darstellung der Verlaufsgrafiken ist das Paket \"Historie und erweiterter Download\" notwendig"
            ]

    Box.box' [] [ heading; graphsContent ]

let dataView dispatch (model: DataModel) =
    let content = [
        Heading.h1 [
            Heading.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Centered)
            ]
        ] [ str model.Name ]
        Columns.columns [ Columns.IsMultiline ] [
            Column.column [ Column.Width(Screen.All, Column.IsFull) ] [
                Box.box' [] [ currentDataBox model.CurrentData ]
            ]
            Column.column [ Column.Width(Screen.All, Column.IsFull) ] [ downloadBox dispatch model.SensorId model ]
        ]
    ]

    let graphsView = [ graphView dispatch model ]

    Container.container [] (List.append content graphsView)

let pageView dispatch (model: PageModel) =
    match model with
    | NotFound -> Heading.h1 [] [ str "Der Sensor wurde nicht gefunden" ]
    | NotActive -> Heading.h1 [] [ str "Dieser Sensor ist nicht aktiv" ]
    | NotAllowed -> Heading.h1 [] [ str "Sie dürfen diese Seite nicht ansehen" ]
    | NotAuthenticated ->
        Heading.h1 [] [
            str "Sie sind aktuell nicht eingeloggt, bitte laden Sie diese Seite neu"
        ]
    | Data data -> dataView dispatch data

let view (model: Model) dispatch = Loadable.view (pageView dispatch) model