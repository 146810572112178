namespace Client.Components

open System
open Client.DateTime
open Fable.React
open Fable.React.Props
open Feliz.Recharts
open Fulma
open Shared

module GraphCommon =
    type SimpleGraphData<'a> = { Data: 'a list }

    let rec createAxisTicks includeZero delta (minValue: float) (maxValue: float) =
        let startDataValue = Math.Floor(minValue / delta) * delta

        let startValue =
            if includeZero && 0. < startDataValue then
                0.
            else
                startDataValue

        let endValue = Math.Ceiling(maxValue / delta) * delta

        let ticks =
            [ startValue..delta..endValue ] |> List.map (fun tick -> Math.Round(tick, 1))

        if List.length ticks > 6 then
            createAxisTicks includeZero (delta * 2.) minValue maxValue
        else
            ticks

    let createAxisTicksBy (includeZero: bool) (delta: float) (valueFunc: 'a -> float) (data: 'a list) =
        let values = List.map valueFunc data

        let minValue = List.min values

        let maxValue = List.max values

        createAxisTicks includeZero delta minValue maxValue

    let private createBox (headline: string) (content: ReactElement) =
        Column.column [ Column.Width(Screen.All, Column.IsFull) ] [
            (Heading.h3 [
                Heading.Modifiers [
                    Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
                ]
            ] [ str headline ])
            content
        ]

    let graphBox (headline: string) (cssClass: string) (graph: ReactElement) =
        let graphWrapper =
            div [ Class cssClass ] [
                Recharts.responsiveContainer [
                    responsiveContainer.debounce 1
                    responsiveContainer.chart graph
                ]
            ]

        createBox headline graphWrapper

    let fullHeightGraphBox (headline: string) (graph: ReactElement) =
        graphBox headline "full-height-graph-box" graph

    let halfHeightGraphBox (headline: string) (graph: ReactElement) =
        graphBox headline "half-height-graph-box" graph

    let currentDataLevelItem label value =
        Level.item [ Level.Item.HasTextCentered ] [
            div [] [
                Level.heading [] [ str label ]
                Level.title [] [ str value ]
            ]
        ]

    let temperatureToString = sprintf "%.2f °C"

    let percentageToString = sprintf "%.2f %%"

    let rainFallToString = sprintf " %.1f mm"

    let windSpeedToString = sprintf " %.1f km/h"

    let datedValueToString f (value: DatedValue<'a>) =
        sprintf "%s (%s)" (f value.Value) (timeToString value.Date)