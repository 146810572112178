module Client.DateTime

open System

let dateTimeToString (dateTime: DateTime) =
    dateTime.ToString("dd.MM.yyyy HH:mm:ss")

let dateTimeToShortString (dateTime: DateTime) = dateTime.ToString("dd.MM.yy HH:mm")

let dateToString (dateTime: DateTime) = dateTime.ToString("dd.MM.yyyy")

let dateToFileNameString (dateTime: DateTime) = dateTime.ToString("yyyyMMdd")

let dayMonthToString (dateTime: DateTime) = dateTime.ToString("dd.MM.")

let timeToString (time: DateTime) = time.ToString("HH:mm")