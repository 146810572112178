module Client.Components.Graph.AirSensor

open System
open Client.Components
open Client.Components.GraphCommon
open Client.DateTime
open Feliz.Recharts
open Fulma
open Shared.Dto.Dto
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData

type AirSensorGraphNode = {
    Date: int64
    Datum: string
    Temperature: float
    HumidTemperature: float
    DewPoint: float
    AirHumidity: float
    LeafletWetness: int
}

type AirSensorGraphData = {
    TemperatureAxis: float list
    HumidityAxis: float list
    Data: AirSensorGraphNode list
}

let private createTemperatureAxisTicks (data: AirSensorGraphNode list) =
    let minValue =
        data
        |> List.map (fun graphNode ->
            [
                graphNode.Temperature
                graphNode.HumidTemperature
                graphNode.DewPoint
            ]
            |> List.min
        )
        |> List.min

    let maxValue =
        data
        |> List.map (fun graphNode ->
            [
                graphNode.Temperature
                graphNode.HumidTemperature
                graphNode.DewPoint
            ]
            |> List.max
        )
        |> List.max

    GraphCommon.createAxisTicks false 5. minValue maxValue

let createAirSensorGraphData (data: AirSensorGraphNode list) =
    let temperatureAxisTicks = createTemperatureAxisTicks data

    let humidityAxis = [ 0. .. 20. .. 100. ]

    {
        TemperatureAxis = temperatureAxisTicks
        HumidityAxis = humidityAxis
        Data = data
    }

let toAirSensorGraphData (data: GraphAirDataDto) = {
    Date = data.TimeStamp.Ticks
    Datum = data.TimeStamp.ToString("dd.MM HH:mm")
    Temperature = data.AirTemperature
    HumidTemperature = Math.Round(data.HumidTemperature, 2)
    DewPoint = Math.Round(data.DewPoint, 2)
    AirHumidity = data.AirHumidity
    LeafletWetness = leafletWetnessToInt data.LeafletWetness
}

let mapDataToAir (data: GraphDataDto) =
    match data with
    | Air airData -> Some airData
    | _ -> None

let graphDataDtoToAirGraphData data =
    data
    |> List.map mapDataToAir
    |> List.choose id
    |> List.map toAirSensorGraphData
    |> createAirSensorGraphData

let currentAirDataBox (data: AirSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (dateTimeToString data.Date)
        currentDataLevelItem "Aktuelle Temperatur" (temperatureToString data.AirTemperature)
        currentDataLevelItem "Aktuelle Luftfeuchtigkeit" (percentageToString data.AirHumidity)
        currentDataLevelItem "Min. Temperatur" (datedValueToString temperatureToString data.MinAirTemperature)
        currentDataLevelItem "Max. Temperatur" (datedValueToString temperatureToString data.MaxAirTemperature)
    ]
]

let airDataGraphs (graphData: AirSensorGraphData) =
    let ticks = Interop.mkYAxisAttr "ticks" (graphData.TemperatureAxis |> List.toArray)

    let temperatureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: AirSensorGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [ ticks; yAxis.unit " °C" ]
                Recharts.legend []
                Recharts.tooltip []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.Temperature)
                    line.name "Temperatur [°C]"
                    line.stroke "#FFC000"
                ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.HumidTemperature)
                    line.name "Feuchttemperatur [°C]"
                    line.stroke "#7030A0"
                ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.DewPoint)
                    line.name "Taupunkt [°C]"
                    line.stroke "#0071C1"
                ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Temperatur"

    let humidityYAxis =
        Interop.mkYAxisAttr "ticks" (graphData.HumidityAxis |> List.toArray)

    let humidityChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: AirSensorGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [ yAxis.unit "%"; humidityYAxis ]
                Recharts.legend []
                Recharts.tooltip []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.AirHumidity)
                    line.name "Luftfeuchtigkeit [%]"
                ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Luftfeuchtigkeit"

    let wetnessTicks = Interop.mkYAxisAttr "ticks" [| box 0; box 1 |]

    let leafletWetnessGraph =
        Recharts.areaChart [
            areaChart.syncId "syncId1"
            areaChart.data graphData.Data
            areaChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: AirSensorGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [
                    yAxis.dataKey (fun (p: AirSensorGraphNode) -> p.LeafletWetness)
                    yAxis.number
                    wetnessTicks
                ]
                Recharts.legend []
                Recharts.tooltip []
                Recharts.area [
                    area.fillOpacity 0.2
                    area.dataKey (fun (p: AirSensorGraphNode) -> p.LeafletWetness)
                    area.name "Blattnässe"
                ]
            ]
        ]
        |> halfHeightGraphBox "Verlauf der Blattnässe"

    [
        temperatureChart
        humidityChart
        leafletWetnessGraph
    ]