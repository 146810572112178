module Client.Routing

open Elmish.UrlParser
open Leaflet

type Route =
    | Home
    | TtnSensorList
    | SiteNotice
    | MySensSensorList
    | GatewayList
    | UserList
    | SensorMap of LatLngExpression option
    | PasswordLost
    | ResetPassword of string
    | PublicMap
    | Login
    | MyProfile
    | NotFound
    | CalculationConfiguration
    | MySensSensor of int
    | MySensorsData
    | UserDefinedMapSensorProperties of int
    | PublicHistory of int
    | SensorPictures of int
    | MyMapSensors

let parseRoute: Parser<Route -> Route, Route> =
    oneOf [
        map Home top
        map PublicMap (s "public-map")
        map Login (s "login")
        map TtnSensorList (s "ttn-sensor-list")
        map MySensSensorList (s "my-sens-sensor-list")
        map GatewayList (s "gateway-list")
        map UserList (s "user-list")
        map (SensorMap None) (s "sensor-map")
        map MyProfile (s "my-profile")
        map PasswordLost (s "password-lost")
        map ResetPassword (s "reset-password" </> str)
        map CalculationConfiguration (s "calculation-configuration")
        map MySensSensor (s "mysens-sensor" </> i32)
        map MySensorsData (s "my-sensors-data")
        map UserDefinedMapSensorProperties (s "map-sensor-properties" </> i32)
        map PublicHistory (s "public-history" </> i32)
        map SensorPictures (s "sensor-pictures" </> i32)
        map MyMapSensors (s "my-map-sensors")
        map SiteNotice (s "impressum")
    ]

let routeToUrl route =
    match route with
    | Home -> "/"
    | SiteNotice -> "/impressum"
    | TtnSensorList -> "/ttn-sensor-list"
    | Login -> "/login"
    | MySensSensorList -> "/my-sens-sensor-list"
    | GatewayList -> "/gateway-list"
    | UserList -> "/user-list"
    | SensorMap _ -> "/sensor-map"
    | PublicMap -> "/public-map"
    | MyProfile -> "/my-profile"
    | PasswordLost -> "/password-lost"
    | CalculationConfiguration -> "/calculation-configuration"
    | ResetPassword token -> sprintf "/reset-password/%s" token
    | MySensSensor id -> sprintf "/mysens-sensor/%d" id
    | MySensorsData -> "/my-sensors-data"
    | UserDefinedMapSensorProperties id -> sprintf "/map-sensor-properties/%d" id
    | PublicHistory id -> sprintf "/public-history/%d" id
    | SensorPictures id -> sprintf "/sensor-pictures/%d" id
    | MyMapSensors -> "/my-map-sensors"
    | NotFound -> failwith "NotFound Page doesn't have a route"