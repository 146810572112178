module Client.Page.MyProfile

open Client.Views.PageSkeleton
open Elmish
open Fulma
open Shared
open Shared.Dto.User
open Fable.React
open Client.Forms
open Client.Msg
open Shared.Dto.Dto

type Model = {
    Session: UserSession
    ChangePasswordModel: ChangePassword.Model
}

let dataBox (model: Model) =
    Box.box' [] [
        Heading.p [] [ str "Benutzerdaten" ]

        form [] [
            Field.div [ Field.IsHorizontal ] [
                Field.body [] [
                    Field.div [] [
                        Label.label [] [ str "Vorname" ]

                        Input.text [
                            Input.IsReadOnly true
                            Input.IsStatic true
                            Input.Value(getFirstName model.Session.User.Value)
                        ]
                    ]

                    Field.div [] [
                        Label.label [] [ str "Nachname" ]

                        Input.text [
                            Input.IsReadOnly true
                            Input.IsStatic true
                            Input.Value(getLastName model.Session.User.Value)
                        ]
                    ]
                ]
            ]

            Field.div [] [
                Label.label [] [ str "E-Mail Adresse" ]
                Input.text [
                    Input.IsReadOnly true
                    Input.IsStatic true
                    Input.Value(getMail model.Session.User.Value)
                ]
            ]
        ]
    ]

let passwordBox (model: Model) dispatch =
    Box.box' [] [
        Heading.p [] [ str "Passwort ändern" ]

        ChangePassword.view model.ChangePasswordModel (MyProfileMsg.ChangePasswordForm >> dispatch)
    ]

let bookedPackagesBox (model: Model) =
    match model.Session.User.Value with
    | Admin _ -> None
    | User user ->
        let packages =
            user.Packages
            |> List.map getPackageLabel
            |> List.map (fun text -> li [] [ str text ])

        Some
        <| Box.box' [] [
            Heading.p [] [ str "Gebuchte Pakete" ]

            Content.content [] [ ul [] packages ]
        ]

let view (model: Model) dispatch =
    let boxes = [ dataBox model; passwordBox model dispatch ]

    Hero.hero [ Hero.IsFullheightWithNavbar ] [
        Hero.body [] [
            Container.container [] (Lists.appendToListIfSome boxes (bookedPackagesBox model))
        ]
        Hero.foot [] [ mySensFooter ]
    ]

let init (session: UserSession) =
    {
        Session = session
        ChangePasswordModel = ChangePassword.init session
    },
    Cmd.none

let map (modelMap: 'model -> Model) (cmdMap: 'msg -> Msg) (subModel: 'model, subCmd: Cmd<'msg>) =
    modelMap subModel, Cmd.map cmdMap subCmd

let update (msg: MyProfileMsg) (model: Model) =
    match msg with
    | MyProfileMsg.ChangePasswordForm formMsg ->
        ChangePassword.update formMsg model.ChangePasswordModel
        |> (fun (formModel, cmd) ->
            {
                model with
                    ChangePasswordModel = formModel
            },
            Cmd.map (ChangePasswordForm >> MyProfile) cmd
        )