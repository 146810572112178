namespace Client.Components

open System

module DateRangePicker =
    type Data = {
        DeltaLimit: TimeSpan option

        Start: DateTime option
        StartLimit: DateTime option

        End: DateTime option
        EndLimit: DateTime option
    }

    let updateLimits (data: Data) =
        match (data.Start, data.DeltaLimit) with
        | Some start, Some delta ->
            let startLimit = start - delta
            let endLimit = min (start + delta) DateTime.Now.Date

            {
                data with
                    StartLimit = Some startLimit
                    EndLimit = Some endLimit
            }
        | _, _ -> {
            data with
                StartLimit = None
                EndLimit = Some DateTime.Now.Date
          }

    let updateData (dates: DateTime list) (data: Data) =
        let updateStartEnd =
            fun (data: Data) startDate endDate -> {
                data with
                    Start = startDate
                    End = endDate
            }

        let newData =
            match List.length dates with
            | 2 -> updateStartEnd data (Some dates.[0]) (Some dates.[1])
            | 1 -> updateStartEnd data (Some dates.[0]) None
            | 0 -> updateStartEnd data None None
            | _ -> raise <| Exception "More than 2 dates selected"

        updateLimits newData

    let view (placeHolder: string) callback (data: Data) =
        let selectedRange = [ data.Start; data.End ] |> List.choose id |> Array.ofList

        let flatpickrOptions = [
            Flatpickr.custom "defaultDate" selectedRange true
            Flatpickr.Placeholder placeHolder
            Flatpickr.Locale Flatpickr.Locales.german
            Flatpickr.DateFormat "d.m.Y"
            Flatpickr.SelectionMode Flatpickr.Mode.Range
            Flatpickr.OnManyChanged callback
            Flatpickr.ClassName "input"
        ]

        let flatpickrOptions =
            match data.StartLimit with
            | Some limit -> (Flatpickr.MinimumDate limit) :: flatpickrOptions
            | None -> flatpickrOptions

        let flatpickrOptions =
            match data.EndLimit with
            | Some limit -> Flatpickr.MaximumDate limit :: flatpickrOptions
            | None -> flatpickrOptions

        Flatpickr.flatpickr flatpickrOptions

    let initWithRange startDate endDate deltaLimit = {
        DeltaLimit = Some deltaLimit

        Start = Some startDate
        StartLimit = None

        End = Some endDate
        EndLimit = Some DateTime.Now.Date
    }

    let initWithOneWeek deltaLimit =
        let startDate = (DateTime.Now.Date.AddDays -7.)
        let endDate = DateTime.Now.Date

        initWithRange startDate endDate deltaLimit

    let initWithTwoDays deltaLimit =
        let startDate = (DateTime.Now.Date.AddDays -2.)
        let endDate = DateTime.Now.Date

        initWithRange startDate endDate deltaLimit

    let init deltaLimit =
        let startDate = (DateTime.Now.Date - deltaLimit)
        let endDate = DateTime.Now.Date

        initWithRange startDate endDate deltaLimit