module Shared.Dto.User

open System

type Package =
    | BaseAccess
    | AdditionalSensor
    | History
    | Alerts

let getPackageLabel package =
    match package with
    | BaseAccess -> "Basiszugang"
    | AdditionalSensor -> "Zusätzlicher Sensor"
    | History -> "History und erweiterter Download der Daten"
    | Alerts -> "Bewarnung"

let getPackageCharacter package =
    match package with
    | BaseAccess -> "B"
    | AdditionalSensor -> "S"
    | History -> "H"
    | Alerts -> "W"

let getPackageId package =
    match package with
    | BaseAccess -> "base"
    | AdditionalSensor -> "additional_sensor"
    | History -> "history"
    | Alerts -> "alerts"

let packageFromId packageId =
    match packageId with
    | "base" -> BaseAccess
    | "additional_sensor" -> AdditionalSensor
    | "history" -> History
    | "alerts" -> Alerts
    | _ -> raise (Exception <| sprintf "Unknown Package Id '%s'" packageId)

type BaseUserDataDto = {
    Mail: string
    FirstName: string
    LastName: string
    Password: string option
    LastLogin: DateTime option
}

type UserDataDto = {
    Base: BaseUserDataDto
    Packages: Package list
}

type UserDto =
    | Admin of BaseUserDataDto
    | User of UserDataDto

let getUserData user =
    match user with
    | Admin data -> data
    | User data -> data.Base

let getFirstName user = (getUserData user).FirstName

let getLastName user = (getUserData user).LastName

let getMail user = (getUserData user).Mail

let getLastLogin user = (getUserData user).LastLogin

let getPackages user =
    match user with
    | Admin _ -> []
    | User user -> user.Packages

let userTypeToString user =
    match user with
    | Admin _ -> "Administrator"
    | User _ -> "Benutzer"

let getFullName user =
    sprintf "%s %s" (getLastName user) (getFirstName user)