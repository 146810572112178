module Client.Components.Graph.GroundSensor

open Client.Components.GraphCommon
open Feliz.Recharts
open Fulma
open Client.DateTime
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData

type GroundSensorGraphNode = {
    Date: int64
    Datum: string
    Temperature: float
    SoilHumidity: float
    SoilConductivity: float
}

type GroundSensorGraphData = {
    TemperatureAxisTicks: float list
    HumidityAxisTicks: float list
    ConductivityAxisTicks: float list
    Data: GroundSensorGraphNode list
}

let createGroundSensorGraphData (data: GroundSensorGraphNode list) =
    let temperatureAxis = createAxisTicksBy true 5. (fun node -> node.Temperature) data

    let humidityAxis = createAxisTicksBy true 5. (fun node -> node.SoilHumidity) data

    let conductivityAxis =
        createAxisTicksBy true 100. (fun node -> node.SoilConductivity) data

    {
        TemperatureAxisTicks = temperatureAxis
        HumidityAxisTicks = humidityAxis
        ConductivityAxisTicks = conductivityAxis
        Data = data
    }

let toGroundSensorGraphData (data: GraphSoilDataDto) = {
    Date = data.TimeStamp.Ticks
    Datum = data.TimeStamp.ToString("dd.MM HH:mm")
    Temperature = data.SoilTemperature
    SoilHumidity = data.SoilHumidity
    SoilConductivity = data.SoilConductivity
}

let mapDataToGround (data: GraphDataDto) =
    match data with
    | Soil groundData -> Some groundData
    | _ -> None

let graphDataDtoToGroundGraphData data =
    data
    |> List.map mapDataToGround
    |> List.choose id
    |> List.map toGroundSensorGraphData
    |> createGroundSensorGraphData

let currentGroundDataBox (data: GroundSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (dateTimeToString data.Date)
        currentDataLevelItem "Aktuelle Bodenfeuchtigkeit" (percentageToString data.GroundHumidity)
        currentDataLevelItem "Aktuelle Temperatur" (temperatureToString data.GroundTemperature)
        currentDataLevelItem "Min. Bodenfeuchtigkeit" (datedValueToString percentageToString data.MinGroundHumidity)
        currentDataLevelItem "Max. Bodenfeuchtigkeit" (datedValueToString percentageToString data.MaxGroundHumidity)
    ]
]

let groundDataGraphs (graphData: GroundSensorGraphData) =
    let humidityAxisTicks =
        Interop.mkYAxisAttr "ticks" (List.toArray graphData.HumidityAxisTicks)

    let humidityChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: GroundSensorGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [
                    yAxis.unit "%"
                    yAxis.number
                    humidityAxisTicks
                    yAxis.tickCount 6
                ]
                Recharts.legend []
                Recharts.tooltip []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.SoilHumidity)
                    line.name "Bodenfeuchtigkeit [%]"
                    line.stroke "#01B0F1"
                ]
            ]
        ]
        |> fullHeightGraphBox "Bodenfeuchtigkeit"

    let temperatureAxisTicks =
        Interop.mkYAxisAttr "ticks" (List.toArray graphData.TemperatureAxisTicks)

    let temperatureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: GroundSensorGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [ yAxis.unit " °C"; temperatureAxisTicks ]
                Recharts.legend []
                Recharts.tooltip []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.Temperature)
                    line.name "Bodentemperatur [in °C]"
                    line.stroke "#FFC000"
                ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Bodentemperatur"

    let conductivityAxisTicks =
        Interop.mkYAxisAttr "ticks" (List.toArray graphData.ConductivityAxisTicks)

    let conductivityChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    xAxis.dataKey (fun (p: GroundSensorGraphNode) -> p.Datum)
                ]
                Recharts.yAxis [ yAxis.unit " μS/cm"; conductivityAxisTicks ]
                Recharts.legend []
                Recharts.tooltip []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.SoilConductivity)
                    line.name "Leitfähigkeit in [in μS/cm]"
                    line.stroke "#EB9934"
                ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Bodenleitfähigkeit"

    [
        humidityChart
        temperatureChart
        conductivityChart
    ]