module Client.Page.NotFound

open Fulma
open Fable.React

let view =
    Heading.h1 [
        Heading.Modifiers [
            Modifier.TextAlignment(Screen.All, TextAlignment.Centered)
        ]
    ] [ str "Diese Seite existiert nicht" ]