module Client.AverageWindSpeed

open Shared.Dto.MapSensorData

let mapSensorDataToAverageWindSpeed (data: MapSensorData) =
    match data with
    | AverageWindSpeedData data -> Some data
    | _ -> None

let getIconForWindSpeed (data: AverageWindSensorData) : string =
    let category =
        if data.AverageWindSpeed <= 1.8 then "0"
        else if data.AverageWindSpeed <= 3.6 then "1"
        else if data.AverageWindSpeed <= 9.0 then "2"
        else "3"

    sprintf "/images/wind-speed/wind_%s.svg" category