namespace Shared

[<RequireQualifiedAccess>]
module Lists =
    let addToListIfSome (list: 'a list) (maybeItem: 'a option) : 'a list =
        match maybeItem with
        | Some item -> item :: list
        | None -> list

    let appendToListIfSome (list: 'a list) (maybeItem: 'a option) : 'a list =
        match maybeItem with
        | Some item -> List.append list [ item ]
        | None -> list