namespace Client

open Browser.Types
open Fable.FontAwesome
open Fable.React.Props
open Fulma
open Fable.React

[<RequireQualifiedAccess>]
module ConfirmationModal =
    type Configuration = {
        Headline: string
        Text: string
        OnClose: MouseEvent -> unit
        OnYes: MouseEvent -> unit
        OnNo: MouseEvent -> unit
    }

    let private createButton text icon color onClick =
        Button.button [ Button.Color color; Button.OnClick onClick ] [
            Icon.icon [] [ Fa.i [ icon ] [] ]
            span [] [ str text ]
        ]

    let view configuration =
        Modal.modal [ Modal.IsActive true ] [
            Modal.background [
                GenericOption.Props [ DOMAttr.OnClick configuration.OnClose ]
            ] []
            Modal.Card.card [] [
                Modal.Card.head [] [
                    Modal.Card.title [] [ str configuration.Headline ]
                    Delete.delete [ Delete.OnClick configuration.OnClose ] []
                ]
                Modal.Card.body [] [ Content.content [] [ str configuration.Text ] ]
                Modal.Card.foot [] [
                    Level.level [] [
                        Level.left [] []
                        Level.right [] [
                            Level.item [] [
                                Control.p [] [
                                    createButton "Ja" Fa.Solid.Check IsGrey configuration.OnYes
                                ]
                            ]
                            Level.item [] [
                                Control.p [] [
                                    createButton "Nein" Fa.Solid.Times IsGrey configuration.OnNo
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]