module Client.Navbar

open Fable.React
open Fable.React.Props
open Fulma
open Shared
open Shared.Dto.User

let navLink text onClick =
    Navbar.Item.div [] [
        Button.a [ Button.OnClick onClick ] [ str text ]
    ]

let navBrand =
    Navbar.Brand.div [] [
        Navbar.Item.div [] [ img [ Src "/images/icon/menu-icon.png" ] ]
    ]

let leftNavigation (maybeUser: IdValue<UserDto> option) dispatch =
    let items = []

    Navbar.Start.div [] items

let rightNavigation (maybeUser: IdValue<UserDto> option) dispatch =
    let items = []

    Navbar.End.div [] items

let createMainNavbar (user: IdValue<UserDto> option) dispatch =
    Navbar.navbar [ Navbar.Color Color.IsLight ] [
        navBrand
        leftNavigation user dispatch
        rightNavigation user dispatch
    ]