module Client.Leaflet

open System
open Client.Msg
open Client.Routing
open Fable.Core
open Fable.FontAwesome
open Fable.React
open DateTime
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Msg
open ReactLeaflet
open Shared
open Shared.Infrastructure
open Leaflet
open Shared.Dto.MapSensorData
open Fable.Core.JsInterop

let popupSecondColCls = "second-column"
let popupMeasurementCls = "measurement-data"

let iconCls = "icon"

let signalStrengthCls = "signal-strength"

let rainIntensityCls = "rain-intensity"

let wideIconCls = "wide-icon"

let leafletWetnessCls = "leaflet-wetness"

let detailsButtonCls = "details-button"

type IconFactory =
    abstract createIcon: props: obj -> Leaflet.Icon<obj>

    abstract createCircle: latlng: obj -> props: obj -> Leaflet.Circle<obj>

[<ImportAll("./icon.js")>]
let iconFactory: IconFactory = jsNative

type IconProps =
    | IconUrl of string
    | IconSize of obj array
    | IconAnchor of obj array
    | PopupAnchor of obj array
    | TooltipAnchor of obj array

let makePosition latitude longitude =
    LatLngExpression.Case3(latitude, longitude)

let createIcon (fileName: string) =
    let iconOptions =
        keyValueList CaseRules.LowerFirst [
            IconUrl(sprintf "/flags/%s" fileName)
            IconSize [| box 25; box 41 |]
            IconAnchor [| box 12; box 41 |]
            PopupAnchor [| box 0; box -42 |]
        ]

    iconFactory.createIcon iconOptions |> U2.Case1

let getTotalMinutesLimit (data: MapSensorData) =
    match data with
    | NoDataAvailable
    | NoPublicData -> None
    | AirData _
    | RainFallData _
    | AverageWindSpeedData _ -> Some 30.0
    | GroundData _
    | LeafletMoistureData _
    | SoilPhData _ -> Some 50.0

let isOldAgeData (data: MapSensorData) =
    let totalMinutesLimit = (getTotalMinutesLimit data)

    getSensorDataDate data
    |> Option.map (fun date -> (DateTime.UtcNow - date).TotalMinutes)
    |> Option.map2 (fun limit totalMinutes -> totalMinutes > limit) totalMinutesLimit
    |> Option.defaultValue true

let getIconNameByMapSensorData (data: MapSensorData) =
    let isOldAge = isOldAgeData data

    match (data, isOldAge) with
    | NoDataAvailable, _
    | NoPublicData, _ -> "Grey.png"
    | AirData _, true -> "TL_grey.svg"
    | AirData _, false -> "TL.svg"
    | GroundData _, true -> "BT_grey.svg"
    | GroundData _, false -> "BT_brown.svg"
    | RainFallData _, true -> "RF_grey.svg"
    | RainFallData _, false -> "RF_blue.svg"
    | LeafletMoistureData _, true -> "BN_grey.svg"
    | LeafletMoistureData _, false -> "BN_blue.svg"
    | SoilPhData _, true -> "PH_grey.svg"
    | SoilPhData _, false -> "PH_gold.svg"
    | AverageWindSpeedData _, true -> "WI_grey.svg"
    | AverageWindSpeedData _, false -> "WI_violett.svg"

let getSensorNameLinkColor (data: MapSensorData) =
    match data with
    | NoDataAvailable
    | NoPublicData _ -> "black"
    | AirData _ -> "#369fd9"
    | GroundData _ -> "#8f5c30"
    | RainFallData _ -> "#0033ab"
    | LeafletMoistureData _ -> "#4c70c4"
    | SoilPhData _ -> "#cc9933"
    | AverageWindSpeedData _ -> "#663399"

let makeMarker data latLong popupContent =
    let icon = getIconNameByMapSensorData data |> createIcon

    marker [
        MarkerProps.Position latLong
        MarkerProps.Icon icon
    ] [
        popup [
            PopupProps.KeepInView false
            PopupProps.MaxWidth 800.
            PopupProps.MinWidth 250.
        ] [ popupContent ]
    ]

let datedValueToString f (value: DatedValue<'a>) =
    sprintf "%s (%s)" (f value.Value) (timeToString value.Date)

let temperatureToString = sprintf "%.2f °C"

let conductivityToString conductivity = sprintf "%.0f μS/cm" conductivity

let percentageToString = sprintf "%.2f %%"

let rainFallToString = sprintf " %.1f mm"

let windSpeedToString = sprintf "%.1f km/h"

let noSensorDataToolTip = [
    tr [] [
        td [ ColSpan 2 ] [ str "Heute noch keine Daten empfangen" ]
    ]
]

let popupUpdateRow date =
    let timeElapsed = (DateTime.Now - date) |> TimeSpan.toHumanReadable

    let timeElapsedToolTip =
        sprintf "%s (%s)" (dayMonthToString date) (timeToString date)

    tr [] [
        td [] [ str "Aktualisiert:" ]
        td [
            classList [
                (popupSecondColCls, true)
                (Tooltip.ClassName, true)
            ]
            Tooltip.dataTooltip timeElapsedToolTip
        ] [ sprintf "vor %s" timeElapsed |> str ]
    ]

let popupDataRow label number =
    tr [] [
        td [] [ sprintf "%s:" label |> str ]
        td [
            classList [
                popupSecondColCls, true
                (popupMeasurementCls, true)
            ]
        ] [ str number ]
    ]

let popupIconRow icon label onClick =
    tr [] [
        td [ ColSpan 2 ] [
            a [ Class "icon-text"; OnClick onClick ] [
                Icon.icon [] [ Fa.i [ icon ] [] ]
                span [] [ str label ]
            ]
        ]
    ]

let createDetailsSensorNameLink props (sensor: MapSensor) dispatch =
    let customProps = [
        OnClick(fun ev ->
            ev.preventDefault ()
            dispatch (Route.MySensSensor sensor.Id |> GoToRoute |> Global)
        )
        :> IHTMLProp
        Style [ Color(getSensorNameLinkColor sensor.Data) ] :> IHTMLProp
    ]

    a (List.append customProps props) [ str sensor.Name ]

let makeMarkerPopupHeader (sensor: MapSensor) dispatch =
    let factory =
        if sensor.DetailsAllowed then
            createDetailsSensorNameLink
        else
            fun props sensor _ -> span props [ str sensor.Name ]

    let nameProperties: IHTMLProp list = [ Class "popup-sensor-name" :> IHTMLProp ]

    let sensorName = factory nameProperties sensor dispatch

    tr [ Class "header" ] [
        td [] [ sensorName ]
        td [ Class popupSecondColCls ] [ str (dateToString DateTime.Now) ]
    ]

let private createPopupSymbol cls toolTip src =
    div [
        classList [
            Tooltip.ClassName, true
            iconCls, true
            cls, true
        ]
        Tooltip.dataTooltip toolTip
    ] [ img [ Src src ] ]

let makeMarkerSymbolRow (data: MapSensorData) =
    let signalStrength = SignalStrength.fromMapSensorData data
    let leafletWetness = LeafletWetness.getLeafletWetness data
    let leafletMoisture = LeafletWetness.getLeafletMoisture data
    let rainIntensity = RainFall.getRainFallIntensity data
    let averageWindSpeed = AverageWindSpeed.mapSensorDataToAverageWindSpeed data
    let soilPh = SoilPh.toSoilPhData data

    let leftContent =
        List.choose id [
            signalStrength
            |> Option.map SignalStrength.toImageSrc
            |> Option.map (createPopupSymbol signalStrengthCls "Empfangsstärke")
            (getBatteryLevel data)
            |> Option.map BatteryLevel.toImageSrc
            |> Option.map (createPopupSymbol signalStrengthCls "Batterie")
        ]

    let leafletWetnessTooltipText =
        match leafletWetness with
        | Some(Shared.Dto.Dto.Wet _) -> "Blatt nass"
        | Some Shared.Dto.Dto.Dry -> "Blatt trocken"
        | None -> ""

    let rightContent =
        List.choose id [
            leafletWetness
            |> Option.map LeafletWetness.toImageSrc
            |> Option.map (createPopupSymbol leafletWetnessCls leafletWetnessTooltipText)
            leafletMoisture
            |> Option.map LeafletWetness.percentageToImageSrc
            |> Option.map (createPopupSymbol rainIntensityCls "Blattnässe")
            rainIntensity
            |> Option.map RainFall.toImageSrc
            |> Option.map (createPopupSymbol rainIntensityCls "Regenmenge")
            averageWindSpeed
            |> Option.map AverageWindSpeed.getIconForWindSpeed
            |> Option.map (createPopupSymbol wideIconCls "Windgeschwindigkeit")
            soilPh
            |> Option.map SoilPh.toImageSrc
            |> Option.map (createPopupSymbol wideIconCls "PH-Wert")
        ]

    tr [] [
        td [] leftContent
        td [ Class popupSecondColCls ] rightContent
    ]

let aggregatedMinMaxValuesPopup label minValue maxValue =
    let table =
        table [ Style [ Width "100%" ] ] [
            tbody [] [
                tr [] [
                    td [] [ str "Heute:" ]
                    td [] [ str label; sub [] [ str "min" ]; str ":" ]
                    td [ Class popupSecondColCls ] [ str minValue ]
                ]
                tr [] [
                    td [] []
                    td [] [ str label; sub [] [ str "max" ]; str ":" ]
                    td [ Class popupSecondColCls ] [ str maxValue ]
                ]
            ]
        ]

    tr [] [ td [ ColSpan 2 ] [ table ] ]

let aggregatedMinMaxTemperaturePopup minValue maxValue =
    let datedTemperatureString = datedValueToString temperatureToString

    aggregatedMinMaxValuesPopup "T" (datedTemperatureString minValue) (datedTemperatureString maxValue)

let aggregatedMinMaxHumidityPopup minValue maxValue =
    let datedPercentageString = datedValueToString percentageToString

    aggregatedMinMaxValuesPopup "BF" (datedPercentageString minValue) (datedPercentageString maxValue)

let noPublicSensorDataPopup = [
    tr [] [
        td [ ColSpan 2 ] [
            str
                "Für diesen Sensor gibt es keine öffentlich sichtbaren Daten. Wenn du die Daten sehen möchtest, dann logge dich bitte ein"
        ]
    ]
]

let airDataToPopup sensorId (data: AirSensorData) dispatch = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Temperatur" (temperatureToString data.AirTemperature)
    popupDataRow "Rel. Luftfeuchte" (sprintf " %.2f %%" data.AirHumidity)
    popupDataRow "Feuchttemperatur" (temperatureToString data.HumidTemperature)
    popupDataRow "Taupunkt" (temperatureToString data.DewPoint)
    popupDataRow
        "Gefühlte Temperatur"
        (data.ApparentTemperature
         |> Option.map temperatureToString
         |> Option.defaultValue "-")
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    aggregatedMinMaxTemperaturePopup data.MinAirTemperature data.MaxAirTemperature
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupIconRow
        Fa.Solid.ChartLine
        "Temperaturverlauf"
        (fun event ->
            event.preventDefault ()
            GoToRoute(Route.PublicHistory sensorId) |> Global |> dispatch
        )
    popupIconRow
        Fa.Solid.PhotoVideo
        "Fotos"
        (fun event ->
            event.preventDefault ()
            GoToRoute(Route.SensorPictures sensorId) |> Global |> dispatch
        )
]

let groundDataToPopup (data: GroundSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Rel. Bodenfeuchte" (sprintf " %.2f %%" data.GroundHumidity)
    popupDataRow "Bodentemperatur" (temperatureToString data.GroundTemperature)
    popupDataRow "Leitfähigkeit" (conductivityToString data.SoilConductivity)
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    aggregatedMinMaxHumidityPopup data.MinGroundHumidity data.MaxGroundHumidity
]

let rainFallDataToPopup (data: RainFallSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Regen aktuell" (rainFallToString data.CurrentRainFall)
    popupDataRow "Regen letzte Stunde" (rainFallToString data.RainFallLastHour)
    popupDataRow "Regen Heute" (rainFallToString data.RainFallToday)
]

let leafletMoistureDataToPopup (data: LeafletMoistureSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Blattnässe" (percentageToString data.LeafletMoisture)
    popupDataRow "Blatttemperatur" (temperatureToString data.LeafletTemperature)
]

let soilPhDataToPopup (data: SoilPhSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "PH-Wert" (sprintf "%.2f" data.SoilPh)
    popupDataRow "Bodentemperatur" (temperatureToString data.SoilTemperature)
]

let averageWindSpeedDataToPopup (data: AverageWindSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Ø Windgeschwindigkeit" (windSpeedToString data.AverageWindSpeed)
]

let sensorDataToPopup sensorId (data: MapSensorData) dispatch =
    match data with
    | NoDataAvailable -> noSensorDataToolTip
    | NoPublicData -> noPublicSensorDataPopup
    | AirData airData -> airDataToPopup sensorId airData dispatch
    | GroundData groundData -> groundDataToPopup groundData
    | RainFallData rainFallData -> rainFallDataToPopup rainFallData
    | LeafletMoistureData sensorData -> leafletMoistureDataToPopup sensorData
    | SoilPhData sensorData -> soilPhDataToPopup sensorData
    | AverageWindSpeedData sensorData -> averageWindSpeedDataToPopup sensorData

let detailsButtonRow (sensor: MapSensor) dispatch =
    tr [] [
        td [ ColSpan 2; Class detailsButtonCls ] [
            Button.button [
                Button.Color Color.IsLink
                Button.OnClick(fun _ -> dispatch (Route.MySensSensor sensor.Id |> GoToRoute |> Global))
                Button.IsOutlined
            ] [ str "Detailseite" ]
        ]
    ]

let makeMarkerPopup (sensor: MapSensor) dispatch =
    let rows = [
        makeMarkerPopupHeader sensor dispatch
        makeMarkerSymbolRow sensor.Data
    ]

    let rows = List.append rows (sensorDataToPopup sensor.Id sensor.Data dispatch)

    table [ Style [ Width "100%" ] ] [ tbody [] rows ]

let private minTemperature = -20.

let private maxTemperature = 40.

let private greyColor = "#808080"

let rgbToHex (red, green, blue) = sprintf "#%02X%02X%02X" red green blue

let private getCircleColor (temperature: float) =
    let fixedTemperature =
        if temperature < minTemperature then minTemperature
        else if temperature > maxTemperature then maxTemperature
        else temperature
        |> Math.Floor

    let rgb =
        match fixedTemperature with
        | -20. -> (100, 0, 255)
        | -19. -> (83, 0, 242)
        | -18. -> (65, 0, 230)
        | -17. -> (32, 32, 217)
        | -16. -> (0, 65, 205)
        | -15. -> (0, 85, 215)
        | -14. -> (0, 105, 225)
        | -13. -> (5, 125, 232)
        | -12. -> (10, 145, 240)
        | -11. -> (20, 157, 247)
        | -10. -> (30, 170, 255)
        | -9. -> (45, 182, 255)
        | -8. -> (60, 195, 255)
        | -7. -> (80, 207, 255)
        | -6. -> (100, 220, 255)
        | -5. -> (125, 232, 252)
        | -4. -> (150, 245, 250)
        | -3. -> (180, 250, 252)
        | -2. -> (210, 255, 255)
        | -1. -> (160, 212, 222)
        | 0. -> (110, 170, 190)
        | 1. -> (95, 180, 190)
        | 2. -> (80, 190, 190)
        | 3. -> (47, 200, 160)
        | 4. -> (15, 210, 130)
        | 5. -> (32, 217, 115)
        | 6. -> (50, 225, 100)
        | 7. -> (75, 247, 80)
        | 8. -> (100, 240, 60)
        | 9. -> (132, 245, 32)
        | 10. -> (165, 250, 5)
        | 11. -> (187, 250, 2)
        | 12. -> (210, 250, 0)
        | 13. -> (232, 252, 20)
        | 14. -> (255, 255, 40)
        | 15. -> (255, 255, 100)
        | 16. -> (255, 255, 160)
        | 17. -> (255, 240, 137)
        | 18. -> (255, 225, 115)
        | 19. -> (255, 207, 105)
        | 20. -> (255, 190, 95)
        | 21. -> (255, 170, 80)
        | 22. -> (255, 150, 65)
        | 23. -> (255, 132, 50)
        | 24. -> (255, 115, 35)
        | 25. -> (255, 95, 17)
        | 26. -> (255, 75, 0)
        | 27. -> (255, 47, 7)
        | 28. -> (255, 20, 15)
        | 29. -> (230, 10, 7)
        | 30. -> (205, 0, 0)
        | 31. -> (185, 0, 0)
        | 32. -> (165, 0, 0)
        | 33. -> (145, 0, 0)
        | 34. -> (125, 0, 0)
        | 35. -> (112, 0, 7)
        | 36. -> (100, 0, 15)
        | 37. -> (107, 15, 30)
        | 38. -> (115, 30, 45)
        | 39. -> (120, 47, 62)
        | 40. -> (125, 65, 80)
        | _ -> raise (Exception "Shouldn't happen")

    rgbToHex rgb

let createCircleMarker (position: LatLngExpression) radius popupContent (maybeTemperature: float option) =
    let color =
        match maybeTemperature with
        | Some temperature -> getCircleColor temperature
        | None -> greyColor

    let popup =
        popup [
            PopupProps.KeepInView false
            PopupProps.MaxWidth 800.
            PopupProps.MinWidth 250.
        ] [ popupContent ]

    let bigCircle =
        circle [
            CircleProps.Custom("center", position)
            CircleProps.Radius radius
            CircleProps.Color color
            CircleProps.Opacity 0.8
            CircleProps.FillOpacity 0.5
            CircleProps.Fill true
        ] [ popup ]

    let smallCircle =
        circle [
            CircleProps.Custom("center", position)
            CircleProps.Radius 2.0
            CircleProps.Color "#000000"
            CircleProps.Opacity 0.0
            CircleProps.FillOpacity 1.0
        ] [ popup ]

    layerGroup [] [ bigCircle; smallCircle ]

let getMapFromEvent (event: LeafletEvent) =
    event.target |> Option.map (fun target -> target :?> Leaflet.Map) |> Option.get