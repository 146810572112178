module Client.Page.GatewayList

open Client
open Client.Api
open Client.InfrastructureTypes
open Client.Msg

open Client.Forms
open Client.Views
open Client.DateTime
open Elmish
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Shared
open Shared.Dto.Dto
open Fable.React
open Shared.Dto.MySensGateway
open Shared.Dto.User
open Shared.Infrastructure
open Shared.WithLastDate
open Thoth.Elmish

type DataModel = {
    Gateways: WithOptionalLastDate<IdValue<MySensGateway>> list
    Users: IdValue<UserDto> list
    Modal: Gateway.Model option
    TTNRefreshRequestRunning: bool
    TTIRefreshRequestRunning: bool
    Session: UserSession
}

type LoadingModel = {
    Session: UserSession
    Gateways: WithOptionalLastDate<IdValue<MySensGateway>> list option
    Users: IdValue<UserDto> list option
}

let modelLoadingToData (model: LoadingModel) : DataModel option =
    Option.map2
        (fun gateways users -> {
            Gateways = gateways
            Session = model.Session
            Users = users
            Modal = None
            TTNRefreshRequestRunning = false
            TTIRefreshRequestRunning = false
        })
        model.Gateways
        model.Users

type Model = Loadable<DataModel, LoadingModel>

let init (session: UserSession) =
    let request = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmds =
        Cmd.batch [
            Cmd.OfAsync.perform
                api.getAllGatewaysWithOptionalLastDate
                ()
                (GatewayListMsg.GatewaysReceived >> GatewayList)
            Cmd.OfAsync.perform api.getAllUsers request (GatewayListMsg.UsersReceived >> GatewayList)
        ]

    Loadable.Loading {
        Gateways = None
        Session = session
        Users = None
    },
    cmds

let updateRequestRunningModalState (model: DataModel) (requestRunning: bool) =
    let modalModel = Option.get model.Modal

    {
        model with
            Modal =
                Some {
                    modalModel with
                        RequestRunning = requestRunning
                }
    }

let updateRefreshRequestRunning (model: DataModel) (running: bool) = {
    model with
        TTNRefreshRequestRunning = running
        TTIRefreshRequestRunning = running
}

let update (msg: GatewayListMsg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | GatewayListMsg.GatewaysReceived gateways, Loadable.Loading loadingModel ->
        let updatedLoadingModel = {
            loadingModel with
                Gateways = Some gateways
        }

        let newModel =
            modelLoadingToData updatedLoadingModel
            |> Option.map Loadable.Data
            |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

        newModel, Cmd.none
    | GatewayListMsg.UsersReceived maybeUsers, Loadable.Loading loadingModel ->
        match maybeUsers with
        | Result.Ok users ->
            let updatedLoadingModel = { loadingModel with Users = Some users }

            let newModel =
                modelLoadingToData updatedLoadingModel
                |> Option.map Loadable.Data
                |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

            newModel, Cmd.none
        | Result.Error error ->
            let errorMessage =
                match error with
                | AuthErr Unauthenticated -> "Sie sind nicht eingeloggt, bitte laden Sie die Seite neu"
                | AuthErr Unauthorized ->
                    "Sie sind nicht dazu berechtigt, die Sensorkarte anzuzeigen, bitte wenden Sie sich an einen Administrator"
                | _ -> "Ein Fehler beim Laden der Sensoren ist aufgetreten. Bitte laden Sie die Seite neu"

            let toastCmd =
                Toast.create errorMessage |> Toast.title "Fehler beim User laden" |> Toast.error

            model, toastCmd
    | GatewayListMsg.RefreshGatewaysTTI, Loadable.Data data ->
        let cmd =
            Cmd.OfAsync.perform api.refreshGatewaysFromTti () (GatewayListMsg.GatewaysRefreshed >> GatewayList)

        Loadable.Data {
            data with
                TTIRefreshRequestRunning = true
        },
        cmd
    | GatewayListMsg.GatewaysRefreshed successful, Loadable.Data data ->
        if successful then
            let toastCmd =
                Toast.create "Die Gateways wurden erfolgreich aktualisiert" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        else
            let toastCmd =
                Toast.create "Ein Fehler ist aufgetreten beim aktualisieren der Gateways"
                |> Toast.error

            Loadable.Data(updateRefreshRequestRunning data false), toastCmd

    | GatewayListMsg.CloseModal, Loadable.Data data -> Loadable.Data { data with Modal = None }, Cmd.none
    | GatewayListMsg.OpenModal gateway, Loadable.Data data ->
        Loadable.Data {
            data with
                Modal = Some(Gateway.init data.Users gateway)
        },
        Cmd.none
    | GatewayListMsg.GatewayUpdated success, Loadable.Data data ->
        if success then
            let toastCmd =
                Toast.create "Der Gateway wurde erfolgreich gespeichert" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        else
            let toastCmd = Toast.create "Das Speichern ist fehlgeschlagen" |> Toast.error
            Loadable.Data(updateRequestRunningModalState data false), toastCmd
    | GatewayListMsg.UpdateGateway gateway, Loadable.Data data ->
        Loadable.Data(updateRequestRunningModalState data true),
        Cmd.OfAsync.perform api.updateGateway gateway (GatewayListMsg.GatewayUpdated >> GatewayList)
    | _, Loadable.Data data ->
        let result =
            match data.Modal with
            | Some modal -> Gateway.update msg modal |> (fun (modal, cmd) -> Some modal, cmd)
            | None -> None, Cmd.none

        Loadable.Data { data with Modal = fst result }, snd result
    | _, _ -> model, Cmd.none

let maybeFloatToString (maybeFloat: float option) =
    maybeFloat
    |> Option.map (fun float -> float.ToString())
    |> Option.defaultValue ""

let gatewayToRow
    dispatch
    (users: IdValue<UserDto> list)
    (index: int)
    (gateway: WithOptionalLastDate<IdValue<MySensGateway>>)
    =
    let findUser =
        fun userId -> List.tryFind (fun (user: IdValue<_>) -> user.Id = userId) users

    let lastDateString =
        gateway.LastDate
        |> Option.map TimeSpan.createTimeSinceString
        |> Option.defaultValue ""

    let lastDateTooltip =
        gateway.LastDate
        |> Option.map (fun date -> sprintf "%s (%s)" (dayMonthToString date) (timeToString date))
        |> Option.map Tooltip.dataTooltip

    let lastDateCellProps =
        [
            classList [
                (Tooltip.ClassName, (Option.isSome lastDateTooltip))
                ("last_date", true)
            ]
            :> IHTMLProp
            |> Some
            Option.map (fun date -> date :> IHTMLProp) lastDateTooltip
        ]
        |> List.choose id

    tr [] [
        td [] [ Table.rowIndexString index ]
        td [] [ str gateway.Value.Value.TtnId ]
        td [] [ str gateway.Value.Value.Eui ]
        td [] [ str gateway.Value.Value.Name ]
        td [] [
            str (gateway.Value.Value.Description |> Option.defaultValue "")
        ]
        td [] [
            str (maybeFloatToString gateway.Value.Value.Latitude)
        ]
        td [] [
            str (maybeFloatToString gateway.Value.Value.Longitude)
        ]
        td lastDateCellProps [ str lastDateString ]
        td [] [
            Option.map findUser gateway.Value.Value.UserId
            |> Option.flatten
            |> Option.map (fun user -> getFullName user.Value)
            |> Option.defaultValue ""
            |> str
        ]
        td [] [
            Button.button [
                Button.OnClick(fun _ -> dispatch (GatewayListMsg.OpenModal gateway.Value |> GatewayList))
            ] [ str "Bearbeiten" ]
        ]
    ]

let gatewayListToTable
    dispatch
    (gateways: WithOptionalLastDate<IdValue<MySensGateway>> list)
    (users: IdValue<UserDto> list)
    =
    Table.table [
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
    ] [
        thead [] [
            tr [] [
                th [] [ str "#" ]
                th [] [ str "TTN Id" ]
                th [] [ str "EUI" ]
                th [] [ str "Name" ]
                th [] [ str "Beschreibung" ]
                th [] [ str "Breitengrad" ]
                th [] [ str "Längengrad" ]
                th [ classList [ ("last_date", true) ] ] [ str "Letzte Aktual." ]
                th [] [ str "Zugeordneter Benutzer" ]
                th [] []
            ]
        ]
        tbody
            []
            (gateways
             |> List.sortBy (fun gw -> gw.Value.Value.Name)
             |> List.mapi (gatewayToRow dispatch users))
    ]

let refreshDevicesTTIButton isRefreshRequestRunning dispatch =
    Button.button [
        Button.IsLoading isRefreshRequestRunning
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (GatewayList GatewayListMsg.RefreshGatewaysTTI))
    ] [ str "Gateways aus TTI aktualisieren" ]

let tableHeader dispatch model =
    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [
                Field.div [] [
                    Control.div [] [
                        refreshDevicesTTIButton model.TTIRefreshRequestRunning dispatch
                    ]
                ]
            ]
        ]
    ]

let private dataView dispatch (data: DataModel) =
    let modal =
        data.Modal
        |> Option.map (Gateway.view dispatch)
        |> Option.defaultValue (div [] [])

    [
        Heading.h1 [] [ str "Hitzekarte Klar - Gateway Liste" ]
        tableHeader dispatch data
        gatewayListToTable dispatch data.Gateways data.Users
        modal
    ]
    |> Container.container [ Container.IsFluid ]
    |> (fun content -> div [] [ content; PageSkeleton.mySensFooter ])

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model