module Client.Forms.MySensSensor

open Browser.Types
open Browser.Navigator
open Client
open Client.Domain
open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fulma
open Shared
open Shared.Dto.Dto
open Fable.React
open Fable.React.Props
open Shared.Dto.User
open Thoth.Elmish

type SensorData = {
    Id: int option
    Name: string option
    Latitude: double option
    Longitude: double option
    IsPublic: bool
    IsDisabled: bool
    SensorType: SensorType
    TtnSensorSelected: ConfiguredTtnSensor option
    UserSelected: IdValue<UserDto> option
}

type Model = {
    TtnSensors: ConfiguredTtnSensor list
    Sensor: SensorData
    Users: IdValue<UserDto> list
    RequestRunning: bool
    RetrieveCurrentLocationRunning: bool
}

let init (sensors: ConfiguredTtnSensor list) (users: IdValue<UserDto> list) (maybeSensor: MySensSensor option) =
    let sensorModel =
        match maybeSensor with
        | Some sensor ->
            let ttnSensor =
                Option.map
                    (fun eui -> List.tryFind (fun ttnSensor -> ttnSensor.BaseData.DeviceEui = eui) sensors)
                    sensor.TtnSensorId
                |> Option.flatten

            let user =
                Option.map (fun userId -> List.tryFind (fun (user: IdValue<_>) -> user.Id = userId) users) sensor.UserId
                |> Option.flatten

            {
                Id = Some sensor.Id
                Name = Some sensor.Name
                Latitude = Some sensor.Latitude
                Longitude = Some sensor.Longitude
                IsPublic = sensor.IsPublic
                IsDisabled = sensor.IsDisabled
                SensorType = sensor.SensorType
                TtnSensorSelected = ttnSensor
                UserSelected = user
            }
        | None -> {
            Id = None
            Name = None
            Latitude = None
            Longitude = None
            IsPublic = true
            IsDisabled = false
            SensorType = SensorType.Air
            TtnSensorSelected = None
            UserSelected = None
          }

    {
        Sensor = sensorModel
        TtnSensors = sensors
        RequestRunning = false
        RetrieveCurrentLocationRunning = false
        Users = users
    }

let private sensorTypeToString (type_: SensorType) =
    match type_ with
    | SensorType.Air -> "Luft"
    | SensorType.Soil -> "Boden"
    | SensorType.RainFall -> "Regenmenge"
    | SensorType.LeafletMoisture -> "Blattnässe"
    | SensorType.PH -> "PH"
    | SensorType.WindAverage -> "Wind Durchschnitt"

let private sensorTypeFromString (type_: string) =
    match type_ with
    | "Luft" -> SensorType.Air
    | "Boden" -> SensorType.Soil
    | "Regenmenge" -> SensorType.RainFall
    | "Blattnässe" -> SensorType.LeafletMoisture
    | "PH" -> SensorType.PH
    | "Wind Durchschnitt" -> SensorType.WindAverage
    | _ -> failwithf "Unknown Sensor Type '%s'" type_

let sensorTypeToOption (type_: SensorType) =
    let sensorType = sensorTypeToString type_

    option [ Value sensorType ] [ str sensorType ]

let sensorTypeSelect (isExisting: bool) (selected: SensorType) dispatch =
    let types = [
        SensorType.Air
        SensorType.Soil
        SensorType.RainFall
        SensorType.LeafletMoisture
        SensorType.PH
        SensorType.WindAverage
    ]

    let options = List.map sensorTypeToOption types

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue(sensorTypeToString selected)
                Disabled isExisting
                OnChange(fun event ->
                    dispatch (
                        sensorTypeFromString event.Value
                        |> MySensSensorListMsg.SelectedSensorTypeUpdated
                        |> MySensSensorList
                    )
                )
            ]
            options
    ]

let ttnSensorToOption (sensor: ConfiguredTtnSensor) =
    option [ Value sensor.BaseData.DeviceEui ] [ str sensor.BaseData.Name ]

let ttnSensorSelect (selected: ConfiguredTtnSensor option) (list: ConfiguredTtnSensor list) dispatch =
    let options = option [ Value "" ] [ str "-" ] :: List.map ttnSensorToOption list

    let findSensor =
        fun selectedDeviceEui ->
            List.tryFind (fun (sensor: ConfiguredTtnSensor) -> sensor.BaseData.DeviceEui = selectedDeviceEui) list

    let selectedValue =
        Option.map (fun sensor -> sensor.BaseData.DeviceEui) selected
        |> Option.defaultValue ""

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue selectedValue
                OnChange(fun event ->
                    dispatch (
                        findSensor event.Value
                        |> MySensSensorListMsg.SelectedSensorUpdated
                        |> MySensSensorList
                    )
                )
            ]
            options
    ]

let userToOption (user: IdValue<UserDto>) =
    option [ Value user.Id ] [ str (getFullName user.Value) ]

let userSelect (selected: IdValue<UserDto> option) (list: IdValue<UserDto> list) dispatch =
    let options = option [ Value "" ] [ str "-" ] :: List.map userToOption list

    let findUser =
        fun (maybeId: string) ->
            Int.tryParseString maybeId
            |> Option.map (fun userId -> List.tryFind (fun (user: IdValue<_>) -> user.Id = userId) list)
            |> Option.flatten

    let selectedValue =
        Option.map (fun (user: IdValue<_>) -> user.Id.ToString()) selected
        |> Option.defaultValue ""

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue selectedValue
                OnChange(fun event ->
                    dispatch (
                        findUser event.Value
                        |> MySensSensorListMsg.SelectedUserUpdated
                        |> MySensSensorList
                    )
                )
            ]
            options
    ]

let private locationSuccessCallback dispatch (location: Position) =
    MySensSensorListMsg.CurrentLocationReceived location
    |> MySensSensorList
    |> dispatch

let private locationErrorCallback dispatch (error: PositionError) =
    MySensSensorListMsg.CurrentLocationFailed error |> MySensSensorList |> dispatch

let private deviceLocationOnClick dispatch geolocation =
    DeviceLocation.request (locationSuccessCallback dispatch) (locationErrorCallback dispatch) geolocation

let form dispatch (model: Model) =
    form [] [
        Field.div [] [
            Label.label [] [ str "Name" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder "MS-TL0815"
                    model.Sensor.Name |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> MySensSensorListMsg.SensorNameUpdated
                        |> MySensSensorList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Breitengrad" ]
            Control.div [] [
                Input.number [
                    Input.Placeholder "47.155999194351715"
                    model.Sensor.Latitude |> Inputs.optionalDoubleToString |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> Inputs.toDoubleOption
                        |> MySensSensorListMsg.LatitudeUpdated
                        |> MySensSensorList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Längengrad" ]
            Control.div [] [
                Input.number [
                    Input.Placeholder "15.649259567260742"
                    model.Sensor.Longitude |> Inputs.optionalDoubleToString |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> Inputs.toDoubleOption
                        |> MySensSensorListMsg.LongitudeUpdated
                        |> MySensSensorList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Control.div [] [
                Button.button [
                    Button.IsLoading model.RetrieveCurrentLocationRunning
                    Button.Color Color.IsLink
                    match navigator.geolocation with
                    | Some geolocation ->
                        Button.OnClick(fun event ->
                            event.preventDefault ()
                            dispatch (MySensSensorList MySensSensorListMsg.CurrentLocationRequested)
                            deviceLocationOnClick dispatch geolocation
                        )
                    | None -> Button.Disabled true
                ] [ str "Aktuellen Standort übernehmen" ]
            ]
        ]
        Field.div [] [
            Checkbox.checkbox [] [
                Checkbox.input [
                    Props [
                        Checked model.Sensor.IsPublic
                        OnChange(fun _ ->
                            not model.Sensor.IsPublic
                            |> MySensSensorListMsg.IsPublicUpdated
                            |> MySensSensorList
                            |> dispatch
                        )
                    ]
                ]
                str " Sensor öffentlich sichtbar"
            ]
        ]

        Field.div [] [
            Checkbox.checkbox [] [
                Checkbox.input [
                    Props [
                        Checked model.Sensor.IsDisabled
                        OnChange(fun _ ->
                            not model.Sensor.IsDisabled
                            |> MySensSensorListMsg.IsDisabledUpdated
                            |> MySensSensorList
                            |> dispatch
                        )
                    ]
                ]
                str " Sensor ist deaktiviert"
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Sensor Typ" ]
            Control.div [ Control.IsExpanded ] [
                sensorTypeSelect (Option.isSome model.Sensor.Id) model.Sensor.SensorType dispatch
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Physikalischer Sensor" ]
            Control.div [ Control.IsExpanded ] [
                ttnSensorSelect model.Sensor.TtnSensorSelected model.TtnSensors dispatch
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Benutzer" ]
            Control.div [ Control.IsExpanded ] [
                userSelect model.Sensor.UserSelected model.Users dispatch
            ]
        ]
    ]

let createUpdateSensorMsg
    id
    maybeName
    maybeLatitude
    maybeLongitude
    isPublic
    isDisabled
    sensorType
    selectedTtnSensor
    selectedUser
    =
    Option.map3
        (fun name latitude longitude -> {
            Id = id
            Name = name
            Latitude = latitude
            Longitude = longitude
            IsPublic = isPublic
            IsDisabled = isDisabled
            SensorType = sensorType
            TtnSensorId = Option.map (fun selected -> selected.BaseData.DeviceEui) selectedTtnSensor
            UserId = Option.map (fun (selected: IdValue<_>) -> selected.Id) selectedUser
        })
        maybeName
        maybeLatitude
        maybeLongitude
    |> Option.map MySensSensorListMsg.UpdateSensor

let createCreateSensorMsg
    maybeName
    maybeLatitude
    maybeLongitude
    isPublic
    isDisabled
    sensorType
    selectedTtnSensor
    selectedUser
    =
    Option.map3
        (fun name latitude longitude -> {
            Name = name
            Latitude = latitude
            Longitude = longitude
            IsPublic = isPublic
            IsDisabled = isDisabled
            SensorType = sensorType
            TtnSensorId = Option.map (fun selected -> selected.BaseData.DeviceEui) selectedTtnSensor
            UserId = Option.map (fun (selected: IdValue<_>) -> selected.Id) selectedUser
        })
        maybeName
        maybeLatitude
        maybeLongitude
    |> Option.map MySensSensorListMsg.CreateSensor

let saveButton dispatch (model: Model) =
    let maybeOnClick =
        match model.Sensor.Id with
        | Some id ->
            createUpdateSensorMsg
                id
                model.Sensor.Name
                model.Sensor.Latitude
                model.Sensor.Longitude
                model.Sensor.IsPublic
                model.Sensor.IsDisabled
                model.Sensor.SensorType
                model.Sensor.TtnSensorSelected
                model.Sensor.UserSelected
        | None ->
            createCreateSensorMsg
                model.Sensor.Name
                model.Sensor.Latitude
                model.Sensor.Longitude
                model.Sensor.IsPublic
                model.Sensor.IsDisabled
                model.Sensor.SensorType
                model.Sensor.TtnSensorSelected
                model.Sensor.UserSelected
        |> Option.map (fun msg -> Button.OnClick(fun _ -> dispatch (MySensSensorList msg)))

    let buttonOptions = [
        Button.IsLoading model.RequestRunning
        Button.Color IsSuccess
        Button.Disabled(Option.isNone maybeOnClick)
    ]

    Button.button (Lists.addToListIfSome buttonOptions maybeOnClick) [ str "Speichern" ]

let view dispatch (model: Model) =
    let closeModal =
        (fun _ -> dispatch (MySensSensorListMsg.CloseModal |> MySensSensorList))

    let headline =
        if Option.isSome model.Sensor.Id then
            sprintf "Karten Sensor '%s' bearbeiten" (Option.defaultValue "" model.Sensor.Name)
        else
            sprintf "Neuen Sensor erstellen"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [ Props [ OnClick closeModal ] ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
            Modal.Card.foot [] [ saveButton dispatch model ]
        ]
    ]

let update (msg: MySensSensorListMsg) (model: Model) =
    match msg with
    | MySensSensorListMsg.SelectedSensorUpdated selectedSensor ->
        let newSensor = {
            model.Sensor with
                TtnSensorSelected = selectedSensor
        }

        { model with Sensor = newSensor }, Cmd.none
    | MySensSensorListMsg.SelectedUserUpdated selectedUser ->
        let newSensor = {
            model.Sensor with
                UserSelected = selectedUser
        }

        { model with Sensor = newSensor }, Cmd.none
    | MySensSensorListMsg.SensorNameUpdated name ->
        let newSensor = { model.Sensor with Name = name }

        { model with Sensor = newSensor }, Cmd.none
    | MySensSensorListMsg.IsPublicUpdated isPublic ->
        let newSensor = {
            model.Sensor with
                IsPublic = isPublic
        }

        { model with Sensor = newSensor }, Cmd.none
    | MySensSensorListMsg.IsDisabledUpdated isDisabled ->
        let newSensor = {
            model.Sensor with
                IsDisabled = isDisabled
        }

        { model with Sensor = newSensor }, Cmd.none
    | MySensSensorListMsg.LatitudeUpdated latitude ->
        let newSensor = {
            model.Sensor with
                Latitude = latitude
        }

        { model with Sensor = newSensor }, Cmd.none
    | MySensSensorListMsg.LongitudeUpdated longitude ->
        let newSensor = {
            model.Sensor with
                Longitude = longitude
        }

        { model with Sensor = newSensor }, Cmd.none
    | MySensSensorListMsg.SelectedSensorTypeUpdated sensorType ->
        let newSensor = {
            model.Sensor with
                SensorType = sensorType
        }

        { model with Sensor = newSensor }, Cmd.none
    | MySensSensorListMsg.CurrentLocationRequested ->
        {
            model with
                RetrieveCurrentLocationRunning = true
        },
        Cmd.none
    | MySensSensorListMsg.CurrentLocationFailed error ->
        let toast =
            Toast.create "Beim Auslesen der GPS Koordinaten ist ein Fehler aufgetreten"
            |> Toast.error

        {
            model with
                RetrieveCurrentLocationRunning = false
        },
        toast
    | MySensSensorListMsg.CurrentLocationReceived position ->
        let newSensor = {
            model.Sensor with
                Latitude = Some position.coords.latitude
                Longitude = Some position.coords.longitude
        }

        let toastCmd =
            Toast.create "GPS Koordinaten erfolgreich übernommen" |> Toast.success

        {
            model with
                Sensor = newSensor
                RetrieveCurrentLocationRunning = false
        },
        toastCmd
    | _ -> model, Cmd.none