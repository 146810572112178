module Client.PublicMap

open System
open Client
open Client.Components
open Client.InfrastructureTypes
open Client.Routing
open Elmish
open Fable.FontAwesome
open Fable.Core
open Fable.Core.JsInterop
open Fable.React.Props
open Fable.React
open Leaflet
open ReactLeaflet
open Api
open Client.Msg
open Shared.Dto.MapSensorData
open Thoth.Elmish

[<ImportAll("../icon.js")>]
let iconFactory: IconFactory = jsNative

let latLngToExpression (latLng: LatLng) : LatLngExpression =
    Fable.Core.Case3(latLng.lat, latLng.lng)

type Model = {
    Sensors: MapSensor list
    LastUpdate: DateTime option
    MapZoomLevel: float
}

let private airDataToPopup sensorId dispatch (data: AirSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Temperatur" (temperatureToString data.AirTemperature)
    popupDataRow "Rel. Luftfeuchte" (sprintf " %.2f %%" data.AirHumidity)
    popupDataRow
        "Gefühlte Temperatur"
        (data.ApparentTemperature
         |> Option.map temperatureToString
         |> Option.defaultValue "-")
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    aggregatedMinMaxTemperaturePopup data.MinAirTemperature data.MaxAirTemperature
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupIconRow
        Fa.Solid.ChartLine
        "Temperaturverlauf"
        (fun event ->
            event.preventDefault ()
            GoToRoute(Route.PublicHistory sensorId) |> Global |> dispatch
        )
    popupIconRow
        Fa.Solid.PhotoVideo
        "Fotos"
        (fun event ->
            event.preventDefault ()
            GoToRoute(Route.SensorPictures sensorId) |> Global |> dispatch
        )
]

let private groundDataToPopup (data: GroundSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Rel. Bodenfeuchte" (sprintf " %.2f %%" data.GroundHumidity)
    popupDataRow "Bodentemperatur" (temperatureToString data.GroundTemperature)
    popupDataRow "Leitfähigkeit" (conductivityToString data.SoilConductivity)
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    aggregatedMinMaxHumidityPopup data.MinGroundHumidity data.MaxGroundHumidity
]

let private rainFallDataToPopup (data: RainFallSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Regen aktuell" (rainFallToString data.CurrentRainFall)
    popupDataRow "Regen letzte Stunde" (rainFallToString data.RainFallLastHour)
    popupDataRow "Regen Heute" (rainFallToString data.RainFallToday)
]

let private leafletMoistureDataToPopup (data: LeafletMoistureSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Blattnässe" (percentageToString data.LeafletMoisture)
    popupDataRow "Blatttemperatur" (temperatureToString data.LeafletTemperature)
]

let private soilPhDataToPopup (data: SoilPhSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "PH-Wert" (sprintf "%.2f" data.SoilPh)
    popupDataRow "Bodentemperatur" (temperatureToString data.SoilTemperature)
]

let private averageWindSpeedDataToPopup (data: AverageWindSensorData) = [
    popupUpdateRow data.Date
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Ø Windgeschwindigkeit" (windSpeedToString data.AverageWindSpeed)
]

let sensorDataToPopup sensorId dispatch (data: MapSensorData) =
    match data with
    | NoDataAvailable -> noSensorDataToolTip
    | NoPublicData -> noPublicSensorDataPopup
    | AirData airData -> airDataToPopup sensorId dispatch airData
    | GroundData groundData -> groundDataToPopup groundData
    | RainFallData rainFallData -> rainFallDataToPopup rainFallData
    | LeafletMoistureData sensorData -> leafletMoistureDataToPopup sensorData
    | SoilPhData sensorData -> soilPhDataToPopup sensorData
    | AverageWindSpeedData sensorData -> averageWindSpeedDataToPopup sensorData

let makeMarkerPopup (sensor: MapSensor) dispatch =
    let rows =
        makeMarkerPopupHeader sensor dispatch
        :: (sensorDataToPopup sensor.Id dispatch sensor.Data)

    table [ Style [ Width "100%" ] ] [ tbody [] rows ]

let onZoom (dispatch: PublicMapMsg -> Unit) (map: Leaflet.Map) =
    dispatch (map.getZoom () |> ZoomChanged)

let view (model: Model) (dispatch: Msg -> unit) =
    let markers =
        model.Sensors
        |> List.map (fun sensor ->
            let position = makePosition sensor.Latitude sensor.Longitude

            let markerPopup = makeMarkerPopup sensor dispatch

            sensor.Data
            |> onlyAirData
            |> Option.map (fun airData -> airData.AirTemperature)
            |> Option.map (Some >> createCircleMarker position 50. markerPopup)
        )
        |> List.choose id

    div [ Style [ Position PositionOptions.Relative ] ] [
        map
            [
                MapProps.Center(LatLngExpression.Case3(47.476275, 9.725183))
                MapProps.MaxZoom 18.
                MapProps.MinZoom 10.0
                MapProps.Zoom model.MapZoomLevel
                MapProps.Style [ Height "100vh"; ZIndex 90 ]
                MapProps.OnZoomEnd(getMapFromEvent >> onZoom (PublicMap >> dispatch))
            ]
            (tileLayer [
                TileLayerProps.Url "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
             ] []
             :: markers)

        match model.MapZoomLevel with
        | zoom when zoom <= 14. ->
            img [
                Class "mobile-map-logo"
                Style [ ZIndex 99 ]
                Src "/images/LOGO_Kombination_KLAR_powered_by_Klimafonds.png"
            ]

            div [ Class "mobile-map-legend"; Style [ ZIndex 99 ] ] [
                img [ Src "/images/Farbskala_ZAMG.jpg" ]
                p [
                    Style [
                        Padding "5px"
                        BackgroundColor "#f5f5f5"
                        TextAlign TextAlignOptions.Center
                    ]
                ] [
                    a [
                        OnClick(fun event ->
                            event.preventDefault ()
                            Route.SiteNotice |> GoToRoute |> Global |> dispatch
                        )
                    ] [ str "Impressum" ]
                ]
            ]
        | _ -> ()

        div [
            Class "desktop-map-legend"
            Style [ ZIndex 99 ]
        ] [
            p [
                Style [
                    Padding "5px"
                    BackgroundColor "#f5f5f5"
                    TextAlign TextAlignOptions.Center
                ]
            ] [
                a [
                    OnClick(fun event ->
                        event.preventDefault ()
                        Route.SiteNotice |> GoToRoute |> Global |> dispatch
                    )
                ] [ str "Impressum" ]
            ]

            div [
                Style [
                    Display DisplayOptions.Flex
                    FlexDirection "row-reverse"
                ]
            ] [
                img [
                    Src "/images/LOGO_Kombination_KLAR_powered_by_Klimafonds.png"
                ]
                img [ Src "/images/Farbskala_ZAMG.jpg" ]
            ]
        ]
    ]

let init =
    let model = {
        Sensors = []
        LastUpdate = None
        MapZoomLevel = 14.
    }

    let cmd =
        Cmd.OfAsync.either api.getPublicSensors () PublicMapMsg.GotSensors PublicMapMsg.SensorLoadingFailed

    model, cmd

let update (msg: PublicMapMsg) (model: Model) =
    match msg with
    | PublicMapMsg.GotSensors sensors ->
        let dates =
            sensors
            |> List.map (fun sensor -> getSensorDataDate sensor.Data)
            |> List.choose id

        let latestDate =
            match dates with
            | x :: _ -> Some(List.max dates)
            | _ -> None

        {
            model with
                Sensors = sensors
                LastUpdate = latestDate
        },
        Cmd.none
    | PublicMapMsg.SensorLoadingFailed ex ->
        let statusCode = Exceptions.getStatusCode ex

        let message =
            match statusCode with
            | 500 -> "Interner Server Fehler ist aufgetreten. Bitte wende dich an den Administrator"
            | _ -> sprintf "Fehlermeldung: '%s'. Bitte wende dich an den Administrator" ex.Message

        let toastCmd =
            Toast.create message
            |> Toast.errorTitle statusCode
            |> Toast.timeout (TimeSpan.FromSeconds 10.0)
            |> Toast.error

        model, toastCmd
    | PublicMapMsg.ZoomChanged zoom -> { model with MapZoomLevel = zoom }, Cmd.none