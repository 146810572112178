module Client.Page.MySensSensorList

open Client
open Client.InfrastructureTypes
open Client.Api
open Client.Msg

open Client.Forms
open Client.Views
open Client.DateTime
open Elmish
open Fable.FontAwesome
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Leaflet
open Shared
open Shared.Dto.Dto
open Shared.Infrastructure
open Fable.React
open Shared.Dto.User
open Shared.WithLastDate
open Thoth.Elmish
open System
open Zanaptak.TypedCssClasses

Fable.Core.JsInterop.importAll "bulma-tooltip"

type FA =
    CssClasses<"https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css", Naming.PascalCase>

type DataModel = {
    MySensSensors: WithOptionalLastDate<MySensSensor> list
    TtnSensors: ConfiguredTtnSensor list
    Users: IdValue<UserDto> list
    Modal: MySensSensor.Model option
    Session: UserSession
}

type LoadingModel = {
    Session: UserSession
    MySensSensors: WithOptionalLastDate<MySensSensor> list option
    TtnSensors: ConfiguredTtnSensor list option
    Users: IdValue<UserDto> list option
}

let modelLoadingToData (model: LoadingModel) : DataModel option =
    Option.map3
        (fun ttn mysens users -> {
            MySensSensors = mysens
            Session = model.Session
            TtnSensors = ttn
            Users = users
            Modal = None
        })
        model.TtnSensors
        model.MySensSensors
        model.Users

type Model = Loadable<DataModel, LoadingModel>

let init (session: UserSession) =
    let requestData = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmds =
        Cmd.batch [
            Cmd.OfAsync.perform api.getConfiguredTtnSensors () (MySensSensorListMsg.TtnListReceived >> MySensSensorList)
            Cmd.OfAsync.perform
                api.getAllMySensSensorsWithOptionalLastDate
                ()
                (MySensSensorListMsg.MySensListReceived >> MySensSensorList)
            Cmd.OfAsync.perform api.getAllUsers requestData (MySensSensorListMsg.UsersReceived >> MySensSensorList)
        ]

    Loadable.Loading {
        MySensSensors = None
        Session = session
        TtnSensors = None
        Users = None
    },
    cmds

let updateRequestRunningModalState (model: DataModel) (requestRunning: bool) =
    let modalModel = Option.get model.Modal

    {
        model with
            Modal =
                Some {
                    modalModel with
                        RequestRunning = requestRunning
                }
    }

let getSortKey (sensor: WithOptionalLastDate<MySensSensor>) = sensor.Value.Name

let update (msg: MySensSensorListMsg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | MySensSensorListMsg.TtnListReceived sensors, Loadable.Loading loadingModel ->
        let updatedLoadingModel = {
            loadingModel with
                TtnSensors = Some sensors
        }

        let newModel =
            modelLoadingToData updatedLoadingModel
            |> Option.map Loadable.Data
            |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

        newModel, Cmd.none
    | MySensSensorListMsg.MySensListReceived sensors, Loadable.Loading loadingModel ->
        let updatedLoadingModel = {
            loadingModel with
                MySensSensors = Some(List.sortBy getSortKey sensors)
        }

        let newModel =
            modelLoadingToData updatedLoadingModel
            |> Option.map Loadable.Data
            |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

        newModel, Cmd.none
    | MySensSensorListMsg.UsersReceived maybeUsers, Loadable.Loading loadingModel ->
        match maybeUsers with
        | Result.Ok users ->
            let updatedLoadingModel = { loadingModel with Users = Some users }

            let newModel =
                modelLoadingToData updatedLoadingModel
                |> Option.map Loadable.Data
                |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

            newModel, Cmd.none
        | Result.Error msg ->
            let errorMessage =
                match msg with
                | AuthErr Unauthenticated -> "Sie sind nicht angemeldet? Laden Sie die Seite neu"
                | AuthErr Unauthorized -> "Sie düfen nicht die Kartensensoren auflisten"
                | CustomErr error -> error

            let toastCmd =
                Toast.create errorMessage |> Toast.title "Fehler beim User laden" |> Toast.error

            model, toastCmd

    | MySensSensorListMsg.CloseModal, Loadable.Data data -> Loadable.Data { data with Modal = None }, Cmd.none
    | MySensSensorListMsg.OpenModal sensor, Loadable.Data data ->
        Loadable.Data {
            data with
                Modal = Some(MySensSensor.init data.TtnSensors data.Users sensor)
        },
        Cmd.none
    | MySensSensorListMsg.SensorUpdated success, Loadable.Data data ->
        if success then
            let toastCmd =
                Toast.create "Der Sensor wurde erfolgreich gespeichert" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        else
            let toastCmd = Toast.create "Das Speichern ist fehlgeschlagen" |> Toast.error
            Loadable.Data(updateRequestRunningModalState data false), toastCmd
    | MySensSensorListMsg.UpdateSensor sensor, Loadable.Data data ->
        Loadable.Data(updateRequestRunningModalState data true),
        Cmd.OfAsync.perform api.updateMySensSensor sensor (MySensSensorListMsg.SensorUpdated >> MySensSensorList)
    | MySensSensorListMsg.CreateSensor newSensor, Loadable.Data data ->
        Loadable.Data(updateRequestRunningModalState data true),
        Cmd.OfAsync.perform api.createMySensSensor newSensor (MySensSensorListMsg.SensorUpdated >> MySensSensorList)
    | MySensSensorListMsg.DeleteSensor id, _ ->
        model, Cmd.OfAsync.perform api.deleteMySensSensor id (MySensSensorListMsg.SensorDeleted >> MySensSensorList)
    | MySensSensorListMsg.SensorDeleted success, Loadable.Data data ->
        if success then
            let toastCmd = Toast.create "Sensor erfolgreich gelöscht" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        else
            let toastCmd = Toast.create "Fehler beim Löschen des Sensors" |> Toast.error

            model, toastCmd
    | _, Loadable.Data data ->
        let result =
            match data.Modal with
            | Some modal -> MySensSensor.update msg modal |> (fun (modal, cmd) -> Some modal, cmd)
            | None -> None, Cmd.none

        Loadable.Data { data with Modal = fst result }, snd result
    | _, _ -> model, Cmd.none

let private getTotalMinutesLimit (sensorType: SensorModel) =
    match sensorType with
    | LHT65
    | LSN50v2_S31
    | LSN50v2_Rain
    | LSN50v2_Wind -> 30.0
    | LSE01
    | LLMS01
    | LSPH01 -> 50.0

let getIconForSensor (maybeTtnSensor: ConfiguredTtnSensor option) (sensor: WithOptionalLastDate<MySensSensor>) =
    match maybeTtnSensor with
    | Some ttnSensor ->
        let totalTimeLimit = getTotalMinutesLimit ttnSensor.Configuration

        let isOldAge =
            match sensor.LastDate with
            | None -> true
            | Some lastDate ->
                let elapsedMinutes = (DateTime.UtcNow - lastDate).TotalMinutes

                elapsedMinutes > totalTimeLimit

        match sensor.Value.IsDisabled, isOldAge with
        | true, _ ->
            td [
                classList [ (Tooltip.ClassName, true) ]
                Tooltip.dataTooltip "Sensor ist deaktiviert"
            ] [ Fa.span [ Fa.Solid.Ban ] [] ]
        | false, true -> td [] [ Fa.span [ Fa.Solid.ExclamationCircle ] [] ]
        | false, false -> td [] [ Fa.span [ Fa.Solid.Check ] [] ]
    | None ->
        td [
            classList [ (Tooltip.ClassName, true) ]
            Tooltip.dataTooltip "Kein physikalischer Sensor zugeordnet"
        ] [ Fa.span [ Fa.Solid.Ban ] [] ]

let sensorToRow
    dispatch
    (ttnSensors: ConfiguredTtnSensor list)
    (users: IdValue<UserDto> list)
    (index: int)
    (sensor: WithOptionalLastDate<MySensSensor>)
    =
    let findSensor =
        fun selectedDeviceEui ->
            List.tryFind (fun (sensor: ConfiguredTtnSensor) -> sensor.BaseData.DeviceEui = selectedDeviceEui) ttnSensors

    let ttnSensor = Option.map findSensor sensor.Value.TtnSensorId |> Option.flatten

    let findUser =
        fun userId -> List.tryFind (fun (user: IdValue<_>) -> user.Id = userId) users

    let icon = getIconForSensor ttnSensor sensor

    let lastDateString =
        sensor.LastDate
        |> Option.map TimeSpan.createTimeSinceString
        |> Option.defaultValue ""

    let lastDateTooltip =
        sensor.LastDate
        |> Option.map (fun date -> sprintf "%s (%s)" (dayMonthToString date) (timeToString date))
        |> Option.map Tooltip.dataTooltip

    let lastDateCellProps =
        [
            classList [
                (Tooltip.ClassName, (Option.isSome lastDateTooltip))
                ("last_date", true)
            ]
            :> IHTMLProp
            |> Some
            Option.map (fun date -> date :> IHTMLProp) lastDateTooltip
        ]
        |> List.choose id

    let position = LatLngExpression.Case3(sensor.Value.Latitude, sensor.Value.Longitude)

    tr [] [
        td [] [ Table.rowIndexString index ]
        icon
        td [] [ str sensor.Value.Name ]
        td [] [ str (sensor.Value.Latitude.ToString()) ]
        td [] [ str (sensor.Value.Longitude.ToString()) ]
        td [] [
            str (Boolean.toUserString sensor.Value.IsPublic)
        ]
        td lastDateCellProps [ str lastDateString ]
        td [] [
            ttnSensor
            |> Option.map (fun ttnSensor -> ttnSensor.BaseData.Name)
            |> Option.defaultValue ""
            |> str
        ]
        td [] [
            Option.map findUser sensor.Value.UserId
            |> Option.flatten
            |> Option.map (fun user -> getFullName user.Value)
            |> Option.defaultValue ""
            |> str
        ]
        td [] [
            Dropdown.dropdown [ Dropdown.IsHoverable; Dropdown.IsRight ] [
                Dropdown.trigger [] [
                    Button.button [] [
                        Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Cog ] [] ]
                    ]
                ]
                Dropdown.menu [] [
                    Dropdown.content [] [
                        Dropdown.Item.a [
                            Dropdown.Item.Props [
                                Props.OnClick(fun _ ->
                                    dispatch (MySensSensorListMsg.OpenModal(Some sensor.Value) |> MySensSensorList)
                                )
                            ]
                        ] [
                            Icon.icon [ Icon.Size IsMedium ] [ Fa.i [ Fa.Solid.Edit ] [] ]

                            span [] [ str "Bearbeiten" ]
                        ]
                        Dropdown.Item.a [
                            Dropdown.Item.Props [
                                Props.OnClick(fun _ ->
                                    GlobalMsg.GoToRoute(Routing.UserDefinedMapSensorProperties sensor.Value.Id)
                                    |> Global
                                    |> dispatch
                                )
                            ]
                        ] [
                            Icon.icon [ Icon.Size IsMedium ] [ Fa.i [ Fa.Solid.Edit ] [] ]

                            span [] [ str "Fotos bearbeiten" ]
                        ]
                        Dropdown.Item.a [
                            Dropdown.Item.Props [
                                Props.OnClick(fun _ ->
                                    dispatch (GlobalMsg.GoToRoute(Routing.MySensSensor sensor.Value.Id) |> Global)
                                )
                            ]
                        ] [
                            Icon.icon [ Icon.Size IsMedium ] [ Fa.i [ Fa.Solid.Search ] [] ]

                            span [] [ str "Detail-Seite" ]
                        ]
                        Dropdown.Item.a [
                            Dropdown.Item.Props [
                                Props.OnClick(fun _ ->
                                    dispatch (GlobalMsg.GoToRoute(Routing.SensorMap(Some position)) |> Global)
                                )
                            ]
                        ] [
                            Icon.icon [ Icon.Size IsMedium ] [ Fa.i [ Fa.Solid.Globe ] [] ]

                            span [] [ str "Zur Karte" ]
                        ]
                        Dropdown.Item.a [
                            Dropdown.Item.Props [
                                Props.OnClick(fun _ ->
                                    dispatch (MySensSensorListMsg.DeleteSensor sensor.Value.Id |> MySensSensorList)
                                )
                            ]
                        ] [
                            Icon.icon [ Icon.Size IsMedium ] [ Fa.i [ Fa.Solid.TrashAlt ] [] ]

                            span [] [ str "Löschen" ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let sensorListToTable
    dispatch
    (ttnSensors: ConfiguredTtnSensor list)
    (users: IdValue<UserDto> list)
    (sensors: WithOptionalLastDate<MySensSensor> list)
    =
    Table.table [
        Table.CustomClass "table-auto"
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
        Table.Modifiers [
            Modifier.Spacing(Spacing.MarginBottom, Spacing.Is6)
        ]
    ] [
        thead [] [
            tr [] [
                th [] [ str "#" ]
                th [] []
                th [] [ str "Name" ]
                th [] [ str "Breitengrad" ]
                th [] [ str "Längengrad" ]
                th [] [ str "Sensor ist öffentlich" ]
                th [ classList [ ("last_date", true) ] ] [ str "Letzte Aktual." ]
                th [] [ str "Physikalischer Sensor" ]
                th [] [ str "Benutzer" ]
                th [] []
            ]
        ]
        tbody [] (List.mapi (sensorToRow dispatch ttnSensors users) sensors)
    ]

let createNewSensorButton dispatch =
    Button.button [
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (MySensSensorList <| MySensSensorListMsg.OpenModal None))
    ] [ str "Neuen Sensor erstellen" ]

let tableHeader dispatch =
    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [
                Field.div [] [
                    Control.div [] [ createNewSensorButton dispatch ]
                ]
            ]
        ]
    ]

let private dataView dispatch (data: DataModel) =
    let modal =
        data.Modal
        |> Option.map (MySensSensor.view dispatch)
        |> Option.defaultValue (div [] [])

    [
        Heading.h1 [] [ str "Karten Sensoren Liste" ]
        tableHeader dispatch
        sensorListToTable dispatch data.TtnSensors data.Users data.MySensSensors
        modal
    ]
    |> Container.container [ Container.IsFluid ]
    |> (fun content -> div [] [ content; PageSkeleton.mySensFooter ])

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model