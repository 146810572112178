namespace Client

open Shared.Dto.Dto

[<RequireQualifiedAccess>]
module internal BatteryLevel =
    let toImageSrc (level: BatteryLevel) =
        let fileName =
            match level with
            | Full -> 1
            | TwoQuarters -> 2
            | OneQuarter -> 3
            | Low -> 4
            | Danger -> 5

        sprintf "/images/battery-level/batt%i.svg" fileName